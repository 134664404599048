.barcode-qty-edit-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
}

.barcode-edit-qty-modal-content {
  background-color: #fff;
  padding: 14px;
  border-radius: 8px;
  width: 75%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.barcode-qty-edit-form {
  margin-top: 2rem;
}

.barcode-edit-item-qty-form-group {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  font-size: large;
  gap: 1rem;
}

.barcode-edit-item-qty-form-group label {
  margin-right: 0rem;
  font-weight: 500;
}

.barcode-edit-item-qty-form-group input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: medium;
}

.barcode-flexbox {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.barcode-update-btn {
  background-color: #474DDC;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: medium;
  transition: background-color 0.3s ease;
}

.barcode-update-btn:hover {
  background-color: #3a3fb8;
}

.barcode-close-btn {
  position: absolute;
  top: 1px;
  right: 0px;
  background-color: #fff;
  color: #000;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  font-size: large;
}

.barcode-close-btn:hover {
  background-color: #f0f0f0;
}
