.purchase-quote-main-container {
  display: flex;
  flex-direction: column;
  height: 80vh;
  background-color: white;
  padding: 0.8rem;
  border-radius: 1rem;
  margin-top: 1rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 700px;
  height: 700px;
}

.purchase-quote-container-first-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.purchase-quote-first-left {
  display: flex;
  align-items: center;
}

.purchase-quote-first-right {
  display: flex;
  justify-content: flex-end;
}

button.purchase-quote-first-right-botton {
  display: flex;
  align-items: center;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  background-color: #002989;
  gap: 1rem;
}

span.all-purchase-quote-show {
  color: #002989;
  font-size: x-large;
  font-weight: bold;
}

.new-purchase-order-popup-container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  z-index: 1000
}

/* Table group */
.purchase-quote-table-group {
  border-bottom: 1px solid #ccc;
  height: 625px;
  margin-top: 1rem;
  overflow-y: auto;
}

.purchase-quote-table {
  width: 100%;
  border-collapse: collapse;
}

.purchase-quote-thead-row th {
  padding: 0.4rem;
  border-bottom: 1px solid #ccc;
  text-align: left;
  border: 1px solid #ccc;
  font-size: small;
  font-weight: bold;
}

.purchase-quote-table-body td {
  padding: 0.2rem;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  text-align: left;
  font-size: small;
}

.purchase-quote-tab-td {
  padding: 0.2rem;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  text-align: left;
  font-size: small;
}

.pq-status-dropdown {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  list-style: none;
  padding: 5px;
  right: 400px;
  margin-top: 0px;
  margin-bottom: 0px;
  width: max-content;
  min-width: 100px; 
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.pq-action-dropdown {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  list-style: none;
  padding: 5px;
  right: 30px;
  margin-top: 0px;
  margin-bottom: 0px;
  width: max-content;
  min-width: 100px; 
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.status-dropdown li {
  padding: 8px 12px;
  cursor: pointer;
}

.status-dropdown li:hover {
  background-color: #f0f0f0;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.modal h2 {
  margin: 0;
  font-size: 16px;
}

.modal button {
  margin: 5px;
  padding: 5px 10px;
  cursor: pointer;
}