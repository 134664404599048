.restaurant-settings {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .restaurant-settings h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .settings-section {
    margin-bottom: 30px;
  }
  
  .settings-section h3 {
    color: #555;
    margin-bottom: 10px;
  }
  
  .settings-section p {
    color: #777;
    margin-bottom: 20px;
  }
  
  .settings-section button {
    background-color: #002989 ; 
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .settings-section button:hover {
    background-color: #0e3ba5;
  }
  
  .restaurant-config-main-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }