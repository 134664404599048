.platform-for-store-container {
  height: auto;
  width: auto;
  /* max-width: 900px; */
  /* margin: 0 auto; */
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.platform-for-store-header {
  line-height: 3rem;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

span.platform-store-title {
  font-size: x-large;
  font-weight: bold;
  text-align: center;
  display: block;
  color: #002989;
}

.platform-store-sub-container {
  margin-top: 1rem;
  padding: 1rem;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* .platform-for-store-header h2 {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
} */

.platform-for-store-select-container,
.platform-for-store-list-container {
  margin-bottom: 30px;
}

/* .platform-for-store-header h3 {
  font-size: 18px;
  color: #555;
  margin-bottom: 10px;
} */

/* .platform-for-store-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
} */

.platform-for-store-table {
  width: 100%;
  border-collapse: collapse;
}

.platform-for-store-th, .platform-for-store-td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.platform-for-store-th {
  background-color: #f2f2f2;
  font-weight: bold;
  color: #333;
}

.platform-for-store-icon {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

/* .platform-for-store-checkbox {
  transform: scale(1.5);
  margin: 0;
  cursor: pointer;
} */

.platform-for-store-action-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.platform-for-store-enable-button,
.platform-for-store-disable-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.platform-for-store-enable-button {
  background-color: #002989;
  color: white;
}

.platform-for-store-disable-button {
  background-color: #002989;
  color: white;
}

.platform-for-store-enable-button:hover,
.platform-for-store-disable-button:hover {
  opacity: 0.8;
  transform: translateY(-2px);
}

span.platform-select-store-option-title {
  font-size: larger;
  font-weight: bold;
}

span.platform-list-store-title {
  font-size: larger;
  font-weight: bold;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.platform-for-store-container {
  animation: fadeIn 0.5s ease-out;
}

@media (max-width: 600px) {
  .platform-for-store-container {
    padding: 10px;
  }

  .platform-for-store-table {
    font-size: 14px;
  }

  .platform-for-store-action-buttons {
    flex-direction: column;
    gap: 10px;
  }
}
