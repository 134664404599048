.template-main-content {
  display: flex;
  flex-direction: column;
  height: 86vh;
  background-color: whitesmoke;
  padding: 17px;
  border-radius: 15px;
  overflow: hidden;
  position: absolute;
  width: 98%;
}

.template-setting-title {
  font-family: 'Poppins', sans-serif;
  font-size: 22px;
  color: #474DDC;
  font-weight: 600;
  line-height: 33px;
  margin-left: 10px;
}

.input-space {
  margin-right: 1rem;
}


.lable-space {
  font-size: inherit;
  margin-left: 10px;
  flex-grow: 1;
}

.receipt-image-size {
  width: 150px;
  height: 300px;
}

.selected {
  position: relative;
  /* box-shadow: 0px 0px 10px 0px rgba(34, 34, 196, 0.7); */
  /* transition: box-shadow 0.3s ease; */
  /* width: 153px; */
}

.check-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #474DDC;
  font-size: 2rem;
  z-index: 1;
}

.popup-hover {
  padding: 0px;
  transition: transform .2s;
  width: 100px;
  height: 150px;
}

.popup-hover:hover {
  transform: scale(1.2);
}

.right-content {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 50%;
  background-color: white;
  /* z-index: 1; */
  border-radius: 15px;
  /* border: 1px solid grey; */
  height: 96%;
  box-sizing: border-box;
}

.right-content-inner {
  padding: 20px;
  height: calc(100% - 4rem);
  width: calc(100% - 4rem);
  margin-top: 2rem;
  margin-left: 2rem;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

.temp-setting-options-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  margin-left: 10px;
}

.temp-setting-options-container::-webkit-scrollbar {
  display: none;
}

.temp-option-item {
  display: flex;
  /* align-items: center; */
  font-family: 'Poppins', sans-serif;
  font-size: larger;
  position: relative;
  width: 100%;
}

.label-space {
  margin-left: 10px; 
}

.form-check-input {
  margin-right: 10px;
}

.input-space {
  margin-right: 10px;
}

.edit-icon {
  margin-left: 10px;
  cursor: pointer;
  color: #474DDC;
  margin-top: 5px;
}

.horizontal-line {
  margin-top: 20px;
}

.text-right {
  text-align: right;
}

.edit-input {
  display: block;
  width: 30px;
  font-family: 'Poppins', sans-serif;
  font-size: 17px;
}

.input-options {
  /* justify-content: center;
  align-items: center; */
  margin-left: 10px;
}
.right-content-inner::-webkit-scrollbar {
  width: 10px;
}

.right-content-inner::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

.right-content-inner::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.setting-template-figure {
  border: 2px solid white;
  border-radius: 1%;
  height: 75%;
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: 20px;
  margin-right: 20px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

.setting-template-figure::-webkit-scrollbar {
  width: 10px;
}

.setting-template-figure::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

.setting-template-figure::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.inner-box-1 {
  background-color: whitesmoke;
  border-radius: 10px;
  height: calc(100% - 4rem);
  width: calc(35% - 4rem);
  margin-top: 2rem;
  margin-left: 1rem;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  overflow-y: scroll;
}

.inner-box-2 {
  background-color: white;
  border-radius: 10px;
  margin-left: 1rem;
  margin-right: 2rem;
  height: calc(100% - 4rem);
  width: calc(45vw - 4rem);
  margin-top: 2rem;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

.template-setting-box-container {
  display: flex;
  width: 48%;
  height: 100%;
  gap: 2px;
  border-radius: 10px;
  background-color: white;
}

.template-main-sub-content {
  display: flex;
  align-items: flex-start;
  width: 70%;
  margin-left: 1rem;
}

.temp-img-align {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.box-temp-setting-image {
  max-width: 90%;
  max-height: 100%;
  object-fit: contain;
  margin-left: 2.rem;
}

.inner-box-1::-webkit-scrollbar {
  width: 10px;
}

.inner-box-1::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

.inner-box-1::-webkit-scrollbar-thumb:hover {
  background: #555;
}

button.btn.btn-primary.save-btn-i {
  margin-right: 1rem;
}

input.form-control.temp-setting-edti-fied {
  width: 90%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
