.void-rem-item-modifier {
  width: 50%;
  border: 1px solid grey;
  background-color: #fff;
}

.void-remark-res-modify-title {
  width: 100%;
  background-color: #002989 !important;
  height: 3rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}

.remark-modify-title-heading {
  font-size: larger;
  font-weight: bold;
}

.remark-modify-cross-icon {
  font-size: larger;
  font-weight: bold;
  cursor: pointer;
}

.void-remark-sub {
  background-color: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

button.btn.void-reason-button {
  margin-bottom: 10px;
  border-radius: 1rem;
  margin-right: 10px;
  border: 1px solid #002989;
  color: #002989;
  background-color: white;
  padding: 10px 20px;
}

button.btn.void-reason-button:hover{
  background-color: #cddcf8;
}

/* .form-control {
  width: 100%;
  padding: 0.5rem;
  margin-top: 0.5rem;
} */

.save-button-container {
  display: flex;
}

.void-remark-save-btn {
  font-size: larger;
  font-weight: bold;
  margin-top: 10px;
  background-color: #002989;
  border-color: 1px solid #002989;
  padding: 5px 6px;
  line-height: 1.5rem;
  color: white;
  border-radius: 10px;
}

.void-remark-void-reason{
  font-size: larger;
  font-weight: bold;
  margin-top: .5rem;
  margin-bottom: 0.3rem;
}
