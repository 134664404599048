.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-guest-main {
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 50rem;
  transition: height 0.2s ease; 
}

.modal-container-small {
  height: auto;
}

.modal-container-large {
  height: auto;
}

.select-guest-container-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #002989;
  height: 50px;
}

.invoice-select-guest-title {
  font-size: larger;
  font-weight: bold;
  color: white;
  margin-top: 3px;
  margin-left: 10px;
}

.invoice-select-guest-close-icon {
  margin-right: 15px;
  margin-top: 3px;
  color: white;
  font-size: larger;
  font-weight: bold;
  cursor: pointer;
}

.invoice-guest-search-container {
  padding: 20px;
}

.input-group {
  margin-bottom: 10px;
}

.input-label {
  display: block;
  margin-top: -.5%;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: x-large;
}

.guest-list {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.guest-list th,
.guest-list td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.guest-list th {
  background-color: #f2f2f2;
}

.guest-table-list-info {
  overflow-y: auto;
  max-height: 200px;
  overflow-x: hidden;
  margin-bottom: 1rem;
}

.select-guest-list-heading {
  font-size: x-large;
  font-weight: bold;
  margin-left: 10px;
}

.create-guest-form {
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 1rem;
  margin-bottom: 2rem;
  height: auto;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}

.guest-title-header-box {
  width: 100%;
  background-color: #002989;
  margin-bottom: 1%;
}

span.guest-header-title {
  color: white;
  margin-left: 1rem;
}

.guest-title-header-box {
  width: 100%;
  background-color: #002989;
  padding: 0.5rem; 
}

span.guest-header-title {
  color: white;
  margin-left: 1rem;
}

.select-guest-create-form-group {
  display: flex;
  align-items: center;
  margin-bottom: 1rem; 
  margin-left: 1rem;
  margin-right: 1rem;
}

.select-guest-create-form-group label {
  flex: 1; 
}

.select-guest-create-form-group input {
  flex: 2; 
}

.button-guest-group {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
}

button.resturant-guest-search-button {
  line-height: 2rem;
  border-radius: 5px;
  padding: 5px 6px;
  background-color: #002989;
  color: white;
  border: 1px solid #002989;
}

.select-guest-sub-container {
  overflow-y: auto;
  max-height: 450px;
}

.select-guest-create-form-group.guest-form-save-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

button.select-guest-create-form {
  line-height: 2rem;
  padding: 5px 6px;
  border-radius: 10px;
  background-color: #002989;
  color: white;
  border: 1px solid #002989;
  margin-bottom: 1rem;
}

.select-guest-error-msg-grp {
  margin-top: 1rem;
}

.invoice-guest-sub-content {
  /* overflow-y: auto; */
  max-height: 144px;
  height: 140px;
}

.customer-error-msg {
  display: flex;
}

.customer-phone-error {
  margin-left: auto;
  color: red;
  font-size: medium;
  float: right;
  margin-right: 15rem;
}
