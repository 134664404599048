.close-icon {
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  cursor: pointer;
  margin-left: 30px;
}

.close-icon:hover {
  opacity: 1;
  color: rgb(10, 10, 10);
}

.discount-input-group {
  display: flex;
  align-items: center;
  margin-top: -2rem;
  margin-left: 55px;
}

.billing-checks .form-check{margin-right: 17px;}
.billing-checks form{display: flex;}
.billing-checks .col-md-2 svg{fill: #474DDC; font-size: 22px;}
.billing-checks{margin: 25px 0;  }
.billing-container .row{width: 100%; }
.billing-container .col-md-4{padding-right: 0;}
.amount-details ul{padding-left: 0;}
.amount-details ul span{float: right;}
.amount-details ul li{line-height: 35px; color: #000;}
.amount-details { border-top: 1px solid #e0e0e0; padding-top: 15px;}
.total-amount {border: 1px solid #474DDC; border-radius: 8px; padding: 15px;}
/* .total-amount h3 span{float: right; font-weight: bold;} */
.total-bill-amount{
  display: flex;
  justify-content: space-between; 
  align-items: center; 
}

span.bill-label {
  font-weight: bold;
  font-size: x-large;
}

span.amount-total-value {
  font-size: x-large;
  font-weight: bold;
}

.payment-mode {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}

.seller-details h6{font-size: 18px; border-bottom: 1px solid #e0e0e0; padding-bottom: 13px;}
.seller-details .form-check{margin: 12px 0; color: #000;}
.total-amount h3{margin-bottom: 0; color: #000;}
.billing-left {
  background-color: #fff;
  padding: 15px;
  /* height: 790px;
  overflow-y: scroll; */
  height: 80vh;
  overflow-y: auto;
  max-height: 800px;
  margin-bottom: 2rem;
}

.billing-checks .col-md-2 {
  text-align: right;
}

.billing-right {
  /* height: 790px; */
  height: 80vh;
  overflow-y: auto;
  max-height: 800px;
  margin-bottom: 2rem;
}


.billing-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.bill-number {
  font-weight: bold;
}

.form-header {
  flex-grow: 1;
  text-align: right;
}

.billing-create-tab {
  height: auto;
  max-height: 900px;
  overflow-y: scroll;
}

.bottom_space {
  margin-top: 3px;
  justify-content: space-between;
}

.tab-width {
  width: 345rem;
}

.billing-coupon-group {
  display: flex;
  margin-top: -15px;
  gap: 2rem;
}

span.bill-coupon-label {
  margin-left: 1rem;
  font-size: large;
  font-weight: bold;
}

h6.billing-discount-coupon-label {
  width: 60%;
}

button.billling-coupon-apply-btn {
  line-height: 1rem;
  padding: 5px 6px;
  border-radius: 6px;
  border-color: 1px solid #002989;
  color: white;
  background-color: #002989;
  height: fit-content;
}

.party-wise-toggle-button-group {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.party-wise-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-right: 10px;
}

.party-wise-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider-bill-party-wise {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.toggle-slider-bill-party-wise:before {
  position: absolute;
  content: "";
  height: 2rem;
  width: 1.9rem;
  left: 1px;
  bottom: 1px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .toggle-slider-bill-party-wise {
  background-color: #002989;
}

input:checked + .toggle-slider-bill-party-wise:before {
  transform: translateX(27px);
}

.billing-doctor-detail-grp {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

span.billing-doctor-details {
  font-size: medium;
  font-weight: bold;
}

.payment-mode-option{
  width: 40%;
}


button.billling-coupon-apply-btn {
  margin-top: 2rem;
}

.toggle-button-group {
  display: flex;
  margin-top: -0.5rem;
  align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-right: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.custom-partial-payment-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.custom-popup-content {
  width: 100%;
  max-width: 400px;
}

.custom-payment-table {
  width: 100%;
  margin-top: 10px;
  border-collapse: collapse;
}

.custom-payment-table th, .custom-payment-table td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: center;
}

.custom-input {
  width: 80px;
  padding: 5px;
  text-align: center;
}

.custom-remaining-amount
.custom-total-amount {
  margin-top: 15px;
  font-size: 1.2em;
  text-align: right;
}

.custom-popup-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 15px;
}

.reminder-date-label {
  font-weight: bold;
  margin-right: 5px;
}

.toggle-slider-bill {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.toggle-slider-bill:before {
  position: absolute;
  content: "";
  height: 2rem;
  width: 1.9rem;
  left: 1px;
  bottom: 1px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .toggle-slider-bill {
  background-color: #002989;
}

input:checked + .toggle-slider-bill:before {
  transform: translateX(27px);
}

.billing-multitoggles {
  display: flex;
  justify-content: space-between;
}

.billing-multitoggles-second-div {
  display: flex;
  gap: 1rem;
}

.form-select-wrapper {
  margin-bottom: 1rem;
}
input.form-control.reminder-date-box {
  height: 2rem;
}

input.form-control.reminder-date-box {
  height: 2rem;
 }

 .reminder-date-label {
  font-weight: bold;
 margin-right: 5px;
 }

.col-md-12.biling-data-form-grp {
  display: flex;
 justify-content: space-between;
 }

span.bill-paymode-title {
  width: 60%;
  font-size: medium;
  font-weight: bold;
}

.amount-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 10px;
}

/* toggle button CSS */
.bill-toggle-tax-wise-toggle-wrapper {
  display: flex;
  align-items: center;
  margin-top: 2rem;
}

.bill-toggle-tax-wise-toggle-container {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.bill-toggle-tax-wise-toggle-container input {
  opacity: 0;
  width: 0;
  height: 0;
}

.bill-toggle-tax-wise-toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.bill-toggle-tax-wise-toggle-container input:checked + .bill-toggle-tax-wise-toggle-slider {
  background-color: #002989;
}

.bill-toggle-tax-wise-toggle-slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.bill-toggle-tax-wise-toggle-container input:checked + .bill-toggle-tax-wise-toggle-slider:before {
  transform: translateX(26px);
}
.whatsapp-login-section {
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
}

.whatsapp-login-section button {
  display: flex;
  align-items: center;
  gap: 5px;
}
