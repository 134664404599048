.new-purchase-order-main-container {
  height: 95vh;
  width: 100%;
  background-color: white;
  border-radius: 0.4rem;
  overflow-y: auto;
}

.new-purchase-order-sub-container {
  max-height: 500px;
  padding: 1rem;
}

.new-purchase-order-first-div {
  display: flex;
  gap: 3rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.new-purchase-order-estimate-no-grp {
  display: flex;
  flex-flow: column;
  width: 20%;
  gap: 0.5rem;
}

.new-purchase-order-estimate-date-grp {
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
}

span.new-purchase-order-estdate-input{
  display: flex;
  justify-content: center;
}

.new-purchase-order-tilldate-grp {
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
}

span.new-purchase-order-est-tilldate-input{
  display: flex;
  justify-content: center;
}

.new-purchase-order-reference-grp{
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
}

span.new-purchase-order-est-reference-input{
  display: flex;
  justify-content: center;
}

.new-purchase-order-second-div {
  margin-top: 1rem;
  display: flex;
}

.new-purchase-order-customer-div {
  display: flex;
  flex-flow: column;
  margin-left: 1rem;
}

input.form-control.new-purchase-order-cust-field {
  width: 30%;
}

.quotation-invoice-table-group {
  margin-top: 1rem;
}

.new-purchase-order-third-div {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

textarea.new-purchase-order-term-textarea-input,
.new-purchase-order-note-textarea-input{
  border-radius: 0.5rem;
  border: 1px solid #ccc;
  width: 100%;
  height: 100%;
}

textarea.new-purchase-order-term-textarea-input:focus,
.new-purchase-order-note-textarea-input:focus{
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
}

span.new-purchase-order-est-no-title,
.new-purchase-order-estdate-title,
.new-purchase-order-est-tilldate-title,
.new-purchase-order-est-reference-title,
.new-purchase-order-cust-title,
.new-purchase-order-buyer-order-title,
.new-purchase-order-buyer-order-date-title {
  font-size: small;
}

span.new-purchase-order-est-no {
  font-size: large;
  font-weight: bold;
  display: flex;
  justify-content: left;
  margin-left: 1rem;
}

input.form-control.new-purchase-order-first-div-input {
  height: 2rem;
}

input.form-control.new-purchase-order-cust-field {
  height: 2rem;
  width: 60%;
}

input.form-control.new-purchase-order-buyer-order,
.new-purchase-order-buyer-order-date {
  height: 2rem;
  width: 60%;
}

.new-purchase-order-note-grp {
  display: flex;
  flex-flow: column;
  margin-left: 1rem;
}

.new-purchase-order-terms-condition-grp {
  display: flex;
  flex-flow: column;
}

.new-purchase-order-terms-condition-grp {
  display: flex;
  flex-flow: column;
}

input.form-control.new-purchase-order-last-input {
  height: 2rem;
  width: 100%;
}

.new-purchase-order-table {
  margin-top: 2rem;
  margin-bottom: 4rem;
}

input.form-control.new-purchase-order-buyer-order-date {
  width: 100%;
}

.new-purchase-order-customer-div {
  display: flex;
  flex-flow: column;
  margin-left: 1rem;
}

input.form-control.new-purchase-order-cust-field {
  width: 100%;
}

.new-purchase-order-cust-title{
  font-size: small;
}

.new-purchase-order-customer-dropdown {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  background-color: white;
  position: absolute;
  width: 100%;
  z-index: 1000;
  top: 100%;
  left: 0;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.new-purchase-order-dropdown-item {
  padding: 0.2rem 0.5rem;
  cursor: pointer;
}

.new-purchase-order-dropdown-item:hover {
  background-color: #f1f1f1;
}

.new-purchase-order-cust-search {
  position: relative;
}

.new-purchase-order-buyer-order-grp {
  margin-left: 2rem;
}

.po_table {
  width: 50%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.po_table_row {
  display: contents;
}

.form-control {
  width: 100%;
  height: 35px;
  font-size: 14px;
}
/* ========================== */
.pq_table_td {
  position: relative;
}

.reference-dropdown {
  position: absolute;
  width: 100%;
  z-index: 1000;
  max-height: 160px;
  overflow-y: auto;
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.reference-dropdown-item {
  cursor: pointer;
}

.reference-dropdown-item:hover {
  background-color: #f8f9fa;
}

.selected-references-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.selected-reference-tag {
  display: row;
  align-items: center;
  background-color: #e9ecef;
  padding: 0.25rem 0.5rem;
  border-radius: 12px;
  font-size: 14px;
  margin-bottom: 5px;
}

.selected-reference-tag span {
  margin-right: 0.5rem;
}

.selected-reference-tag button {
  background: none;
  border: none;
  color: #dc3545;
  font-size: 14px;
  cursor: pointer;
  line-height: 1;
}