.common-tax-wise-toggle-wrapper {
  display: flex;
  align-items: center;
  margin-top: 2rem;
}

.common-tax-wise-toggle-container {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.common-tax-wise-toggle-container input {
  opacity: 0;
  width: 0;
  height: 0;
}

.common-tax-wise-toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.common-tax-wise-toggle-container input:checked + .common-tax-wise-toggle-slider {
  background-color: #002989;
}

.common-tax-wise-toggle-slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.common-tax-wise-toggle-container input:checked + .common-tax-wise-toggle-slider:before {
  transform: translateX(26px);
}

.common-tax-wise-toggle-label {
  margin-left: 10px;
  font-size: 16px;
  color: #333;
}
