.dropdown-menu {
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 8px;
  z-index: 999;
}

.dropdown-menu div:hover {
  background-color: #f5f5f5;
}


.dropdown {
  position: relative;
  width: 100%;
}

.control {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
}

.selected-value {
  flex: 1;
}

.arrow {
  width: 10px;
  height: 10px;
  border: solid #333;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
  margin-right: 8px;
}

.options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 150px;
  overflow-y: auto;
  display: none;
}

.options.open {
  display: block;
}

.option {
  padding: 8px;
  cursor: pointer;
}

.option:hover {
  background-color: #f5f5f5;
}

.option.selected {
  font-weight: bold;
}

.dropdown-container {
  border: 1px solid #ccc;
  border-radius: 4px; 
  max-height: 100px;
  overflow-y: auto;
}

.dropdown-option {
  padding: 8px;
  cursor: pointer;
}

.dropdown-option:hover {
  background-color: #f0f0f0;
}

.card-content-kot.active {
  background-color: red; 
}