.transfer-popup {
    background: white;
    border-radius: 8px;
    width: 800px;
    max-width: 90vw;
    padding: 20px;
    position: relative;
    min-height: 400px; /* Add fixed minimum height */
    display: flex;
    flex-direction: column;
  }
  
  .transfer-popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .status-select {
    padding: 5px;
    border-radius: 4px;
  }
  
  .status-select.status-pending {
    background-color: #f0f0f0;
    color: #666;
  }
  
  .status-select.status-accept {
    background-color: #e6f3e6;
    color: green;
  }
  
  .status-select.status-reject {
    background-color: #f3e6e6;
    color: red;
  }
  
  
  .tabs {
    display: flex;
    gap: 10px;
  }
  
  .tab {
    padding: 8px 16px;
    border: none;
    background: none;
    cursor: pointer;
    border-bottom: 2px solid transparent;
  }

  .transfer-item-suggestions{
    position: fixed;
    z-index: 1000;
    background-color: #fff;
    padding: 0;
    margin: 0;
    list-style-type: none;
    max-height: 10%;
    overflow-y: auto;
    width: 12%;
  }
  
  .tab.active {
    border-bottom: 2px solid #007bff;
    color: #007bff;
  }
  
  .close-btn {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    margin-left: 33px;
  }
  
  .branch-selection {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
  }
  
  .branch-dropdown {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 200px;
  }
  
  .item-rows {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
  }
  
  .item-row {
    display: flex;
    gap: 15px;
    align-items: center;
  }
  
  .item-input {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    flex: 1; /* Ensures consistent sizing */
    width: 100%; /* Ensures full width of the flex container */
    box-sizing: border-box;
  }
  
  .delete-row-btn {
    padding: 8px 16px;
    background: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .add-item-btn {
    padding: 8px 16px;
    background: #002989;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .popup-actions {
    display: flex;
    gap: 15px;
    justify-content: flex-end;
  }
  
  .transfer-item-btn {
    padding: 8px 16px;
    background: #002989;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 13%;
  }

  .view-details-btn {
    background-color: #002989;
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .view-details-btn:hover {
    background-color: #001a5b; /* Optional: Darker shade for hover effect */
  }
  
  
  .cancel-btn {
    padding: 8px 16px;
    background: #6c757d;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .received-items-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .received-items-table th,
  .received-items-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .received-items-table th {
    background-color: #f8f9fa;
  }
  .received-tab-content {
    width: 100%;
    height: 100%;
    min-height: 300px; /* Add minimum height */
  }
  .transfer-delete-icon {
    cursor: pointer;
    color: #dc3545;
    font-size: 1.2em;
    transition: color 0.3s ease;
  }
  
  .transfer-delete-icon:hover {
    color: #c82333;
  }
  .item-search-wrapper {
    position: relative;
  flex: 1; /* Allow the search field to take up more space */
  display: flex;
  flex-direction: column; 
  }
  .item-search-wrapper .item-input {
    flex: 1; /* Removed special sizing */
    min-width: 0; /* Allows flex to control sizing */
  }
  .transfer-item-suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%; /* Changed to match input width */
    max-height: 200px;
    overflow-y: auto;
    background-color: white;
    border: 1px solid #ddd;
    border-top: none;
    z-index: 10;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .transfer-item-suggestions li {
    padding: 8px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .transfer-item-suggestions li:hover {
    background-color: #f0f0f0;
  }

  .status-select {
    color: white;
  }
  
  .status-select.accepted {
    background-color: green;
  }
  
  .status-select.rejected {
    background-color: red;
  }
  
  .status-select.dispatched {
    background-color: white;
    color: black;
  }

