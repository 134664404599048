.eway-bill-main-container {
  padding: 0.8rem;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
  height: 82vh;
  width: 100%;
  box-sizing: border-box;
}

.eway-bill-first-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ccc;
}

.eway-title {
  font-size: larger;
  font-weight: bold;
  color: #002989;
}

.eway-btn-grp {
  display: flex;
}

.eway-new-btn {
  background-color: #002989;
  color: white;
  border: none;
  padding: 0.2rem 0.5rem;
  cursor: pointer;
  border-radius: 0.2rem;
}

.eway-bill-second-div {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.eway-second-icon-grp {
  display: flex;
  justify-content: flex-end;
}

.eway-turn-right-icon {
  font-size: medium;
  cursor: pointer;
  background-color: #002989;
  color: white;
  padding: 0.2rem 0.4rem;
  border-radius: 0.2rem;
}

/* Table group */
.eway-bill-table-group {
  overflow-x: auto;
  border-bottom: 1px solid #ccc;
  max-height: 330px;
  overflow-y: auto;
}

.eway-bill-table {
  width: 100%;
  border-collapse: collapse;
}

.eway-bill-thead-row th {
  padding: 0.4rem;
  border-bottom: 1px solid #ccc;
  text-align: left;
  border: 1px solid #ccc;
  font-size: small;
  font-weight: bold;
}

.eway-bill-table-body td {
  padding: 0.2rem;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  text-align: left;
  font-size: small;
}

.eway-bill-table-body tr td:last-child {
  text-align: center;
}

.eway-tab-td {
  font-size: small;
}

.show-new-eway-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  z-index: 1000
}

.show-eway-filter-popup-container {
  position: absolute;
  top: 13rem;
  right: 1rem;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  z-index: 10;
  width: 25%;
}

.show-eway-filter-div {
  display: flex;
  flex-flow: column;
  margin-bottom: 0.5rem;
}

span.show-eway-filter-label {
  font-size: x-small;
}

.show-eway-filter-date-grp {
  display: flex;
  justify-content: space-between;
}

.eway-filter-start-grp {
  display: flex;
  flex-flow: column;
  margin-bottom: 0.5rem;
}

span.eway-filter-btn-label {
  font-size: small;
}

.eway-filter-end-grp {
  display: flex;
  flex-flow: column;
  margin-bottom: 0.5rem;
}

input.eway-filter-btn-input {
  padding: 0.2rem;
  font-size: x-small;
  width: 100%;
}

input.eway-filter-input-field {
  width: 100%;
}

.show-eway-filter-button-group {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}

button.eway-filter-btn {
  border: none;
  background-color: #002989;
  color: white;
  padding: 0.2rem 0.2rem;
  font-size: small;
  border-radius: 0.2rem;
}

/* eway-bill down icon action css */
.eway-bill-down-icon-action{
  position: absolute;
  top: 19rem;
  right: 1rem;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
  width: 15%;
}

svg.svg-inline--fa.fa-caret-down.eway-bill-table-body-td {
  margin-left: 0.5rem;
}

ul.eway-bill-down-icon-action-ul {
  padding: 0px;
  margin-bottom: 0px;
}

li.eway-bill-down-icon-action-li {
  padding: 0.2rem;
  font-size: small;
}

.eway-bill-down-icon-action-li:hover{
  background-color: #ccc;
  cursor: pointer;
}
