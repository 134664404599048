.dashboard-platform-main-container {
  height: auto;
  padding: 18px;
}

.dashboard-platform-sub-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px; 
  margin-top: 1rem;
}

.platform-card {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  flex: 1 1 calc(25% - 16px);
  height: 20vh; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.platform-card-title-div {
  padding: 1rem;
	height: 12vh;
}

span.platform-card-title {
  font-size: larger;
  font-weight: bold;
  flex-wrap: wrap;
}

.platform-card-symbol-value-grp {
  display: flex;
  justify-content: space-between;
  align-items: center; 
  padding: 1rem;
  background-color: #fff;
}

.platform-card-symbol {
  font-weight: bold;
  color: green;
  font-size: larger;
}

span.platform-card-symbol-cancel {
  color: #8c6969;
  font-size: larger;
}

img.platform-logo-img {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}

span.platform-card-cacel-value {
  color: #8c6969;
  font-weight: bold;
  font-size: larger;
}

span.platform-card-value {
  font-weight: bold;
  color: green;
  font-size: larger;
}

span.platform-card-value-price {
  font-weight: bold;
  color: #759ade;
  font-size: larger;
}

.dashboard-platform-header-container {
  background-color: white;
  line-height: 4rem;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  position: relative;
}

span.dash-platform-title {
  font-size: x-large;
}

span.dash-platform-options {
  font-size: large;
}


@media (max-width: 768px) {
  .card {
    flex: 1 1 calc(50% - 16px); 
  }
}

@media (max-width: 480px) {
  .card {
    flex: 1 1 100%; 
  }
}

.online-order-three-dots-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5em;
  padding: 10px;
  display: flex;
  justify-content: right;
}

.platform-online-option-list {
  position: relative;
  bottom: 28rem;
  float: right;
  margin-right: 2rem;
}
