.template-main-content {
    display: flex;
    height: 100%;
    padding: 20px;
  }
  
  .template-setting-box-container {
    display: flex;
    width: 70%;
  }
  
  .inner-box-1 {
    width: 30%;
    overflow-y: auto;
  }
  
  .template-main-sub-content {
    width: 70%;
  }
  
  .right-content {
    width: 30%;
  }
  
  .temp-img-align {
    margin-bottom: 20px;
    position: relative;
  }
  
  .box-temp-setting-image {
    width: 100%;
    height: auto;
  }
  
  .check-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #474DDC;
    font-size: 2rem;
  }
  
  .temp-option-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .edit-icon {
    margin-left: 10px;
    cursor: pointer;
  }
  
  .temp-setting-edti-fied {
    margin-top: 5px;
    width: 100%;
  }
  .custom-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .custom-modal {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    max-width: 400px;
    width: 90%;
    max-height: 80vh;
    overflow-y: auto;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .modal-header h2 {
    margin: 0;
    font-size: 1.2rem;
  }
  
  .close-icon {
    cursor: pointer;
    font-size: 1.5rem;
  }
  
  .modal-content {
    margin-bottom: 15px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group select,
  .form-group input {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .modal-footer {
    display: flex;
    justify-content: flex-end;
  }
  
  .modal-footer button {
    margin-left: 10px;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .modal-footer button:first-child {
    background-color: #474DDC;
    color: white;
  }
  
  .modal-footer button:last-child {
    background-color: #474DDC;
    color: white;
  }