.restaurant-platforms-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .restaurant-platforms-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    position: relative;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .platform-logos {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .platform-logo {
    width: 60px;
    height: 60px;
    object-fit: contain;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .platform-logo:hover {
    transform: scale(1.1);
  }
  
  .platform-logo.selected {
    border: 2px solid #007bff;
    border-radius: 50%;
  }
  
  .platform-form {
    display: flex;
    flex-direction: column;
  }
  
  .platform-form input {
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .platform-form button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .platform-form button:hover {
    background-color: #0056b3;
  }