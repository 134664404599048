.res-no-charges-main {
  width: 50%;
  height: auto;
  border: 1px solid;
}

.res-no-charges-title {
  width: 100%;
  background-color: #002989 !important;
  height: 3rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}

.no-charges-title-heading {
  font-size: larger;
  font-weight: bold;
}

.res-charges-sub-contain {
  background-color: white;
	height: auto;
}

.collection-no-charges-modify-button {
  display: flex;
  margin-left: 10px;
  gap: 10px;
}

span.no-charges-buttons-res-save-select {
  margin-bottom: 10px;
}

button.btn.btn-success.no-charges-save-select {
  background-color: #002989;
  border: none;
}

button.btn.btn-danger.no-charges-close {
  background-color: #002989;
  border: none;
}

.res-charges-sub-face-2 {
  padding-top: 10px;
}

span.charges-reason-title {
  margin-left: 1rem;
  font-size: x-large;
}

span.new-nc-title {
  margin-left: 1rem;
  font-size: x-large;
}

input.form-control.no-charges-reason-input-field {
  width: 94%;
  margin-left: 1rem;
}

.charges-reson-list-contain {
	overflow-y: auto;
	max-height: 150px;
	overflow-x: hidden;
	padding-left: 1rem;
}

.no-charges-invoice-input {
	margin-bottom: 10px;
	border-radius: 1rem;
	margin-right: 10px;
	border: 1px solid #002989;
	color: #002989;
	background-color: white;
	padding: 5px 6px;
}

.no-charges-button-style{
	display: contents;
}

.no-charges-invoice-input:hover{
	background-color: #cddcf8;
}

.charges-error-message {
	color: red;
	margin-top: 10px;
	margin-left: 10px;
}