.item-update-main-content {
  display: flex;
  flex-direction: column;
  height: 75vh;
  background-color: #fff;
  padding: 17px;
  border-radius: 15px;
  overflow-y: auto;
  max-height: 86vh;
}

.update-item-show {
  margin-bottom: 10px;
  padding: 15px 20px;
  border: 1px solid #474DDC;
  border-radius: 1px solid #474DDC;
}

.update-item-show .col-md-12 {
  margin: 7px 0;
}

.update-item-show .col-md-4 {
  margin: 7px 0;
}

input.update-required-field,
select.update-required-field {
  border: 1px solid red !important;
}

select.form-control.update-item-default-selection {
  color: gray;
  border: 1px solid #b9b9b9;
}

.item-update-select-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  color: grey;
}

span.update-item-tax-per-unit {
  margin-left: 1px;
  color: grey;
}

.iteme-update-custom-select {
  position: relative;
}

.iteme-update-custom-select select {
  width: 100%;
  padding-right: 30px;
}

p.update-item-img-name {
  color: #474DDC;
}

/* .readonly-input {
  background-color: #f0f0f0; 
  color: #6c757d; 
  cursor: not-allowed;
}

.readonly-input:read-only {
  background-color: #f0f0f0; 
  color: #6c757d;
}

.readonly-select {
  background-color: #f0f0f0;
  color: #6c757d;
  cursor: not-allowed;
} */

.price-warning {
  color: #dc3545;
  font-size: 0.875em;
  margin-top: 0.5em;
  display: flex;
  align-items: center;
}

.warning-icon {
  margin-right: 0.5em;
}

.edit-purchase-item {
  color: #002989 !important;
  font-weight: bold;
}

.edit-purchase-icon {
  color: #002989 !important;
  font-weight: bold;
  margin-right: 10px;
}

.col-md-12.text-right.item-update-button-group {
  margin-top: 3rem;
  margin-bottom: 1rem;
}
