.sale-invoice-main-container{
  display: flex;
  flex-direction: column;
  height: 82vh;
  background-color: white;
  padding: 0.8rem;
  border-radius: 1rem;
  margin-top: 1rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.sale-invoice-container-first-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sale-invoice-first-left {
  display: flex;
  align-items: center;
}

.sale-invoice-first-right {
  display: flex;
  justify-content: flex-end;
}

button.sale-invoice-first-right-btn {
  display: flex;
  align-items: center;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  background-color: #002989;
  gap: 1rem;
}

span.all-sale-invoice-show {
  color: #002989;
  font-size: x-large;
  font-weight: bold;
}

.sale-invoice-second-div-part {
  display: flex;
  gap: 1rem;
}

.sale-invoice-second-three-dot-group {
  cursor: pointer;
}

span.sale-invoice-icon-between {
  border-left: 2px solid white;
  height: 2rem;
  position: absolute;
  left: 56.5rem;
  top: 25%;
}

.sale-invoice-turn-icon-grp,
.sale-invoice-down-icon-grp {
  cursor: pointer;
}

.sale-invoice-container-second-div {
  margin-top: 0.5rem;
}

.sale-invoice-second-div-right {
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;
}

.sale-invoice-second-drop-first {
  background-color: #002989;
  color: white;
  padding: 0.2rem 0.5rem;
  display: flex;
  gap: 1rem;
  border-radius: 0.5rem;
  height: 2rem;
}

.sale-invoice-second-filter {
  background-color: #002989;
  color: white;
  padding: 0.2rem 0.5rem;
  border-radius: 0.4rem;
  cursor: pointer;
}

span.sale-invoice-ellips-icon {
  color: #002989;
  font-size: x-large;
  font-weight: bold;
}

/* Table group */
.sale-invoice-table-group {
  overflow-x: auto;
  border-bottom: 1px solid #ccc;
  margin-top: 1rem;
  overflow-y: auto;
  max-height: 350px;
}

.sale-invoice-table {
  width: 100%;
  border-collapse: collapse;
}

.sale-invoice-thead-row th {
  padding: 0.4rem;
  border-bottom: 1px solid #ccc;
  text-align: left;
  border: 1px solid #ccc;
  font-size: small;
  font-weight: bold;
}

.sale-invoice-table-body td {
  padding: 0.2rem;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  text-align: left;
  font-size: small;
}

.sale-invoice-tab-td {
  font-size: small;
}

/* sale invoice action css */
.sale-invoice-action-popup {
  position: absolute;
  top: 18rem;
  right: 1rem;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: 15%;
}

svg.svg-inline--fa.fa-caret-down.sale-invoice-table-body-td {
  margin-left: 0.5rem;
}

ul.sale-invoice-down-icon-action-ul {
  padding: 0px;
  margin-bottom: 0px;
}

li.sale-invoice-down-icon-action-li {
  padding: 0.2rem;
  font-size: small;
}

.sale-invoice-down-icon-action-li:hover{
  background-color: #ccc;
  cursor: pointer;
}

.sale-invoice-action-down:hover{
  cursor: pointer;
}

/* three dot popup css */
.sale-invoice-column-filter-popup {
  position: absolute;
  top: 13rem;
  right: 1rem;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 10;
  width: 15%;
}

.sale-invoice-column-filter-popup label {
  display: flex;
  margin-bottom: 0.5rem;
  font-size: x-small;
}

input.sale-invoice--visible-column-input {
  width: 10%;
  margin-right: 4%;
}

button.sale-invoice-visible-box-btn {
  background-color: #002989;
  color: white;
  padding: 0.1rem 0.2rem;
  font-size: small;
  border: none;
  border-radius: 0.2rem;
}

.open-new-sale-invoice-popup{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  z-index: 1000
}

.new-sale-invoice-popup-subcontainer {
  max-height: 600px;
  overflow-y: auto;
}

.new-sale-invoice-second-billing,
.new-sale-invoice-second-shipping {
  display: flex;
  flex-flow: column;
  word-wrap: break-word;
  width: 25%;
}

span.new-sale-invoice-bill-title,
.new-sale-invoice-shipping-title {
  font-weight: bold;
}
