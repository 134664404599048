.button-row {
  display: flex;
  justify-content: space-between;
  width: max-content;
}

.full-width-button {
  flex: 1;
  margin-right: 50px;
  border-radius: 7px;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #474DDC;
}

.small-upload-button {
  background: #002989 !important;
  color: white;
  border-radius: 7px;
  margin-bottom: 10px;
  padding: 10px;
}

.small-upload-button-second {
  background: #474DDC;
  color: white;
  border-radius: 7px;
  margin-bottom: 10px;
  padding: 10px;
  margin-top: 1rem;
}

.col-md-4 {
  margin-top: 2rem;
}

.horizontal-line {
  border-bottom: 1px solid #c3c3c3;
  border-top: 2px solid #ccc;
  margin: 20px 0;
}

.create-item {
  margin-bottom: 10px;
  padding: 25px 20px;
  border: 1px solid #474DDC;
  border-radius: 1px solid #474DDC;
}
.container {
  min-height: 100px;
  height: auto; /* Adjust height based on content */
}
@media (max-width: 768px) {
  .container {
    grid-template-columns: 1fr; /* Stack fields on smaller screens */
  }
}


.create-item .col-md-12 {
  margin: 7px 0;
}

.create-item .col-md-4 {
  margin: 7px 0;
}

.col-md-6,
form {
  margin-left: 10px;
}

.full-width-button {
  display: flex;
  align-items: center;
  justify-content: left
}

.button-text {
  margin-right: 5px;
}

.icon-wrapper {
  margin-left: 0.5rem;
}

.small-upload-button-second,
.second-span-class {
  margin-left: 10px;
}

.small-upload-button-second {
  padding-right: 10px;
}

.file-input {
  display: none;
}

.form-control {
  border-color: black;
  padding: 5px;
  height: 3rem;
}

.item-btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #002989;
  border-radius: 0.6rem;
  text-decoration: none;
  color: white;
  margin-top: 2%;
  margin-bottom: -2%;
}

.plus-icon {
  margin-right: 5px;
}

.col-md-9,
.attribute-name {
  text-align: right;
}

.browser-image {
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  padding: 0.6rem;
  margin-top: 1.5rem;
  text-align: left;
  width: 100%;
}

.col-sm-6,
.col-area {
  margin-top: 1rem;
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
  margin-right: 2rem;
  margin-top: 0.4rem;
}

.third-span-class {
  margin-left: 24rem;
}

.item-main-content {
  display: flex;
  flex-direction: column;
  height: 80vh;
  background-color: #fff;
  padding: 17px;
  border-radius: 15px;
  overflow-y: auto;
  max-height: 86vh;
}

.item-main-sub-content {
  display: flex;
  flex-direction: column;
  height: 5rem;
  background-color: #fff;
  padding: 8px;
  border-radius: 15px;
}

.item-add-price-content {
  display: flex;
  flex-direction: column;
  height: 48vh;
  background-color: #fff;
  padding: 0px;
  border-radius: 15px;
}

.item-table-content {
  display: flex;
  flex-direction: column;
  height: 80vh;
  background-color: #fff;
  padding: 17px;
  border-radius: 15px;
  overflow: hidden;
}

.table-container {
  flex: 1;
  overflow-y: auto;
  position: relative;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.thead-fixed {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.tbody-sticky {
  position: relative;
}

tbody tr:last-child td {
  border-bottom: 0;
}

th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

td {
  vertical-align: middle;
}

th {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;
}

td img {
  max-width: 50px;
}

.setting-icon {
  color: #0d6efd;
}

.file-color-name,
.file-color-image {
  color: #474DDC;
  margin-top: -2rem;
}

.heading-title {
  font-family: 'Poppins', sans-serif;
  font-size: 22px;
  color: #3e429d !important;
  font-weight: 600;
  line-height: 33px;
}

.button-spacing {
  margin-left: 1rem;
}

input.required-field,
select.required-field {
  border: 1px solid red !important;
}

.custom-select {
  position: relative;
}

.custom-select select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.select-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  color: grey;
}

.bulk-perform {
  color: #474DDC;
  margin-top: 0.2;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background: white;
  padding: 50px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
  position: relative;
}

.modal-image {
  position: relative;
  display: block;
  max-width: 100%;
  height: auto;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.image-close {
  position: absolute;
  top: 35px;
  left: 62%;
  background: white;
  color: black;
  cursor: pointer;
  font-size: 30px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.close-sign {
  height: 1rem;
  margin-top: 0rem;
  position: absolute;
  top: -28px;
  left: 28rem;
}

.Qty-edit-icon {
  color: #0d6efd;
  float: right;
}

.item-add-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.radio-group {
  display: flex;
  align-items: center;
}

.item-label {
  font-size: 16px;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-right: 15px;
}

.radio-group input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom-radio {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 2px solid #ccc;
  transition: background-color 0.3s, border-color 0.3s;
}

input:checked~.custom-radio {
  background-color: #474DDC;
}

.ml-3 {
  margin-left: 15px;
}

.label-item-checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding-left: 35px;
  font-size: 16px;
  margin-top: 0.8rem;
}

.item-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom-checkbox {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 4px;
  border: 2px solid #ccc;
  transition: background-color 0.3s, border-color 0.3s;
}

.item-checkbox:checked~.custom-checkbox {
  background-color: #474DDC;
}

.item-checkbox:checked~.custom-checkbox::after {
  content: '\2714';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 14px;
}

.label-item-checkbox:hover .custom-checkbox {
  border-color: #888;
}

.add-setting-close-sign {
  height: 3rem;
  margin-top: 0rem;
}

.add-close {
  position: absolute;
  top: 65px;
  left: 62%;
  background: white;
  color: black;
  cursor: pointer;
  font-size: 30px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

select.form-control.default-selection {
  color: gray;
  border: 1px solid #b9b9b9;
}

/* .text-right.search-adjust {
  margin-bottom: 1rem;
} */

.small-image {
  width: 120px;
  height: auto;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-top: 0.5rem;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #474DDC;
}

input:focus+.slider {
  box-shadow: 0 0 1px #474DDC;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

option {
  color: black;
}

.icons {
  display: flex;
  align-items: center;
  gap: 4px;
}

input[disabled][data-tooltip-id],
select[disabled][data-tooltip-id] {
  background-color: #ccc !important;
  color: #999 !important;
}

.back-btn {
  margin-bottom: 10px;
}

span.tax-per-unit {
  margin-left: 1px;
  color: grey;
}

.check-symbol {
  color: green;
  padding: 2px 6px;
  font-size: larger;
}

.cross-symbol {
  color: red;
  padding: 2px 6px;
  font-size: larger;
}

.item-detail-value {
  flex-grow: 1;
  margin-left: 300px;
}
.dynamic-fields-container {
  margin-bottom: 20px;
}

.row {
  display: grid;
  /* flex-wrap: wrap; */
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.col-md-3.cd{
  display: flex;
}

.col-md-3 {
  flex: 0 0 25%;
  max-width: 25%;
  padding: 10px;
}

.form-control {
  width: 100%; 
}

@media (max-width: 768px) {
  .row {
    grid-template-columns: repeat(2, 1fr); 
  }
}

@media (max-width: 480px) {
  .row {
    grid-template-columns: 1fr;
  }
}

.item-detail-title-content {
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 4rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.sub-container-item-details {
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
  padding-top: 2rem;
  padding-bottom: 2rem;
  height: 44rem;
}

.sub-container-item-details::-webkit-scrollbar {
  width: 10px;
}

.sub-container-item-details::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

.sub-container-item-details::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.item-detail-title {
  color: #3e429d;
  margin-left: 1rem;
  font-weight: bold;
}

.tax-item {
  float: right;
}

.edit-item-attributes {
  color: #0d6efd;
  font-weight: bold;
}

.barcode-print-icon {
  color: #0d6efd;
  font-weight: bold;
}

.item-eye-icon {
  color: #0d6efd;
  font-size: larger;
  font-weight: bold;
}

.update-item-close {
  position: absolute;
  top: -1rem;
  left: 33rem;
  color: #474DDC;
  cursor: pointer;
  font-size: 45px;
  border-radius: 20%;
  width: 1px;
  height: 40px;
  display: flex;
  justify-content: center;
}

.update-item-popup-content {
  padding: 40px;
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 30%;
  width: 100%;
  position: relative;
  height: 50%;
}

.error-message {
  color: red;
  font-size: 0.8rem;
  margin-top: 5px;
}

.purchase-item-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

svg.svg-inline--fa.fa-plus.add-purchase-plus-icon {
  color: #002989 !important;
  font-weight: bold;
  font-size: larger;
}

.add-purchase-label {
  color: #002989 !important;
  font-weight: bold;
  margin-left: 10px;
}

.edit-purchase-icon {
  color: #002989 !important;
  font-weight: bold;
}

.col-md-12.text-right.item-create-button-group {
  margin-top: 4rem;
  margin-bottom: 1rem;
}

.col-sm-4.mt-2-item-image {
  margin-top: 8px;
}

.tax-item-container {
  display: flex;
  gap: 1rem;
}

/* Center the container div */
.bill-image-save-cancel-inventery {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bill-image-save-cancel-inventery .row {
  width: 100%;
}

.bill-image-save-cancel-inventery .col-md-8 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.bill-image-save-cancel-inventery .col-md-4 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.bulk-upload-error-download-link {
  color: red; 
  text-decoration: underline; 
  cursor: pointer; 
  margin-top: 0.5rem;
  margin-left: 1rem;
}

.bulk-upload-error-download-link:hover {
  color: darkred;
}

.col-md-8.item-file-download-class {
  display: flex;
}

.bulk-upload-error-file-note {
  margin-left: 1rem;
}

.modal-content {
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}


.item-unauthorize-modal-popup{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-unauthorize-second-modal-popup{
  border: 1px solid;
  height: 50%;
  width: 50%;
  background-color: white;
  color: black;
}

.loader {
  display: inline-block;
  margin-left: 10px;
}

.loader .dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #333;
  animation: dot-bounce 1.4s infinite ease-in-out;
}

.loader .dot:not(:last-child) {
  margin-right: 5px;
}

.loader .dot:nth-child(1) {
  animation-delay: -0.32s;
}

.loader .dot:nth-child(2) {
  animation-delay: -0.16s;
}

.loader .dot:nth-child(3) {
  animation-delay: 0s;
}

.progress-message{
  margin-left: 0.5rem;
  color: #002989;
  font-weight: bold;
}

@keyframes dot-bounce {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.z-50 {
  z-index: 50;
}

/* Add to your CSS file */
.expiry-header-container {
  position: relative;
  display: inline-block;
}

.expiry-header-trigger {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 8px;
}

.expiry-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  min-width: 200px;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 21px;
}

.expiry-dropdown-content {
  display: flex;
  flex-direction: column;
}

.dropdown-item {
  padding: 8px 16px;
  text-align: left;
  border: none;
  background: none;
  width: 100%;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f7fafc;
}

.custom-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.calendar-container {
  position: absolute;
  left: 100%;
  top: 0;
  margin-left: 8px;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.transfer-item-table{
  margin-left: 80rem;
}
.pagination-new-right-align {
  display: flex;
}

.filter-controls {
  display: flex;
  gap: 1rem;
}

select.form-select.dis-extra-height {
  height: 3rem;
  border: 1px solid #b9b9b9;
  margin-top: 0.2rem;
}



/*  */
.dynamic-fields-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
}

.field-item {
  display: flex;
  flex-direction: column;
}

.field-label {
  margin-bottom: 8px;
}

.field-input,
.radio-group {
  width: 100%;
}

.radio-group {
  display: flex;
  align-items: center;
  gap: 8px;
}

.radio-label {
  display: flex;
  align-items: center;
  gap: 4px;
}

.radio-label .custom-radio {
  width: 16px;
  height: 16px;
  /* border: 2px solid #333; */
  border-radius: 50%;
  margin-right: 8px;
  margin-bottom: -17px;
  position: relative;
}

.radio-label input[type="radio"]:checked + .custom-radio {
  background-color: #007bff;
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  margin-bottom: -17px;
}
/*  */
.cursor {
  cursor: pointer;
}

.custmodal{
  width: 410px;
  padding: 7px;
}

.custmodal .css-1nmdiq5-menu {
  color: black !important;
}

