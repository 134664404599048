.table-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1px;
  gap: 5px;
}

.table-button {
  background-color: transparent;
  font-size: 16px;
  border-radius: 0px !important;
  padding: 8px 16px;
  cursor: pointer;
  color: white;
  border: none;
}

.dropdown {
  display: inline-block;
}

.table1 {
  background-color: grey;
  border-radius: 5px;
}

.table2 {
  background-color: #0a951c;
  border-radius: 5px;
}

.table3 {
  background-color: orange;
  border-radius: 5px;
}

.table4 {
  background-color: #0056b3;
  border-radius: 5px;
}

.dropdown-button {
  background-color: transparent;
  border: none;
  font-size: 16px;
  padding: 8px 16px;
  cursor: pointer;
}

.dropdown-button:hover {
  background-color: #aaacb5;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-item {
  color: rgb(113, 50, 50);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.highlight {
  background-color: yellow !important;
}

.table1 .table-button:hover,
.table1 .table-button.active {
  background-color: #696969;
  border-color: black;
  border: 10px;
  border-block: revert;
}

.table2 .table-button:hover,
.table2 .table-button.active {
  background-color: #03500d;
  border-color: black;
  border-block: revert;

}

.table3 .table-button:hover,
.table3 .table-button.active {
  background-color: #b87800;
  border-color: black;
  border-block: revert;

}

.table4 .table-button:hover,
.table4 .table-button.active {
  background-color: #0044ff;
  border-color: black;
  border-block: revert;

}

button.restaurant-main-header-buttons {
  line-height: 2rem;
  background-color: whitesmoke;
  padding: 5px 6px;
  word-wrap: break-word;
  border: none;
}

button.restaurant-main-header-buttons:hover {
  color: white;
  background-color: grey;
}

