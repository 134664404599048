.debit-note-main-container{
  display: flex;
  flex-direction: column;
  height: 82vh;
  background-color: white;
  padding: 0.8rem;
  border-radius: 1rem;
  margin-top: 1rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.debit-note-container-first-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.debit-note-first-left {
  display: flex;
  align-items: center;
}

.debit-note-first-right {
  display: flex;
  justify-content: flex-end;
}

button.debit-note-first-right-btn {
  display: flex;
  align-items: center;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  background-color: #002989;
  gap: 1rem;
}

span.all-debit-note-show {
  color: #002989;
  font-size: x-large;
  font-weight: bold;
}

.debit-note-second-three-dot-group {
  cursor: pointer;
}

span.debit-note-icon-between {
  border-left: 2px solid white;
  height: 2rem;
  position: absolute;
  left: 56.5rem;
  top: 25%;
}

.debit-note-turn-icon-grp,
.debit-note-down-icon-grp {
  cursor: pointer;
}

.debit-note-container-second-div {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.debit-note-second-div-right {
  display: flex;
  float: right;
  gap: 1.5rem;
}

.debit-note-second-drop-first {
  background-color: #002989;
  color: white;
  padding: 0.2rem 0.5rem;
  display: flex;
  gap: 1rem;
  border-radius: 0.5rem;
  height: 2rem;
}

.debit-note-second-filter {
  background-color: #002989;
  color: white;
  padding: 0.2rem 0.5rem;
  border-radius: 0.4rem;
  cursor: pointer;
}

span.debit-note-ellips-icon {
  color: #002989;
  font-size: x-large;
  font-weight: bold;
}

span.all-debit-note-show{
  color: #002989;
  font-size: x-large;
  font-weight: bold;
}

/* Table group */
.debit-note-table-group {
  overflow-x: auto;
  border-bottom: 1px solid #ccc;
  margin-top: 1rem;
  overflow-y: auto;
  max-height: 350px;
}

.debit-note-table {
  width: 100%;
  border-collapse: collapse;
}

.debit-note-thead-row th {
  padding: 0.4rem;
  border-bottom: 1px solid #ccc;
  text-align: left;
  border: 1px solid #ccc;
  font-size: small;
  font-weight: bold;
}

.debit-note-table-body td {
  padding: 0.2rem;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  text-align: left;
  font-size: small;
}

.debit-note-tab-td {
  font-size: small;
}

.credit-action-down-icon{
  margin-left: 0.5rem;
}

/* three dot popup css */
.debit-note-column-filter-popup {
  position: absolute;
  top: 13rem;
  right: 1rem;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 10;
  width: 15%;
}

.debit-note-column-filter-popup label {
  display: flex;
  margin-bottom: 0.5rem;
  font-size: x-small;
}

input.debit-note--visible-column-input {
  width: 10%;
  margin-right: 4%;
}

button.debit-note-visible-box-btn {
  background-color: #002989;
  color: white;
  padding: 0.1rem 0.2rem;
  font-size: small;
  border: none;
  border-radius: 0.2rem;
}

/* credit note down icon action css */
.debit-note-down-icon-action{
  position: absolute;
  top: 19rem;
  right: 1rem;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
  width: 15%;
}

svg.svg-inline--fa.fa-caret-down.debit-note-table-body-td {
  margin-left: 0.5rem;
}

ul.debit-note-down-icon-action-ul {
  padding: 0px;
  margin-bottom: 0px;
}

li.debit-note-down-icon-action-li {
  padding: 0.2rem;
  font-size: small;
}

.debit-note-down-icon-action-li:hover{
  background-color: #ccc;
  cursor: pointer;
}

.new-debit-note-popup-container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  z-index: 1000
}

.debit-note-seond-icon-part {
  display: flex;
  gap: 1rem;
}
