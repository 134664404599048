.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.pax-selector-container {
  background-color: white;
  border-radius: 5px;
  width: 50%;
  max-width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.pax-heading-title {
  margin-top: 0%;
  padding: 10px;
  background-color: #01228f;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.heading-select-pax-captain-table {
  font-size: 18px;
  font-weight: bold;
}

.pax-close-icon {
  cursor: pointer;
}

.main-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.pax-selection,
.captain-selection {
  text-align: center;
}

.pax-grid,
.captain-grid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 10px;
}

.pax-button {
  background-color: #01346b;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 2px;
  cursor: pointer;
}

.captain-button {
  background-color: #f5c21a;
  color: rgb(2, 17, 160);
  border: none;
  padding: 10px;
  border-radius: 2px;
  cursor: pointer;
}

.pax-button.selected,
.captain-button.selected {
  background-color: #ffc107;
}

.pax-button:hover,
.captain-button:hover {
  background-color: #0056b3;
}

.pax-close-icon svg {
  fill: #000;
  width: 24px;
  height: 24px;
}

.pax-grid-group {
  overflow-y: auto;
  max-height: 250px;
}
