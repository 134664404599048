.cc-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.cc-modal-content {
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 400px;
  max-width: 90%;
}

.cc-modal-header {
  width: 100%;
  background-color: #002989 !important;
  height: 3rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}

span.cc-modal-title {
  font-size: large;
  font-weight: bold;
}

.cc-modal-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: white;
}

.cc-modal-body {
  margin-bottom: 20px;
  display: flex;
}

.cc-modal-body label {
  display: block;
  margin-bottom: 5px;
}

.cc-modal-body input,
.cc-modal-body select {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.cc-modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.cc-modal-footer button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cc-modal-footer .cc-modal-btn-create {
  background-color: #002989;
  color: white;
  margin-top: 3%;
}

.cc-modal-footer .cc-modal-btn-cancel {
  background-color: #002989;
  color: white;
  margin-top: 3%;
}

.category-content {
  height: auto;
  /* max-height: 800px;
  overflow-y: scroll; */
  width: auto;
}

.category-table-view {
  height: auto;
  max-height: 550px;
  overflow-y: auto;
}

.category-headers-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.category-table-list {
  overflow-y: auto;
  max-height: 450px;
}

.category-header-controls {
  display: flex;
}

.item-category-second-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.category-pagination-dropdown{
	display: flex;
	align-items: center;
	height: 3rem;
}

.category-main-container{
  display: flex;
  flex-direction: column;
  height: auto;
  background-color: #fff;
  padding: 17px;
  border-radius: 15px;
  overflow: hidden;
}

.cc-model-sub-container {
  padding: 1rem;
}

.category-image-field {
  margin-top: 0.5rem;
}