.party-billing-container {
  width: 100%;
}

.party-section-title {
  color: #333;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.party-basic-info {
  margin-bottom: 2rem;
}

.party-address-section {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-bottom: 2rem;
}

.party-address-header {
  padding: 1rem;
  background-color: #f8f9fa;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.2s ease;
}

.party-address-header:hover {
  background-color: #e9ecef;
}

.party-address-toggle {
  color: #6c757d;
  transition: transform 0.3s ease;
}

.party-address-content {
  max-height: 0;
	overflow-y: auto;
  transition: max-height 0.3s ease-out;
}

.party-address-content.expanded {
  max-height: 1000px;
  padding: 1rem;
  transition: max-height 0.3s ease-in;
}

.party-form-actions {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
}

.party-custom-modal-body.modal-body {
  overflow-y: auto;
  max-height: 800px;
  overflow-x: hidden;
}

.modal-content {
  padding: 0rem;
}
