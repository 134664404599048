.tax-billing h3 {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.tax-billing .line {
  margin-bottom: 20px;
}

.settings-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.taxbill-item-setting-label {
  display: contents;
}

.taxbill-item-setting-label label {
  grid-column: 1;
}

.taxbill-setting-item-check {
  grid-column: 2;
  justify-self: end;
  margin-right: 16rem;
}

.send-taxbill-item-setting-label {
  display: contents;
  text-transform: capitalize;
}

.send-taxbill-item-setting-label label {
  grid-column: 1;
}

.send-taxbill-setting-item-check {
  grid-column: 2;
  justify-self: end;
  margin-right: 14rem;
}

.item-setting-label {
  display: contents;
  text-transform: capitalize;
}

.item-setting-label label {
  grid-column: 1;
}

.setting-item-check {
  grid-column: 2;
  justify-self: end;
  margin-right: 20rem;
}


label.taxbill-item-setting-label {
  text-transform: capitalize;
}

/* =================== */

body {
  font-family: Arial, sans-serif;
  color: #333;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}

.col {
  flex: 1;
  padding: 10px;
}

.settings-container {
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  min-height: 680px;
}

h3 {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

h3 svg {
  margin-right: 10px;
}

.line {
  margin-bottom: 20px;
  border: none;
  border-top: 1px solid #ddd;
}

.settings-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.settings-item {
  /* display: flex; */
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.settings-item label {
  font-weight: bold;
  margin-right: 10px;
  flex-grow: 1;
}

.settings-item input[type="checkbox"] {
  margin-right: 5px;
}

.toggle-buttons {
  display: block;
  position: relative;
  width: 50px;
  height: 24px;
}

.toggle-buttons input {
  opacity: 0;
  width: 0;
  height: 0;
}

.tax-billing-sliders {
  width: 3rem;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}

.tax-billing-sliders:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 0px;
  bottom: -1px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
  border: 1px solid grey;
}

input:checked+.tax-billing-sliders {
  background-color: #002989;
}

input:checked+.tax-billing-sliders:before {
  transform: translateX(26px);
}

label.toggle-buttons {
  float: right;
}

