.common-temp-setting-main-container {
  height: 95vh;
  background-color: #fff;
  overflow: auto;
  width: 100%;
}

.common-temp-setting-header-grp {
  border: 1px solid;
  line-height: 3rem;
  background-color: #002989;
  color: white;
  display: flex;
  justify-content: space-between;
}

span.temp-setting-head-left-title {
  font-size: larger;
  font-weight: bold;
  margin-left: 1rem;
}

span.temp-setting-head-right-icon {
  font-size: larger;
  font-weight: bold;
  margin-right: 1rem;
}

.common-temp-setting-sub-container {
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
}

.temp-setting-sub-left-box {
  width: 40%;
  margin-left: 0.5rem;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  background-color: white;
  padding: 1rem;
  height: auto;
  overflow-y: auto;
}

.temp-setting-sub-right-box {
  width: 60%;
}

.temp-setting-title-text-grp {
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
}

input.form-control.title-text-input {
  height: 2.5rem;
  width: 40%;
}

.temp-setting-first-div {
  padding: 1rem;
}

/*  print header css*/
.setting-temp-header-grp-box,
.temp-setting-grid-box {  
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  margin-left: -1.5rem;
}

input.form-control.head-temp-input-field {
  height: 1.5rem;
  width: 1.5rem;
}

.setting-temp-head-div-grp {
  display: flex;
  width: 20rem;
  margin-bottom: 0.5rem;
}

label.setting-temp-header-label {
  width: 15rem;
}

input.head-temp-input-field {
  width: 30%;
}

.setting-temp-footer-left{
  margin-left: -1.5rem;
}

span.setting-temp-head-title,
.setting-grid-title, 
.setting-temp-footer-title{
  font-size: larger;
  font-weight: bold;
}

.setting-temp-header-grp,
.setting-temp-grid-grp,
.setting-temp-footer-grp
{
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  background-color: white;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
}

.setting-temp-head-title-grp,
.setting-grid-title-grp,
.setting-temp-footer-title-grp{
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.temp-setting-head-right-icon:hover {
  cursor: pointer;
}

.free-text-popup-container-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  z-index: 1000;
}

.common-temp-setting-left-header-grp {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

button.btn-btn-primary {
  border: none;
  border-radius: 0.2rem;
  padding: 0.4rem 0.8rem;
  color: white;
  background-color: #002989;
}

span.temp-setting-head-title {
  font-size: larger;
  font-weight: bold;
}

.temp-setting-left-box {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  background-color: white;
  padding: 1rem;
  margin-top: 0.5rem;
}

span.temp-setting-option-title {
  font-size: larger;
  font-weight: bold;
}

input.temp-cat-input-option {
  width: 10%;
}
