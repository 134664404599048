.purchase-amount-qty-edit-container {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 40%;
	height: 70%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.purchase-amount-edit-qty-modal-content {
	background-color: #fff;
	padding: 25px;
	border-radius: 8px;
	position: fixed;
	width: 50%;
	height: auto;
}

.purchase-amount-qty-edit-form {
	margin-top: 3rem;
	/* margin-left: 0px; */
}

.purchase-amount-edit-item-qty-form-group {
	display: flex;
	align-items: center;
	margin-bottom: 2.8vh;
	font-size: large;
	align-items: center;
	gap: 1rem;
}

.purchase-amount-flexbox {
	display: flex;
	justify-content: center;
}

.purchase-amount-update-btn {
	background-color: #474DDC;
	color: #fff;
	padding: 10px 20px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	margin-top: 1.5vh;
	display: flex;
	justify-content: center;
}

label.purchase-amount-edit-item-qty-form-group {
	margin-right: 2rem;
}

input.purchase-amount-edit-item-qty-form-group {
   width: 96%;
}

.purchase-amount-qty-input-select {
	border: 1px solid black;
	padding-left: 10px;
}

.purchase-amount-close-btn {
  position: absolute;
  top: 22px;
  left: 44%;
  background: white;
  color: black;
  cursor: pointer;
  width: 100%;
  height: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: x-large;
}
