.payment-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
  }
  
  .payment-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: white;
    border-radius: 10px;
    outline: none;
    height: 167px;
    width: 36em;
  }
  
  .payment-options {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 11px;
  }
  
  .payment-option {
    flex: 1 1 30%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }

  .payment-heading{
    padding: 0;
    margin: 0;
    background-color: #dd6969;
    color: white;
    font-weight: 400;
    height: 54px;
    padding: 12px;
  }
  
  .payment-option.cash { background-color: green; color: white; }
  .payment-option.card { background-color: gold; color: black; }
  .payment-option.paytm { background-color: deepskyblue; color: white; }
  .payment-option.upi { background-color: dodgerblue; color: white; }
  .payment-option.multi { background-color: gray; color: white; }
  .payment-option.cancel { background-color: red; color: white; }
  