.new-purchase-bill-main-container {
  height: 95vh;
  width: 100%;
  background-color: white;
  border-radius: 0.4rem;
  overflow-y: auto;
}

.new-purchase-bill-sub-container {
  max-height: 300px;
  padding: 1rem;
}

.new-purchase-bill-first-div {
  display: flex;
  gap: 3rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.new-purchase-bill-estimate-no-grp {
  display: flex;
  flex-flow: column;
  width: 20%;
  gap: 0.5rem;
}

.new-purchase-bill-estimate-date-grp {
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
}

span.new-purchase-bill-estdate-input{
  display: flex;
  justify-content: center;
}

.new-purchase-bill-tilldate-grp {
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
}

span.new-purchase-bill-est-tilldate-input{
  display: flex;
  justify-content: center;
}

.new-purchase-bill-reference-grp{
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
}

span.new-purchase-bill-est-reference-input{
  display: flex;
  justify-content: center;
}

.new-purchase-bill-second-div {
  margin-top: 1rem;
  display: flex;
}

.new-purchase-bill-customer-div {
  display: flex;
  flex-flow: column;
  margin-left: 1rem;
}

input.form-control.new-purchase-bill-cust-field {
  width: 30%;
}

.new-purchase-bill-third-div {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

textarea.new-purchase-bill-term-textarea-input,
.new-purchase-bill-note-textarea-input{
  border-radius: 0.5rem;
  border: 1px solid #ccc;
  width: 100%;
  height: 100%;
}

textarea.new-purchase-bill-term-textarea-input:focus,
.new-purchase-bill-note-textarea-input:focus{
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
}

span.new-purchase-bill-est-no-title,
.new-purchase-bill-estdate-title,
.new-purchase-bill-est-tilldate-title,
.new-purchase-bill-est-reference-title,
.new-purchase-bill-cust-title,
.new-purchase-bill-buyer-order-title,
.new-purchase-bill-buyer-order-date-title {
  font-size: small;
}

span.new-purchase-bill-est-no {
  font-size: large;
  font-weight: bold;
  display: flex;
  justify-content: left;
  margin-left: 1rem;
}

input.form-control.new-purchase-bill-first-div-input {
  height: 2rem;
}

input.form-control.new-purchase-bill-cust-field {
  height: 2rem;
  width: 60%;
}

input.form-control.new-purchase-bill-buyer-order,
.new-purchase-bill-buyer-order-date {
  height: 2rem;
  width: 60%;
}

.new-purchase-bill-note-grp {
  display: flex;
  flex-flow: column;
  margin-left: 1rem;
}

.new-purchase-bill-terms-condition-grp {
  display: flex;
  flex-flow: column;
}

.new-purchase-bill-terms-condition-grp {
  display: flex;
  flex-flow: column;
}

input.form-control.new-purchase-bill-last-input {
  height: 2rem;
  width: 100%;
}

.new-purchase-bill-table {
  margin-top: 2rem;
  margin-bottom: 4rem;
}

input.form-control.new-purchase-bill-buyer-order-date {
  width: 100%;
}

.new-purchase-bill-customer-div {
  display: flex;
  flex-flow: column;
  margin-left: 1rem;
}

input.form-control.new-purchase-bill-cust-field {
  width: 100%;
}

.new-purchase-bill-cust-title{
  font-size: small;
}

.new-purchase-bill-customer-dropdown {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  background-color: white;
  position: absolute;
  width: 100%;
  z-index: 1000;
  top: 100%;
  left: 0;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.new-purchase-bill-dropdown-item {
  padding: 0.2rem 0.5rem;
  cursor: pointer;
}

.new-purchase-bill-dropdown-item:hover {
  background-color: #f1f1f1;
}

.new-purchase-bill-cust-search {
  position: relative;
}

.new-purchase-bill-buyer-order-grp {
  margin-left: 2rem;
}