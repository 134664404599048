.App.error {
    position: fixed; /* Fix the position of the component */
    top: 0; /* Position at the top */
    left: 0; /* Position at the left */
    width: 100%; /* Take up the full width */
    height: 100%; /* Take up the full height */
    background-image: url("/public/image4.png");
    background-size: cover; /* Cover the entire element */
    background-position: left top; /* Position the image at the top-left */
    z-index: 9999;
  }

  .button-contents .error-page {
    margin-right: 10px;
    padding: 10px;
    background: conic-gradient(from 0turn at 50% 30%, rgba(241, 241, 241, 0.966), 243deg, #fdfdfd, 354deg, #f1efef);
    border-radius: 50px;
    color: #fdfdfd;
    text-decoration: none;
}

.btn-error {
    margin-top: 290px;
    width: 108px;
}