.new-purchase-expenses-main-container {
  height: auto;
  width: 100%;
  background-color: white;
  border-radius: 0.4rem;
}

.new-purchase-expenses-sub-container {
  overflow-y: auto;
  max-height: 500px;
  padding: 1rem;
}

.new-purchase-expenses-first-div {
  display: flex;
  gap: 3rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.new-purchase-expenses-estimate-no-grp {
  display: flex;
  flex-flow: column;
  width: 20%;
  gap: 0.5rem;
}

.new-purchase-expenses-estimate-date-grp {
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
}

span.new-purchase-expenses-estdate-input{
  display: flex;
  justify-content: center;
}

input.form-control.new-purchase-expenses-cust-field {
  width: 30%;
}

.new-purchase-expenses-third-div {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

textarea.new-purchase-expenses-term-textarea-input,
.new-purchase-expenses-note-textarea-input{
  border-radius: 0.5rem;
  border: 1px solid #ccc;
  width: 100%;
  height: 100%;
}

textarea.new-purchase-expenses-term-textarea-input:focus,
.new-purchase-expenses-note-textarea-input:focus{
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
}

span.new-purchase-expenses-est-no-title,
.new-purchase-expenses-estdate-title,
.new-purchase-expenses-buyer-order-date-title {
  font-size: small;
}

span.new-purchase-expenses-est-no {
  font-size: large;
  font-weight: bold;
  display: flex;
  justify-content: left;
  margin-left: 1rem;
}

.new-purchase-expenses-note-grp {
  display: flex;
  flex-flow: column;
  margin-left: 1rem;
}

.new-purchase-expenses-terms-condition-grp {
  display: flex;
  flex-flow: column;
}

.new-purchase-expenses-terms-condition-grp {
  display: flex;
  flex-flow: column;
}

input.form-control.new-purchase-expenses-last-input {
  height: 2rem;
  width: 100%;
}

.new-purchase-expenses-table {
  margin-top: 2rem;
  margin-bottom: 4rem;
}

input.form-control.new-purchase-expenses-buyer-order-date {
  width: 100%;
}
