.show-customer-main-container {
    background-color: #fff;
    padding: 17px;
    border-radius: 15px;
    max-width: 100%;
    height: auto;
  }
  
  .show-customer-header-container {
    background: white;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    line-height: 4rem;
  }
  
  .customer-show-details-title {
    color: #3e429d;
    margin-left: 1rem;
    font-weight: bold;
    font-size: x-large;
  }
  
  .show-customer-second-container {
    background: white;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
    height: 40rem;
    margin-top: 1rem;
    overflow-y: auto;
  }
  
  .customer-detail-row {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .show-customer-detail-label {
    width: 200px;
    font-weight: bold;
    flex-shrink: 0;
    margin-left: 2rem;
  }
  
  .customer-detail-value {
    flex-grow: 1;
    margin-left: 300px;
  }
  