.order-letter-pad-main-container {
  width: 100vw;
  height: auto;
  max-width: 794px;
  margin: 0 auto;
  background-color: white;
  box-sizing: border-box;
  overflow: hidden;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

.order-letter-pad-sub-container {
  padding: 1rem;
}

.order-letter-pad-header-div {
  padding: 0.5rem;
  border: 1px solid;
  display: flex;
  justify-content: space-between;
  background-color: #002989;
  color: white;
}

.order-letter-pad-horizontal-line {
  width: 100%;
  height: 1px;
  background-color: black;
  margin: 1rem 0;
}

span.order-letter-pad-header-title {
  text-align: center;
  font-size: larger;
  font-weight: bold;
}

span.order-letter-pad-header-left-title {
  font-size: larger;
  font-weight: bold;
}

span.order-letter-pad-header-right-title {
  font-size: larger;
  font-weight: bold;
}

span.order-letter-pad-cust-title {
  font-size: medium;
  font-weight: bold;
}

span.order-letter-pad-cust-sub-titles {
  font-size: small;
  font-weight: bold;
}

span.order-letter-pad-order-no-title,
.order-letter-pad-order-date-title {
  font-size: medium;
}

span.order-letter-pad-order-value {
  font-size: small;
  font-weight: bold;
}

/* order letter pad temp table */
table.order-letter-pad-temp-table {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
  border-bottom: 1px solid;
}

tr.order-letter-pad-temp-tab-head-row,
.order-letter-pad-temp-table-body-row {
  line-height: 0.4rem;
}

th.order-letter-pad-temp-table-header-attribute {
  font-weight: bold;
  font-size: x-small;
}

.order-letter-pad-temp-table th, .order-letter-pad-temp-table td {
  border: 1px solid black;
  padding: 0.4rem;
  text-align: left;
  font-size: xx-small;
  font-weight: bold;
}

table.order-letter-pad-temp-footer-table {
  border: 1px solid black;
  margin-top: -1rem;
}

.order-letter-pad-temp-footer-data {
  display: flex;
  flex-flow: column;
  float: inline-end;
  width: 30%;
}

.order-letter-pad-temp-footer-data-row {
  display: flex;
  justify-content: space-between;
  padding: 0rem 0;
}

span.order-letter-pad-temp-footer-label {
  flex: 1 1;
  text-align: left;
  font-weight: bold;
  font-size: x-small;
}

span.order-letter-pad-temp-footer-value {
  flex: 1 1;
  text-align: right;
  font-size: x-small;
  font-weight: bold;
}

.order-letter-pad-temp-tax-details {
  font-size: 0.4rem;
  font-weight: bold;
  margin-bottom: 0.2rem;
}

span.order-letter-pad-temp-extra-row-data {
  display: flex;
  justify-content: flex-end;
}

.order-letter-pad-cust-info-grp {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.order-letter-pad-cust-info-div {
  display: flex;
  flex-flow: column;
  word-wrap: break-word;
}

.order-letter-pad-order-info-grp {
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
}

.order-letter-pad-order-info {
  display: flex;
  flex-flow: column;
}

.order-letter-pad-header-grp {
  text-align: center;
}

/* footer letter pad signature grp */
.order-letter-pad-temp-signature-section {
  padding: 1rem;
  margin-top: 1rem;
  border: 1px solid black;
}

span.order-letter-pad-temp-first-title {
  font-size: small;
  font-weight: bold;
}

.order-letter-pad-header-right-title{
  cursor: pointer;
}

.order-letter-pad-signature-first-div {
  display: flex;
  justify-content: space-between;
}

.order-letter-pad-signature-second-div {
  display: flex;
  justify-content: space-between;
  margin-top: 5rem;
}

.order-letter-pad-signature-first-part {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
}

.order-letter-pad-signature-second-part {
  display: flex;
  justify-content: flex-end;
}

@media print {
  .order-letter-pad-header-div,
  .sales-order-main-container{
    display: none !important;
  }

  .order-letter-pad-main-container {
    width: 100%;
    height: auto;
    max-width: 794px;
    margin: 0 auto;
    background-color: white;
    overflow: visible;
  }

  table.order-letter-pad-temp-table {
    width: 100%;
    border-collapse: collapse;
    margin: 1rem 0;
    border: 1px solid black;
  }

  .order-letter-pad-temp-table th {
    border: 1px solid black;
    padding: 0.4rem;
    text-align: left;
    font-size: xx-small;
    font-weight: bold;
    color: black !important;
    background-color: white !important;
  }

  tr.order-letter-pad-temp-tab-head-row {
    border: 2px solid black;
  }

  .order-letter-pad-temp-signature-section,
  .order-letter-pad-temp-footer-table,
  .order-letter-pad-header-grp {
    page-break-inside: avoid;
  }
}
