.receipt-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    text-align: center;
  }
  
  .second-receipt-main {
    background-color: #fff;
    padding: 20px;
    width: 100%;
  }
  
  .second-receipt-footer {
    text-align: center;
    margin-bottom: 20px;
  }

  .second-receipt-sub{
    font-size: small;
  }
  
  .second-receipt-sub-header {
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .receipt-date,
  .receipt-time {
    margin-bottom: 10px;
  }
  
  hr, .hr-dotted {
    border-top: 3px dotted #888;
  }
  
  .table-header-dotted {
    border-top: 3px dotted #888;
    border-bottom: 3px dotted #888;
  }
  
  .receipt-date-time{
    display: flex;
    justify-content: space-between;
  }
  
  .receipt-expenses {
    display: flex;
    margin-left: 28rem;
  }
  
  .second-company-title{
    font-size: small;
    font-weight: bold;
  }
  
  .receipt-policy{
    font-size: larger;
  }

  .receipt-second-content{
    text-align: center;
    background: #38475a;
    color: white;
  }

  .barcode-photo{
    height: 6rem;
    margin-left: 9rem;
  }

  .second-barcode{
    height: 3rem;
    width: 6rem;
  }

  .receipt-second-company-address, 
  .receipt-second-com-mail, 
  .receipt-second-content{
    font-size: small;
  }

  .cash-memo-table{
    width: 100%;
  }

  .second-receipt-table-grp{
    overflow-y: auto;
    max-height: 220px;
  }
  
  @media print{
    .hide-on-print{
        display: none;
    }

    .receipt-main {
      background-color: #fff;
      padding: 20px;
      width: 100%;
    }

    .receipt-second-content{
        text-align: center;
        background-color: #38475a;
        color: white;
    }

    .barcode-photo{
        width: 25rem;
        height: 6rem;
        margin-left: 8rem;
    }
  }
