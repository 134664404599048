.toggle-plateform-main-conatainer{
  display: flex;
  flex-direction: column;
  height: auto;
  background-color: whitesmoke;
  padding: 17px;
  border-radius: 5px;
  overflow: hidden;
}

.toggle-plateform-header-grp{
  line-height: 1.5rem;
  background-color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  text-align: right;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}

span.toggle-plateform-header-title {
  font-size: x-large;
  font-weight: bold;
  color: #3e429d;
}

.toggle-plateform-sub-conatainer {
  margin-top: 1rem;
  padding: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  height: auto;
}

.toggle-plateform-grp {
  display: flex;
  gap: 4rem;
}

label.toggle-plateform-left-hand-label {
  font-size: larger;
  font-weight: bold;
}

.toggle-plateform-first-row {
  display: flex;
  gap: 10rem;
  margin-bottom: 3rem;
}

label.plateform-zamoto-title {
  font-size: x-large;
  font-weight: bold;
}

label.plateform-swiggy-title {
  font-size: x-large;
  font-weight: bold;
}

input.plateform-checkbox {
  height: 2rem;
  width: 1.5rem;
}

span.plateform-checkbox-together {
  display: flex;
  gap: 1rem;
}

select.form-control.toggle-plateform-select {
  width: 18rem;
}

.toggle-plateform-button-footer {
  margin-top: 1rem;
  background-color: white;
  line-height: 3rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
}

.toggle-plateform-button-1{
  padding: 5px 6px;
  border-radius: 1rem; 
  background-color: #002989;
  color: white;
  border: 1px solid #002989;
}

.toggle-plateform-button-2{
  padding: 5px 6px;
  border-radius: 1rem; 
  background-color: #002989;
  color: white;
  border: 1px solid #002989;
}

.toggle-plateform-button-3{
  padding: 5px 6px;
  border-radius: 1rem; 
  background-color: #002989;
  color: white;
  border: 1px solid #002989;
}

.toggle-plateform-button-4{
  padding: 5px 6px;
  border-radius: 1rem; 
  background-color: #002989;
  color: white;
  border: 1px solid #002989;
}

.toggle-plateform-button-5{
  padding: 5px 6px;
  border-radius: 1rem; 
  background-color: #002989;
  color: white;
  border: 1px solid #002989;
}
