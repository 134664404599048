.quantity-container-kot-1 {
  align-items: center;
  font-size: 24px;
  font-weight: 'bold'
}

.quantity-control-kot {
  display: flex;
  align-items: center;
  border-radius: 4px;
}

/* .quantity-button-kot-1 {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 4px;
  font-weight: bold
} */

.quantity-number-kot {
  margin: 0 8px;
  font-size: 16px;
}

.sticky-footer-kot {
  position: sticky;
  bottom: 0;
  inset-block-end: 0;
}

/* .billing-table-container-kot {
  max-height: 500px;
   overflow-y: auto
} */

.sticky-header {
  position: sticky;
  top: 0;
}

.column-width-kot-1 {
  width: 50px;
}

.column-width-kot-2 {
  width: 100px;
}

.column-width-kot-3 {
  width: 200px;
}

.column-width-kot-4 {
  width: 150px;
}

.column-width-kot-5 {
  width: 100px;
}

.column-width-kot-6 {
  width: 50px;
}

.kot-buttons {
  margin-left: 5px;
}

.table-container-kot {
  max-height: 500px;
  overflow-y: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  height: 370px;
}

.kot-item-quantity {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  text-align: center;
  background: #002989;
  color: white;
  /* margin-bottom: 2rem; */
  margin-top: -1rem;
  padding: 7px;
}

button.quantity-button-kot-1 {
  background-color: white;
  color: black;
  border: none;
}

.kot-table-popup-show {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

span.item-modifier-option-button {
  margin-left: 5px;
  color: red;
  margin-right: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  font-size: small;
  float: right;
  margin-top: 2px;
  /* position: relative; */
}

span.item-modifier-each-button {
  border: 1px solid #002989;
  border-radius: 8px;
  color: #002989;
  background-color: white;
  width: 11rem;
}

span.item-modifier-selected-button-form {
  display: grid;
  gap: 2px;
  width: auto;
}

.selected-modifier {
  background-color: #002989;
}
