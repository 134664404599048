.delivery-invoice-container {
  width: 100vw; 
  height: calc(100vw * 1.414); 
  max-width: 794px; 
  /* max-height: 1123px; */
  margin: 0 auto;
  padding: 2rem;
  background-color: white;
  box-sizing: border-box;
  overflow: hidden; 
}

.delivery-invoice-header, .invoice-footer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.delivery-company-info, .delivery-invoice-info {
  color: #333;
  margin-bottom: 0.5rem;
  display: flex;
  flex-flow: column;
}

span.delivery-company-name {
  font-size: x-large;
  font-weight: bold;
}

.delivery-invoice-bill-div {
  display: flex;
  flex-flow: column;
}

.delivery-invoice-billing-address {
  display: flex;
  justify-content: space-between;
}

.delivery-invoice-table {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
  border-bottom: 1px solid;
}

.delivery-invoice-table th,
.delivery-invoice-table td {
  border: 1px solid black;
  padding: 0.4rem;
  text-align: left;
  font-size: xx-small;
  font-weight: bold;
}

.delivery-invoice-table th {
  background-color: #f2f2f2;
  font-weight: bold;
  font-size: x-small;
}

span.delivery-extra-row-data {
  display: flex;
  justify-content: flex-end;
}

.delivery-invoice-footer-section {
  display: flex;
  justify-content: flex-end; 
  padding: 1rem;
}

.delivery-invoice-button-group {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

.delivery-invoice-horizontal-line {
  width: 100%;           
  height: 1px;           
  background-color: black; 
  margin: 1rem 0;        
}

.delivery-invoice-signature-section {
  display: flex;
  flex-direction: column; 
  align-items: flex-end;
  padding: 1rem; 
  gap: 5rem;
  margin-top: 1rem;
  border: 1px solid black;
}

.delivery-invoice-footer-data {
  display: flex;
  flex-flow: column;
  float: inline-end;
  width: 40%;
}

.delivery-invoice-footer-data-row {
  display: flex;
  justify-content: space-between; 
  padding: 0rem 0;
}

.delivery-invoice-footer-label {
  flex: 1;
  text-align: left;
  font-weight: bold;
  font-size: x-small; 
}

.delivery-invoice-footer-value {
  flex: 1;
  text-align: right;
  font-size: x-small;
  font-weight: bold;
}

img.delivery-company-logo {
  height: 10%;
  width: 15%;
  margin-top: 0.5rem;
}

.tax-details-invoice {
  font-size: 0.4rem;
  font-weight: bold;
  margin-bottom: 0.2rem;
}

tr.delivery-tab-head-row {
  line-height: 0.4rem;
}

tr.delivery-table-body-row {
  line-height: 0.4rem;
}

.delivery-challan-invoice-first-row {
  display: flex;
  justify-content: space-between;
}

.delivery-challan-invoice-bill-first-row {
  display: flex;
  justify-content: space-between;
}

.delivery-challan-invoice-bill-third-row {
  display: flex;
  justify-content: center;
}

.delivery-challan-invoice-party-data {
  display: flex;
  flex-flow: column;
}

span.delivery-challan-invoice-label {
  font-size: small;
  font-weight: bold;
}

.delivery-challan-invoice-bill-party-row {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.delivery-challan-invoice-bill-user-row {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
}

span.delivery-invoice-first-title {
  font-size: small;
  font-weight: bold;
}

span.delivery-challan-invoice-label-invoice {
  font-size: small;
  font-weight: bold;
}

table.delivery-invoice-footer-table {
  border: 1px solid black;
  margin-top: -1rem;
}

button.delivery-invoice-button-icon {
  width: 2rem;
  font-size: larger;
  color: #474ddc;
  background-color: white;
  border: 2px solid;
}

/* extra header css */
.delivery-invoice-extra-pad-com-info-container {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.delivery-invoice-extra-img-grp {
  width: 18%;
  border: 2px solid black;
  border-radius: 0.5rem;
  height: 8rem;
  padding: 0.5rem;
}

img.delivery-invoice-extra-company-logo {
  height: 5rem;
  width: 100%;
  margin-top: 0.5rem;
}

.delivery-invoice-extra-com-info-grp {
  width: 82%;
  border: 2px solid black;
  border-radius: 0.5rem;
  padding: 1rem;
  height: 8rem;
}

.delivery-invoice-extra-com-grp {
  display: flex;
  justify-content: center;
  word-wrap: break-word;
}

span.delivery-invoice-extra-com-name-label {
  font-size: x-large;
  font-weight: bold;
}

.delivery-invoice-extra-branch-grp {
  display: flex;
  justify-content: center;
  word-wrap: break-word;
}

span.delivery-invoice-extra-branch-label {
  font-size: medium;
  font-weight: bold;
}

.delivery-invoice-extra-header-com-data-grp {
  display: flex;
  justify-content: space-between;
}

span.delivery-invoice-extra-com-data-label {
  font-size: small;
  font-weight: bold;
}

.item-note-invoie {
  font-size: xx-small;
  font-weight: lighter;
  margin-top: 0.5rem;
}

@media print {
  .delivery-invoice-container {
    width: 100%;
    height: auto;
    page-break-inside: avoid;
  }

  .delivery-invoice-header,
  /* .delivery-invoice-footer, */
  .delivery-invoice-table {
    page-break-after: auto;
  }

  .delivery-invoice-button-group {
    display: none !important;
  }

  .delivery-invoice-table thead {
    display: table-header-group;
  }

  /* .delivery-invoice-footer tfoot {
    display: table-footer-group;
  } */

  .delivery-invoice-table {
    width: 100%;
    border-collapse: collapse;
  }

  .delivery-invoice-table th,
  .delivery-invoice-table td {
    padding: 8px;
    border: 1px solid #000;
    text-align: left;
  }

  .delivery-invoice-signature-section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 1rem;
    gap: 5rem;
    margin-top: 1rem;
    border: 1px solid black;
    position: fixed;
    bottom: 0.5rem;
    width: 92%; 
  }

  table.delivery-invoice-footer-table {
    padding: 1rem;
    gap: 5rem;
    margin-top: 1rem;
    border: 1px solid black;
    position: fixed;
    bottom: 11rem;
    width: 92%; 
  }
}

.company-header {
  padding: 10px 0;
  margin-bottom: 20px;
}

.company-name-box {
  display: inline-block;
  border: 2px solid #333;
  border-radius: 8px;
  padding: 8px 17px;
  background-color: #fff;
  height: auto;
  width: 16rem;
}

.company-name-box h1 {
  margin: 0;
  line-height: 1.2;
}

.company-name-box div {
  margin-top: 4px;
}
