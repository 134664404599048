.show-staff-main-container {
  background-color: #fff;
  padding: 17px;
  border-radius: 15px;
  max-width: 100%;
  height: auto;
}

.show-staff-header-container {
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  line-height: 4rem;
}

.staff-show-details-title {
  color: #3e429d;
  margin-left: 1rem;
  font-weight: bold;
  font-size: x-large;
}

.show-staff-second-container {
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
  height: 40rem;
  margin-top: 1rem;
  overflow-y: auto;
}

.staff-detail-row {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.show-staff-detail-label {
  width: 200px;
  font-weight: bold;
  flex-shrink: 0;
  margin-left: 2rem;
}

.staff-detail-value {
  flex-grow: 1;
  margin-left: 300px;
}

.staff-id-proof {
  max-width: 300px;
  cursor: pointer;
}

.staff-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.staff-show-modal-content {
  background: white;
  padding: 1rem;
  border-radius: 5px;
  position: relative;
}

.staff-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 1.5rem;
}

.staff-modal-image {
  max-width: 100%;
  max-height: 80vh;
}
