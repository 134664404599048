/* Container for roles and permissions */
.role-permissions-container {
    margin: 20px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 1rem;
  }
  
  /* Title */
  .title-content {
    color: #010691;
    font-size: 24px;
    font-weight: 600;
    margin-left: 1rem;
    margin-bottom: 20px;
  }
  
  /* Button container */
  .button-content {
    text-align: right;
    margin-top: 20px;
  }
  
  /* Update button */
  .update-button {
    padding: 10px 20px;
    background-color: #474DDC;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* Select box */
  .select-designation select {
    /* width: 100%; */
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  /* Checkbox container */
  .checkbox-permissions {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
  }
  
  /* Checkbox label */
  .checkbox-permissions label {
    display: block;
    margin-bottom: 5px;
  }
  
  /* Checkbox input */
  .checkbox-permissions input[type="checkbox"] {
    margin-right: 5px;
  }
  