.bill-history-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.bill-history-modal {
  background-color: white;
  border-radius: 8px;
  width: 90%;
  max-width: 900px;
  max-height: 80vh;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.bill-history-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid #eee;
}

.bill-history-header h2 {
  margin: 0;
  font-size: 1.25rem;
  color: #333;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  padding: 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.close-button:hover {
  background-color: #f5f5f5;
  color: #333;
}

.bill-history-body {
  padding: 24px;
  overflow-y: auto;
  max-height: calc(80vh - 70px);
}

.no-history-message {
  text-align: center;
  padding: 24px;
  color: #666;
}

span.bill-history-header-left {
  font-size: larger;
  font-weight: bold;
}

button.bill-history-header-right {
  background-color: #002989;
  color: white;
  border: none;
  font-size: larger;
  font-weight: bold;
  width: 5%;
}
