.void-kot-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, sans-serif;
  width: 100%;
}

.void-kot-box-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.void-kot-box {
  display: flex;
  flex-direction: column;
  width: 48%;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 56rem;
}

.order-list table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.order-list th,
.order-list td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 100%;
  border-top: 1px solid #ccc;
}

.total {
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

.footer-buttons {
  display: flex;
  gap: 10px;
}

.order-item-total-title {
  height: 7%;
  background-color: whitesmoke;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  margin-bottom: .5rem;
  margin-top: 1rem;
}

.oreder-item-span-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.order-item-save-list-group {
  display: flex;
  gap: 1px;
}

.header-first-kot-header, .header-second-kot-header, .header-third-kot-header {
  display: flex;
  padding: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  line-height: 2rem;
}

.header-first-kot-header span {
  padding: 2px 85px 8px 0px;
}

.header-second-kot-header {
  margin-top: 10px;
}

.header-second-kot-header span {
  padding: 2px 85px 8px 0px;
}

.header-third-kot-header {
  margin-top: 10px;
  gap: 10px;
  margin-bottom: 10px;
}

.kot-select-icon {
  margin-top: -32px;
  float: right;
  margin-right: 10px;
}

.total-item-sub-total-order-void-res{
  display: flex;
  justify-content: space-between;
}

.order-item-span-group {
  margin-top: 1%;
  font-weight: bold;
  font-size: x-large;
}

.order-item-save-list-group {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

button.order-table-list-button {
  line-height: 3rem;
  width: 50%;
  border-radius: 1rem;
  background-color: #002989;
  color: white;
  border: 1px solid #002989;
}

button.order-item-save-and-print {
  line-height: 3rem;
  width: 50%;
  border-radius: 1rem;
  background-color: #002989;
  color: white;
  border: 1px solid #002989;
}

.order-list {
  overflow-y: auto;
  max-height: 750px;
  height: 500px;
}

.void-item-confirm-button-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.void-item-confirm-selection-button {
  line-height: 3rem;
  border-radius: 0.5rem;
  background-color: #002989;
  color: white;
  border: 1px solid #002989;
  padding: 4px 4px;
  flex: 2;
}
