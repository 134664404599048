.third-template-3-main {
    background-color: #fff;
    padding: 20px;
    width: 100%;
}

.third-template-3-header {
    text-align: center;
    margin-bottom: 20px;
}

.third-template-3-sub {
    text-align: center;
    margin-bottom: 20px;
    font-size: small;
}

.third-template-3-logo {
    height: 5rem;
    width: 9rem;
}

.third-template-3-date-time {
    display: flex;
    justify-content: space-between;
}

.third-template-3-date,
.third-template-3 {
    margin-bottom: 10px;
}


.third-template-3-policy {
    display: flex;
    text-decoration: underline;
}

.template-3-third-policy-content,
.template-3-third-offer-content {
    display: flex;
    margin-bottom: 1rem;
}

.third-template-3-offer {
    display: flex;
    text-decoration: underline;
}

.template-3-third-content {
    text-align: center;
    background: #38475a;
    color: white;
    margin-bottom: 2rem;
    margin-top: 3rem;
}

.template-3-third-table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 2rem;
}

.template-3-third-table th,
.template-3-third-table td {
    text-align: left;
    padding: 8px;
}

.third-template-3-table-heading th {
    background: #38475a;
    color: white;
    position: sticky;
    top: 0;
}

.third-template-3-table-heading th:first-child {
    left: 0;
    z-index: 2;
}

.template-3-third-table th:first-child,
.template-3-third-table td:first-child {
    padding-left: 16px;
}

.template-3-third-table th:last-child,
.template-3-third-table td:last-child {
    padding-right: 16px;
}

.third-template-3-company{
    font-size: larger;
    font-weight: bold;
}

@media print {
    @page {
      size: A4;
      margin: 10mm;
    }
    .template-three-main {
        font-size: 10px;
        line-height: 1.2;
        max-width: 210mm;
        margin: 0 auto; /* Add margin: 0 auto to center the template horizontally */
        padding: 10px;
        box-sizing: border-box; /* Add box-sizing to include padding in the width calculation */
      }
      .template-three-logo {
        height: 40px;
        width: auto;
        margin: 0 auto; /* Add margin: 0 auto to center the logo horizontally */
      }
    
      .template-three-date-time {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
      }
  }
  
  .template-three-main {
    font-size: 10px;
    line-height: 1.2;
    max-width: 210mm;
    margin: 0 auto;
    padding: 10px;
  }
  
  .template-three-header {
    text-align: center;
    margin-bottom: 10px;
  }
  
  .template-three-logo {
    height: 40px;
    width: auto;
  }
  
  .template-three-date-time {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  
  .template-three-hr {
    border: none;
    border-top: 1px solid #ccc;
    margin: 5px 0;
  }
  
  .template-three-company {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .template-three-details {
    font-size: 9px;
    margin-bottom: 5px;
  }
  
  .template-three-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 10px;
  }
  
  .template-three-table th,
  .template-three-table td {
    border: 1px solid #ddd;
    padding: 3px;
    text-align: left;
  }
  
  .template-three-table th {
    background-color: #38475a;
    color: white;
  }
  
  .tax-font-size {
    font-size: 8px;
  }
  
  .template-three-summary {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 10px;
  }
  
  .template-three-summary td {
    padding: 2px;
  }
  
  .template-three-summary td:first-child {
    text-align: right;
    font-weight: bold;
  }
  
  .template-three-footer {
    margin-top: 10px;
    font-size: 9px;
  }
  
  .template-three-policy,
  .template-three-offer-title {
    font-weight: bold;
    text-decoration: underline;
    margin-bottom: 3px;
  }
  
  .template-three-signature,
  .template-three-offer-content {
    margin-bottom: 5px;
  }
  
  .template-three-contact {
    text-align: center;
    background: #38475a;
    color: white;
    padding: 5px;
    margin-top: 10px;
    font-size: 9px;
  }
  
  .template-three-message {
    text-align: center;
    margin-top: 5px;
    font-weight: bold;
  }
  
  @media screen {
    .template-three-main {
      border: 1px solid #ddd;
      padding: 20px;
      margin: 20px auto;
    }
  }