.purchase-common-table-group {
  overflow-x: auto;
  border-bottom: 1px solid #ccc;
  overflow-y: auto;
  max-height: 500px;
}

.purchase-common-table {
  width: 100%;
  border-collapse: collapse;
}

.purchase-common-tbody-row th {
  padding: 0.4rem;
  border-bottom: 1px solid #ccc;
  text-align: left;
  border: 1px solid #ccc;
  font-size: small;
  font-weight: bold;
}

.purchase-common-table-body td {
  padding: 0.2rem;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  text-align: left;
  font-size: small;
}

.purchase-common-td {
  font-size: small;
}

input.form-control.purchase-common-table-item-input {
  width: 15rem;
  padding: 0.3rem;
  font-size: small;
  border: 1px solid #ccc;
  box-sizing: border-box;
  height: 2.5rem;
}

input.form-control.purchase-common-table-input {
  padding: 0.3rem;
  font-size: small;
  border: 1px solid #ccc;
  box-sizing: border-box;
  height: 2.5rem;
}

.common-table-settings-modal {
  position: fixed;
  top: 10%;
  right: 1%;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  z-index: 1000;
}

.common-table-modal-content h4 {
  margin-bottom: 0.5rem;
}

.common-table-modal-content label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: x-small;
}

button.common-table-setting-box-btn{
  margin-right: 1rem;
  padding: 0.4rem 1rem;
}

input.common-table-checkbox-option {
  width: 10%;
  margin-right: 0.5rem;
}

button.common-table-setting-box-btn {
  margin-right: 1rem;
  padding: 0.2rem 0.2rem;
  border: none;
  border-radius: 0.2rem;
  background-color: #002989;
  color: white;
  font-size: small;
}

.purchase-common-table-footer-container {
  border: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
}

.sale-common-table-footer-left {
  margin-top: 1rem;
}

span.sale-common-table-add-item {
  font-size: medium;
  font-weight: bold;
  color: #002989;
  margin-left: 1rem;
}

.sale-common-table-add-item:hover{
  cursor: pointer;
}

.purchase-common-table-footer-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.purchase-common-footer-div {
  display: flex;
  justify-content: space-between;
  width: 100%; 
}

.purchase-common-footer-label {
  font-size: medium;
  width: 60%;
  text-align: left;
}

.sales-common-footer-input,
.sales-common-footer-value {
  font-size: medium;
  width: 40%;
  text-align: right;
}

.form-control {
  width: 100%;
  padding: 0.5rem;
  font-size: medium;
}

.sale-common-footer-reverse-div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

span.sales-common-footer-reverse-label {
  font-size: medium;
  width: 60%;
  text-align: left;
}

span.sales-common-footer-reverse-input {
  width: 100%;
  margin-left: 6rem;
}

input.form-control.sale-common-table-footer-input {
  width: 100%;
}

input.form-control.sale-common-table-footer-input-2 {
  width: 40%;
}

svg.svg-inline--fa.fa-trash.common-table-delete-icon {
  color: red;
}

ul.common-item-dropdown {
  position: absolute;
  z-index: 1000;
  background-color: white;
  width: 15rem;
  border: 1px solid #ccc;
  overflow-y: auto;
  max-height: 150px;
  padding-left: 0px;
  list-style-type: none;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

li.common-dropdown-item {
  padding: 0.2rem;
}

li.common-dropdown-item:hover {
  background-color: #ccc;
  cursor: pointer;
}

span.sale-comon-td-delete-icon {
  color: red;
}

input.form-control.sale-common-table-input {
  width: 40%;
}

.select_pq {
  width: 170px;
  margin-right: 10px;
}

.pq_table_calculation {
  width: 50%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.pq_table_calculation_row {
  display: contents;
}

.form-control {
  width: 100%;
  height: 40px;
  font-size: 16px;
}

.tax-container {
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.tax-label {
  font-size: 16px;
  margin-right: 126px;
}

.tax-select {
  width: 266px;
  margin-right: 12px;
  height: 35px;
  font-size: 14px;
  border: 1px solid #bebebe;
  border-radius: 6px;
  transition: border-color 0.2s ease-in-out;
}

