.item-status-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 15px;
  padding: 10px;
  width: 250px;
  margin-bottom: 487px;
  margin-left: 97rem;
  position: fixed;
  top: 57px;
}

.react-trello-card-sub {
  background-color: white;
}

.item-status-header {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
}

.item-status-tabs {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}

.item-status-tab {
  flex: 1;
  padding: 5px;
  text-align: center;
  cursor: pointer;
}

.item-status-tab.active {
  border-bottom: 2px solid red;
  font-weight: bold;
}

.item-status-content {
  margin-top: 10px;
}

.item-status-row {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
}
.react-trello {
  display: flex;
  flex-direction: row;
  margin: 0;
  width: 114em;
}
