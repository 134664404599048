.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 1rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.search-input-container {
  position: relative;
}

.search-input-container input {
  width: 500px;
  padding: 10px;
  border: 0px solid #d8d6d6;
  border-radius: 50px;
  outline: none;
  background-color: #f1f1f1;
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #1a1fad;
}

.button-contents {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.header-link {
  margin-right: 10px;
  padding: 10px;
  background: conic-gradient(from 0turn at 50% 30%, rgb(3 8 113 / 88%), 243deg, #7b7dc3, 354deg, #474cc1);
  border-radius: 50px;
  color: #fdfdfd;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}

.active .header-link {
  background: #d8d5d570 !important;
  color: #474cc1;
  text-decoration: none;
  font-weight: bold;
}

.dropdown-box {
  position: relative;
}

.dropdown-menus {
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dropdown-menus ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown-menus ul li {
  margin-bottom: 5px;
}

.dropdown-menus ul li a {
  text-decoration: none;
  color: #333;
}

.dropdown-menus ul li a:hover {
  color: #007bff;
}

.form-control.selected-branch-name {
  width: 100%;
}

.branch-header-select-icon {
  position: absolute;
  top: 5.5%;
  transform: translateY(-50%);
  pointer-events: none;
  color: grey;
  left: 13%;
}

.header-back-button-margine {
  margin-right: 10px;
}

.fullscreen-button {
  background-color: transparent; 
  border: none; 
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullscreen-button .icon {
  font-size: 24px; 
  color: rgb(3 8 113 / 88%);
  transition: color 0.3s ease;
}

.fullscreen-button:hover .icon {
  color: rgb(3 8 113 / 88%);
}


