.carousel-arrow-left,
.carousel-arrow-right {
  border: solid #333;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 6px;
}

.carousel-arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.carousel-arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  background-image: none; 
}

.carousel-indicators li {
    background-color: #333; 
    border-color: #333; 
  }
  
.carousel-indicators .active {
    background-color:  #474DDC; 
}
