.new-quotation-main-container {
  height: 95vh;
  width: 100%;
  background-color: white;
  border-radius: 0.4rem;
  overflow-y: auto;
}

.new-quotation-sub-container {
  padding: 1rem;
}

.new-quote-first-div {
  display: flex;
  gap: 3rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.new-quote-estimate-no-grp {
  display: flex;
  flex-flow: column;
  width: 20%;
  gap: 0.5rem;
}

.new-quote-estimate-date-grp {
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
}

span.new-quote-estdate-input {
  display: flex;
  justify-content: center;
}

.new-quote-tilldate-grp {
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
}

span.new-quote-est-tilldate-input {
  display: flex;
  justify-content: center;
}

.new-quote-reference-grp {
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
}

span.new-quote-est-reference-input {
  display: flex;
  justify-content: center;
}

.new-quote-second-div {
  margin-top: 1rem;
  display: flex;
  gap: 2rem;
}

.new-quote-customer-div {
  display: flex;
  flex-flow: column;
  margin-left: 1rem;
}

input.form-control.new-quote-cust-field {
  width: 30%;
}

.quotation-invoice-table-group {
  margin-top: 1rem;
}

.new-quote-third-div {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

textarea.new-quote-term-textarea-input,
.new-quote-note-textarea-input {
  border-radius: 0.5rem;
  border: 1px solid #ccc;
  width: 100%;
  height: 100%;
}

textarea.new-quote-term-textarea-input:focus,
.new-quote-note-textarea-input:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
}

span.new-quote-est-no-title,
.new-quote-estdate-title,
.new-quote-est-tilldate-title,
.new-quote-est-reference-title,
.new-quote-cust-title,
.new-quote-buyer-order-title,
.new-quote-buyer-order-date-title {
  font-size: small;
}

span.new-quote-est-no {
  font-size: large;
  font-weight: bold;
  display: flex;
  justify-content: left;
}

input.form-control.new-quote-first-div-input {
  height: 2rem;
}

input.form-control.new-quote-cust-field {
  height: 2rem;
  width: 100%;
}

input.form-control.new-quote-buyer-order,
.new-quote-buyer-order-date {
  height: 2rem;
  width: 60%;
}

.new-quote-note-grp {
  display: flex;
  flex-flow: column;
  margin-left: 1rem;
}

.new-quote-terms-condition-grp {
  display: flex;
  flex-flow: column;
}

.new-quote-terms-condition-grp {
  display: flex;
  flex-flow: column;
}

input.form-control.new-quote-last-input {
  height: 2rem;
  width: 100%;
}

.new-quote-table {
  margin-top: 2rem;
  margin-bottom: 4rem;
}

input.form-control.new-quote-buyer-order-date {
  width: 100%;
}

.new-quote-customer-dropdown {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  background-color: white;
  position: absolute;
  width: 100%;
  z-index: 1000;
  top: 100%;
  left: 0;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.new-quote-dropdown-item {
  padding: 0.2rem 0.5rem;
  cursor: pointer;
}

.new-quote-dropdown-item:hover {
  background-color: #f1f1f1;
}

.new-quote-cust-search {
  position: relative;
}

.new-quote-dropdown-item:hover {
  background-color: #f1f1f1;
}

.new-quote-cust-search {
  position: relative;
}

.new-quote-buyer-group {
  display: flex;
  margin-left: 1rem;
  margin-top: 1rem;
}

.new-quote-customer-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  z-index: 1000;
}

/*  */
.new-quote-second-billing,
.new-quote-second-shipping {
  display: flex;
  flex-flow: column;
  word-wrap: break-word;
  width: 25%;
}

span.new-quote-bill-title,
.new-quote-shipping-title {
  font-weight: bold;
}

.new-quote-toggle-button-grp {
  padding-left: 2rem;
}
