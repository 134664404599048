.input-select {
    width: 100%;
    padding: 6px 12px;
    font-size: 14px;
    border: 1px solid grey;
    border-radius: 4px;
    outline-color: skyblue;
  }
  
  .save-btn {
    margin-top: 10px;
    padding: 8px 16px;
    font-size: 14px;
    background-color: #002989;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .total-section {
    font-size: 16px;
    text-align: right;
    border: 1px solid grey;
    position: relative;
  }
  
  .sub-btn {
    margin-top: 10px;
    font-weight: bold;
    font-size: 16px;
    text-align: right;
    background-color: #474ddc;
  }
  
  @media (max-width: 600px) {
    .customer-section {
      flex-direction: column;
    }
  }
  
  .table-td {
    padding: 6px;
    font-size: 18px;
    border: 1px solid black !important;
    width: 250px;
  }
  
  .table_td_border {
    padding: 6px;
    font-size: 18px;
    border: 1px solid black !important;
    width: 250px;
  }
  
  .supplier-phone {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    width: 18%;
  }
  
  .supplier-form {
    display: flex;
  }
  
  .table-th {
    background-color: whitesmoke !important;
    color: black !important;
    font-weight: normal;
    border: 1px solid;
    padding: 19px;
    z-index: auto;
  }
  
  .table-row {
    border: 1px solid;
    padding: 2px;
  }
  
  .amount {
    display: flex;
    align-items: center;
  }
  
  .add-btn {
    color: #002989;
    display: flex;
    padding: 3px 1px 4px 7px;
    cursor: pointer;
    width: 7%;
  }
  
  .cal-total-sale {
    position: relative;
    top: -1rem;
    margin-right: 2rem;
  }
  
  .input-wrap {
    display: flex;
    align-items: center;
    padding: 6px;
    gap: 19px;
  }
  
  .table-tr {
    border-width: 0;
  }
  
  .sale_notice {
    font-weight: 600;
    color: #002989;
    font-size: 30px;
    margin-bottom: 1rem;
  }
  
  .item-suggestions {
    position: absolute;
    z-index: 1000;
    background-color: #fff;
    /* border: 1px solid #ccc; */
    padding: 0;
    margin: 0;
    list-style-type: none;
    max-height: 200px;
    overflow-y: auto;
    width: 239px;
  }
  
  .item-suggestions li {
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .item-suggestions li:hover {
    background-color: #f5f5f5;
  }
  
  .css-1u9des2-indicatorSeparator {
    display: none;
  }
  
  .css-1hb7zxy-IndicatorsContainer {
    color: grey;
  }
  
  .trash-icon {
    color: red !important;
    width: 42%;
  }
  
  .amount_trash {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .input-wrap input {
    border: 2px solid #ccc;
    outline-color: skyblue;
    margin: 5px;
    margin-left: -7px;
  }
  
  .phone_input {
    border: 2px solid #ccc !important;
  }
  
  .sno {
    width: 80px;
  }
  
  .inputwrapper {
    position: relative;
    margin-top: 0px;
  }
  
  .inputwrapper::after {
    content: attr(data-required);
    position: absolute;
    right: 8px;
    top: 50%;
    font-size: 15px;
    transform: translateY(-50%);
    color: #ccc;
  }
  
  .error {
    border: 2px solid red !important;
    border-radius: 8px;
  }
  
  .form-control {
    margin-top: 2px !important;
    height: 3rem;
  }
  .unit-select-wrapper {
    position: relative;
  }
  
  .unit-select-wrapper select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 25px;
    background: transparent;
  }
  
  .unit-select-wrapper::after {
    content: '▼';
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: #666;
  }