.qty-edit-container {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 40%;
	height: 70%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.edit-qty-modal-content {
    background-color: #fff;
    padding: 3rem;
    border-radius: 8px;
    position: fixed;
    width: auto;
    height: auto;
}

.qty-edit-form {
	margin-top: 1rem;
	/* margin-left: 0px; */
}

.edit-item-qty-form-group {
	display: flex;
	align-items: center;
	margin-bottom: 2.8vh;
	font-size: large;
	align-items: center;
	gap: 1rem;
}

.flexbox {
	display: flex;
	justify-content: center;
}

.update-btn {
	background-color: #474DDC;
	color: #fff;
	padding: 10px 20px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	margin-top: 1.5vh;
	display: flex;
	justify-content: center;
}

label.edit-item-qty-form-group {
	margin-right: 2rem;
}

input.edit-item-qty-form-group {
   width: 96%;
}

.qty-input-select {
	border: 1px solid black;
	padding-left: 10px;
}

.close-btn {
    position: absolute;
    top: 22px;
    left: 44%;
    background: white;
    color: black;
    cursor: pointer;
    width: 100%;
    height: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-size: x-large;
}

label.edit-item-form-label {
    width: 80%;
}
