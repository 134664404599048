.item-ut-main-container {
  display: flex;
  flex-direction: column;
  height: 84vh;
  background-color: #fff;
  padding: 16px;
  border-radius: 15px;
  overflow: hidden;
}

.item-ut-button-grp {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
}

.item-ut-btn {
  margin-bottom: 10px;
  border-radius: 1rem;
  margin-right: 10px;
  border: 2px solid #002989;
  color: #002989;
  background-color: white;
  padding: 1.5rem 1.6rem;
  font-size: large;
  font-weight: bold;
}

.item-ut-btn.active {
  background-color: #cddcf8;
  color: #002989;
  font-weight: bold;
}

.item-ut-content {
  margin-top: 20px;
}

select.form-select.custom-item-ut-select {
  width: 15%;
}

/* void item css */
.item-ut-void-first {
  display: flex;
  justify-content: space-between;
}
/* way off item css */
