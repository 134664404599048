.input-group-text-height {
    height: 39px;
  }
  
  .button-margin-zero {
    margin: 0;
  }
  
  .close-button-margin {
    margin-left: 10px;
  }
  
  .close-button-bg {
    background-color: #002989;
  }
  