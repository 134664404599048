.transaction-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f5f3f3;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.transaction-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
}

.label1 {
  font-size: 16px;
  font-weight: 800;
  margin-top: 1rem;;
  margin-left: 1rem;
}

.transaction-list input[type="text"] {
  margin-left: 1rem;
  border-radius: 5px;
  height: 30px;
  border: 1px solid #ddd;
  padding: 0.5rem;
}

.search-container {
  position: relative;
  display: inline-block;
}

.search-icon1 {
  position: absolute;
  margin-left: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
}

.transaction-list-header div,
.transaction-item div {
  flex: 1;
  text-align: center;
}

.transaction-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #ddd;
  max-height: 600px;
  overflow-y: auto;
}

.no-transactions {
  text-align: center;
  padding: 1rem;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.button1 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button1:hover {
  background-color: #0056b3;
}

.plus-icon {
  margin-right: 5px;
}

.transaction-list-header {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  background-color: #f0f0f0;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.transaction-item {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding: 10px;
}

.no-transactions {
  margin-top: 20px;
  text-align: center;
  color: #888;
}

.button-container1 {
  display: flex;
  justify-content: center !important;
}

.delete-button,
.edit-button {
  background-color: transparent;
  border: none;
  margin-right: 10px;
  cursor: pointer;
  color: #888;
}

.delete-button:hover,
.edit-button:hover {
  color: red;
}