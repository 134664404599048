.new-sale-receipt-invoice-main-container {
  height: 95vh;
  width: 100%;
  background-color: white;
  border-radius: 0.4rem;
  overflow-y: auto;
}

.new-sale-receipt-invoice-sub-container {
  padding: 1rem;
}

.new-sale-receipt-invoice-first-div {
  display: flex;
  gap: 3rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.new-sale-receipt-invoice-estimate-no-grp {
  display: flex;
  flex-flow: column;
  width: 20%;
  gap: 0.5rem;
}

.new-sale-receipt-invoice-estimate-date-grp {
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
}

span.new-sale-receipt-invoice-estdate-input{
  display: flex;
  justify-content: center;
}

span.new-sale-receipt-invoice-est-reference-input{
  display: flex;
  justify-content: center;
}

.new-sale-receipt-invoice-second-div {
  margin-top: 1rem;
  display: flex;
  gap: 2rem;
}

.new-sale-receipt-invoice-customer-div {
  display: flex;
  flex-flow: column;
  margin-left: 1rem;
}

input.form-control.new-sale-receipt-invoice-cust-field {
  width: 30%;
}

.new-invoice-receipt-table-group {
  margin-top: 1rem;
}

.new-sale-receipt-invoice-third-div {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

textarea.new-sale-receipt-invoice-term-textarea-input,
.new-sale-receipt-invoice-note-textarea-input{
  border-radius: 0.5rem;
  border: 1px solid #ccc;
  width: 100%;
  height: 100%;
}

textarea.new-sale-receipt-invoice-term-textarea-input:focus,
.new-sale-receipt-invoice-note-textarea-input:focus{
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
}

span.new-sale-receipt-invoice-est-no-title,
.new-sale-receipt-invoice-estdate-title,
.new-sale-receipt-invoice-cust-title, 
.new-sale-receipt-invoice-buyer-order-date-title {
  font-size: small;
}

span.new-sale-receipt-invoice-est-no {
  font-size: large;
  font-weight: bold;
  display: flex;
  justify-content: left;
  margin-left: 1rem;
}

input.form-control.new-sale-receipt-invoice-first-div-input {
  height: 2rem;
}

input.form-control.new-sale-receipt-invoice-cust-field {
  height: 2rem;
  width: 100%;
}

input.form-control.new-sale-receipt-invoice-buyer-order,
.new-sale-receipt-invoice-buyer-order-date {
  height: 2rem;
  width: 60%;
}

.new-sale-receipt-invoice-note-grp {
  display: flex;
  flex-flow: column;
  margin-left: 1rem;
}

.new-sale-receipt-invoice-terms-condition-grp {
  display: flex;
  flex-flow: column;
}

.new-sale-receipt-invoice-terms-condition-grp {
  display: flex;
  flex-flow: column;
}

input.form-control.new-sale-receipt-invoice-last-input {
  height: 2rem;
  width: 100%;
}

input.form-control.new-sale-receipt-invoice-buyer-order-date {
  width: 100%;
}

.new-sale-receipt-invoice-customer-dropdown {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  background-color: white;
  position: absolute;
  width: 100%;
  z-index: 1000;
  top: 100%;
  left: 0;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.new-sale-receipt-invoice-dropdown-item {
  padding: 0.2rem 0.5rem;
  cursor: pointer;
}

.new-sale-receipt-invoice-dropdown-item:hover {
  background-color: #f1f1f1;
}

.new-sale-receipt-invoice-cust-search {
  position: relative;
}

.new-sale-receipt-invoice-type-grp {
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
}

span.new-sale-receipt-invoice-type-title {
  font-size: small;
}

select.form-control.new-sale-receipt-invoice-first-div-input {
  width: 100%;
  padding-left: 1rem;
  height: 2rem;
  text-align: left;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

select.form-control.new-sale-receipt-invoice-first-div-input option {
  padding: 0.5rem 0.5rem;
  font-size: small;
  text-align: left;
}

.new-sale-receipt-invoice-select-icon {
  margin-left: 9.5rem;
  margin-top: -2rem;
}

/* middle div css */
.sale-receipt-invoice-container {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.sale-receipt-invoice-middle-div {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.receipt-invoice-middle-first-div,
.receipt-invoice-middle-second-div,
.receipt-invoice-middle-third-div {
  margin-right: 1rem;
}

.receipt-invoice-middle-second-div {
  display: flex;
  margin-left: 5rem;
}

.receipt-invoice-add-row-div {
  display: flex;
  align-items: center;
}

.receipt-invoice-add-row-div button {
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
  padding: 5px;
  margin-left: 5px;
}

.receipt-invoice-add-row-div button:hover {
  background-color: #e0e0e0;
}

select.form-control.receipt-invoice-1-input {
  height: 2rem;
  width: 4rem;
  text-align: center;
  padding-bottom: 0rem;
  padding-top: 0px;
}

select.form-control.receipt-invoice-2-input {
  height: 2rem;
  width: 4rem;
  text-align: center;
  padding-bottom: 0rem;
  padding-top: 0px;
}

input.form-control.receipt-invoice-3-input {
  height: 2rem;
  width: 100%;
  text-align: center;
  padding-bottom: 0rem;
  padding-top: 0px;
}

input.form-control.receipt-invoice-4-input {
  height: 2rem;
  width: 100%;
  text-align: center;
  padding-bottom: 0rem;
  padding-top: 0px;
}

label.receipt-invoice-middle-div-label {
  margin-left: 15rem;
}

label.receipt-invoice-middle-refer-div-label {
  margin-left: 1rem;
}

.new-sale-receipt-table-group {
  margin-top: 1rem;
  overflow-y: auto;
  max-height: 350px;
  padding: 1rem;
}

.new-sale-receipt-table {
  width: 100%;
  border-collapse: collapse;
}

.new-sale-receipt-thead-row th {
  padding: 0.4rem;
  border-bottom: 1px solid #ccc;
  text-align: left;
  border: 1px solid #ccc;
  font-size: small;
  font-weight: bold;
}

.new-sale-receipt-table-body td {
  padding: 0.2rem;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  text-align: left;
  font-size: small;
}

.new-sale-receipt-tab-td {
  padding: 0.2rem;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  text-align: left;
  font-size: small;
}

.new-sale-receipt-invoice-input-group {
  display: flex;
  margin-top: 2rem;
  padding-left: 1rem;
  gap: 1.5rem;
  flex-wrap: wrap;
}

.new-sale-receipt-invoice-div {
  display: flex;
  flex-direction: column; 
}

.new-sale-receipt-invoice-label-grp {
  margin-bottom: 0.5rem;
}

input.form-control.new-sale-invoice-payment {
  height: 3rem;
}

.sale-receip-invoice-select-icon {
  position: absolute;
  top: 63%;
  left: 9rem;
  transform: translateY(-50%);
  pointer-events: none;
  color: grey;
}

.new-sale-receipt-second-billing,
.new-sale-receipt-second-shipping {
  display: flex;
  flex-flow: column;
  word-wrap: break-word;
  width: 25%;
}

span.new-sale-receipt-bill-title,
.new-sale-receipt-shipping-title {
  font-weight: bold;
}
