.new-eway-main-container {
  height: 95vh;
  width: 100%;
  background-color: white;
  border-radius: 0.4rem;
  overflow-y: auto;
}

.new-eway-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem;
  background-color: rgba(0, 0, 0, 0.1);
}

.new-eway-header-right-part {
  display: flex;
  gap: 1rem;
}

button.new-eway-right-button {
  background-color: #002989;
  color: white;
  padding: 0.2rem 0.8rem;
  border-radius: 0.2rem;
  border: 1px solid #002989;
}

.new-eway-sub-container {
  padding: 1rem;
  overflow-y: auto;
  max-height: 550px;
}

.new-eway-box-group {
  display: flex;
  justify-content: space-between;
}

.new-eway-first-box, .new-eway-second-box {
  width: 49%;
}

.new-eway-first-part-name, .new-eway-second-part-name {
  font-weight: bold;
  margin-bottom: 1rem;
  display: block;
}

.new-eway-first-part-label-and-input-grp, 
.new-eway-second-part-label-and-input-grp {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

input[type="date"] {
  padding: 0.35rem;
}

.new-eway-first-div-grp,
.new-eway-second-div-grp {
  display: flex;
  gap: 0.5rem;
}

.new-eway-fist-div-grp {
  display: flex;
  gap: 0.5rem;
}

label.new-eway-first-label,
.new-eway-second-label {
  width: 49%;
  font-size: smaller;
}

textarea, input, select.new-eway-first-input,
.new-eway-second-input {
  width: 50%;
}

/* Table group */
.new-eway-bill-table-group {
  border-bottom: 1px solid #ccc;
  margin-top: 3rem;
}

.new-eway-bill-table {
  width: 100%;
  border-collapse: collapse;
}

.new-eway-bill-thead-row th {
  padding: 0.4rem;
  border-bottom: 1px solid #ccc;
  text-align: left;
  border: 1px solid #ccc;
  font-size: small;
  font-weight: bold;
}

.new-eway-bill-table-body td {
  padding: 0.2rem;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  text-align: left;
  font-size: small;
}

.new-eway-tab-td {
  font-size: medium;
}

span.new-eway-table-filter {
  float: inline-end;
  background-color: #002989;
  color: white;
  padding: 0.2rem 0.2rem;
}

/* new eway table filter */
.new-eway-table-filter-container {
  position: absolute;
  top: 13rem;
  right: 1rem;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 10;
  width: 25%;
}

/* attribute filter popup css*/
.quotation-attribute-filter-popup-container {
  position: absolute;
  top: 13rem;
  right: 1rem;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 10;
  width: 25%;
}

label.new-eway-table-first-att-div-label,
.new-eway-table-second-att-div-label,
.new-eway-table-third-part-1-label,
.new-eway-table-third-part-2-label{
  font-size: x-small;
}

.new-eway-table-first-att-div,
.new-eway-table-second-att-div,
.new-eway-table-third-part-1,
.new-eway-table-third-part-2{
  display: flex;
  flex-flow: column;
  margin-bottom: 0.5rem;
}

.new-eway-table-third-att-div {
  display: flex;
  justify-content: space-between;
}

input.new-eway-table-third-att-date {
  padding: 0.2rem;
  font-size: x-small;
  width: 100%;
}

.new-eway-table-attr-btn-grp {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

button.new-eway-table-attr-pop-btn {
  border: none;
  border-radius: 0.2rem;
  font-size: small;
  padding: 0.2rem 0.4rem;
  background-color: #002989;
  color: white;
}
