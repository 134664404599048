* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

body {
  background-color: #F0F2F7 !important;
}

a {
  text-decoration: none !important;
}

.main-container {
  display: flex;
  overflow-y: hidden;
}

main {
  padding: 10px 15px 10px 15px;
  width: 100%;
  /* position: relative; */
}

.mt0 {
  margin-top: 0 !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.widthfull {
  width: 100%;
}

.btn-primary {
  background-color: #002989 !important;
  font-size: 14px;
}

.width-full {
  width: 100%;
}

.header {
  width: 100%;
  --bs-gutter-x: 0 !important;
  margin-bottom: 10px;
  padding: 10px 10px 10px 0;
  border-radius: 8px;
  display: flex;
  background-color: #fff;
}

.header-nav {
  display: flex;
  justify-content: right;
}

.header-nav ul {
  padding: 0;
  display: flex;
  justify-content: right;
  margin-bottom: 0;
  margin-top: 6px;
}

.header-nav ul li {
  margin: 0 10px;
}

.header-nav ul li a {
  text-decoration: none;
  color: #233D5C;
}

.header-nav ul li a:hover {
  color: #002989 !important;
}

.table-container {
  margin-top: 30px;
}

.title {
  font-size: 3rem;
  display: grid;
  place-items: left;
}

.managing-tax .form-check-input[type="checkbox"] {
  margin-top: 9px;
}

.form-check-input[type="checkbox"] {
  border-color: #aeabab;
}

.managing-tax .form-check {
  line-height: 35px;
}

.form-check label {
  color: #000;
}

/* Sidebar */
.btn-container {
  border-top: 1px solid #e3e3e3;
  margin-top: 10px !important;
  display: inline-block;
  padding-bottom: 10px;
}

/* .sidebar-invoice {
  display: none !important;
  this is breaking invoice template settings
} */

.sidebar {
  background: #002989 !important;
  color: white;
  min-height: 100vh;
  z-index: 9;
  box-shadow: rgba(100, 100, 111, 0.8) 0px 7px 29px 0px;
  border-right: 1px solid #2525b9;
}

/* For large screens */
@media (min-height: 800px) {
  .sidebar {
    min-height: 110vh;
    /* 80% of the viewport height */
  }
}

.sidebar .icon {
  margin-top: -3px;
}

.top_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
}

.logo {
  font-size: 18px;
  line-height: 0;
  margin-top: 8px;
  font-size: 23px;
}

.pr0 {
  padding-right: 0 !important;
}

.dashboxes .col-md-4 {
  padding-right: 0;
}

.dashboxes {
  width: 100%;
}

.box {
  background-color: #fff;
  padding: 10px 25px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.boxone svg {
  margin-right: 10px;
}

.boxtwo {
  background: rgb(255, 164, 151);
  background: linear-gradient(0deg, rgba(255, 164, 151, 1) 0%, rgba(255, 215, 155, 1) 100%);
}

.boxthree {
  background: rgb(153, 219, 190);
  background: linear-gradient(0deg, rgba(153, 219, 190, 1) 0%, rgba(247, 242, 179, 1) 100%);
}

.box svg {
  margin-right: 10px;
}

.box h3 {
  color: #fff;
  font-weight: bold;
}

.dashboxes {
  margin-top: 15px;
}

.bars {
  width: 30px;
}

.hide {
  display: none;
}

.search {
  display: flex;
  align-items: center;
  margin: 10px 0;
  height: 30px;
  padding: 10px;
}

.search input {
  border: none;
  margin-left: 10px;
  border-radius: 5px;
  background: rgb(238, 238, 238);

  color: white;
}

.routes {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.link {
  display: flex;
  color: white;
  gap: 10px;
  padding: 7px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.link:hover {
  border-right: 4px solid white;
  background: #ffffff;
  color: #1921bd;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.active {
  border-right: 4px solid white;
  background: #ffffff;
  color: #1921bd;
}

.link_text {
  white-space: nowrap;
  font-size: 15px;
  text-decoration: none;
  outline: none;
}

.menu {
  display: flex;
  color: white;

  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}

.menu_item {
  display: flex;
  gap: 10px;
}

.menu_container {
  display: flex;
  flex-direction: column;
}

.menu_container .link {
  padding-left: 20px;
  border-bottom: #391ab6 0.5px solid;
  text-decoration: none;
}

.boxone {
  background: rgb(129, 147, 237);
  background: linear-gradient(0deg, rgba(129, 147, 237, 1) 0%, rgba(250, 213, 238, 1) 100%);
}

.overviewbox {
  background-color: #fff;
  margin-top: 25px;
  min-height: 355px;
  border-radius: 10px;
}

.overviewbox h3.title,
.activity-wraper h3.title {
  padding: 20px;
}

.dashboard-oa .col-md-6 {
  margin-top: 25px;
  background-color: #fff;
  border-radius: 10px;
}

.box p {
  font-size: 18px;
  margin-bottom: 0;
}

.box h3 {
  font-size: 28px;
}

.activity-wraper {
  background-color: #fff;
  margin-top: 25px;
  border-radius: 10px;
  height: 355px;
}

.activity-wraper ul {
  padding: 15px 20px;
  overflow-y: scroll;
  height: 265px;
}

.activity-wraper ul li a svg {
  margin-right: 10px;
}

.activity-wraper ul li a {
  text-decoration: none;
  color: #080D79;
  display: block;
}

.activity-wraper ul li {
  line-height: 26px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
  margin-bottom: 25px;
}

h3.title {
  font-family: 'Poppins', sans-serif;
  font-size: 22px;
  color: #3e429d !important;
  font-weight: 600;
  line-height: 33px;
}

.activity-wraper ul li:last-child {
  border-bottom: 0;
}

.line {
  border: 1px solid #C6C6C6;
}

.App {
  background-color: #eee;
  width: 100vw;
  height: 100vh;
}

.search-bar-container {
  padding-top: 20vh;
  width: 40%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 200px;
}

/* .login-body {
  background-color: #474DDC;
  height: 100vh;
  padding-top: 75px;
}

.login-box {
  width: 400px;
  margin: 0 auto;
  min-height: 500px;
  border-radius: 8px;
  background-color: #fff;
  padding: 25px;
} */

body {
  font-family: 'Poppins', sans-serif !important;
  font-size: 18px;
  color: #000;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.blue-bg {
  background-color: #474DDC !important;
}

.blue-color {
  color: #474DDC !important;
}

.login-box form {
  text-align: left;
  font-weight: 600;
}

.login-box form label {
  margin-bottom: 10px;
}

.login-box form .btn {
  width: 100%;
  margin-top: 10px;
  padding: 12px 0;
}

.login-box h1 {
  margin: 70px 0;
}

.login-box ul li a {
  text-decoration: none;
}

.login-box ul li {
  margin: 0 10px;
}

.login-box ul li a {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.login-box ul li a:first-child {
  color: #316FF6 !important;
  font-weight: bold;
}

.login-box ul li a:last-child {
  color: #C71610 !important;
  font-weight: bold;
}

.login-box ul {
  display: flex;
  margin-top: 20px;
  justify-content: center;
  padding-left: 0;
}

.login-with {
  margin: 50px 0;
}

.login-with h4 {
  font-size: 18px;
}

.login-box h1 {
  color: #316FF6;
  text-shadow: 5px 5px #b2cbff;
}

input.form-control {
  font-size: 18px;
  border: 1px solid #b9b9b9;
  font-size: 14px;
  padding: 11px 15px;
  margin-top: 0px;
}

.login-box p {
  margin-left: 10px;
}

.otp-inputs {
  display: flex;
  flex-wrap: nowrap;
}

.flex-container {
  display: flex;
}

.flex-container>div {
  margin: 8px;
  font-size: 30px;
}

.flex-container>div input {
  padding: 0;
  width: 30px;
  padding: 0;
  width: 42px;
  text-align: center;
  height: 42px;
}

.login-with h4 {
  text-align: center;
}

.login-box h1 {
  text-align: center;
}

/* .overview-charts{padding: 15px 0px; margin: 50px 30px 0 0;} */
.recharts-responsive-container {
  padding: 20px 40px 0 0;
}

.content {
  background-color: #fff;
  padding: 17px;
  border-radius: 15px;
}

.highcharts-container {
  height: 300px !important;
}

table th {
  background-color: #002989 !important;
  color: #ffffff !important;
  font-weight: normal;
}

.table-container .pagination {
  display: flex;
  justify-content: right;
}

.table-container .pagination li.active a {
  background-color: #474DDC;
  color: #fff;
}

.table-container .pagination li a {
  color: #474DDC;
}

.mt-20 {
  margin-top: 20px !important;
}

.ad-customer {
  margin-top: 13px;
}

.signature {
  border: 1px dashed #474DDC;
  display: block;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-company .col-md-6 {
  margin: 12px 0;
}

.create-company {
  margin-bottom: 10px;
  padding: 15px 20px;
  border: 1px solid #474DDC;
}

.create-company .btn {
  float: right;
}

.create-company .col-md-12 {
  margin: 7px 0;
}

.signature svg {
  margin-right: 7px;
  font-size: 18px;
}

.tax-billing h3 {
  font-size: 22px;
  color: #3e429d;
  ;
  padding-bottom: 14px;
  font-weight: 600;
  line-height: 33px;
}

.tax-billing .col-md-4 {
  margin: 5px 0;
}

.text-right {
  text-align: right;
}

.tax-billing .form-check span {
  float: right;
}

.tax-billing .form-check a {
  margin-left: 10px;
  margin-left: 10px;
  background-color: #e9e8e8;
  padding: 5px 7px;
  border-radius: 5px;
}

.tax-billing .form-check a:last-child svg {
  fill: #a70101;
}

.create-company textarea {
  height: 75px;
}

.add-items {
  margin-top: 20px;
}

.add-items h3 {
  display: flex;
}

.add-items h3 svg {
  margin-right: 7px;
}

.add-items .col-md-4 {
  margin: 7px 0;
}

.add-items .content {
  margin-top: 0;
}

.billing-container h6 {
  margin-bottom: 8px;
  color: #000;
}

.form-select {
  border: 1px solid #b9b9b9;
  padding: 11px;
}

.radio-group .col-md-4 {
  margin: 7px 0;
}

.item-setting .form-select {
  margin: 10px 0;
}

.item-setting h3.title {
  padding-left: 0;
}

.item-setting .content {
  margin-top: 0;
  border-radius: 15px;
}

.item-setting {
  margin-top: 20px;
}

.note {
  color: #3e429d;
  font-size: 12px;
}

.dropdown-box {
  margin-top: 6px;
  position: relative;
}

.dropdown-box svg {
  cursor: pointer;
  font-size: 24px;
  fill: #474DDC;
}

.dropdown-menus {
  position: absolute;
  width: 180px;
  top: 40px;
  right: 0;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  z-index: 99999;
}

.dropdown-menus ul li {
  width: 100%;
  margin: 0;
  display: block;
  padding: 7px 10px;
  cursor: pointer;
}

.dropdown-menus ul li:hover {
  background-color: #474DDC;
  color: #fff;
}

.dropdown-menus ul {
  display: inherit;
}

.upload-img {
  /* background-color: #474DDC !important; */
  color: #160202 !important;
}

.addmore-section {
  overflow: auto;
}

.admore-field {
  margin: 7px 0;
}

.addmore-section button {
  margin-top: 0;
}

.deletebtn {
  padding: 10px 5px;
  cursor: pointer;
}

.deletebtn svg {
  fill: #ff0000;
}

.sp-blue {
  background-color: #E8E9FC;
  display: flex;
  padding: 20px;
  border: 1px solid #474DDC;
  border-radius: 7px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.sp-blue h3 {
  color: #474DDC;
  font-size: 28px;
  font-weight: bold;
}

.sp-blue svg {
  fill: #474DDC;
  margin-right: 10px;
}

.sp-red {
  background-color: #FBE1E1;
  display: flex;
  padding: 20px;
  border: 1px solid #FA0000;
  border-radius: 7px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.sp-red h3 {
  color: #FA0000;
  font-size: 28px;
  font-weight: bold;
}

.sp-red svg {
  fill: #FA0000;
  margin-right: 10px;
}


.sp-green {
  background-color: #E6FFE0;
  display: flex;
  padding: 20px;
  border: 1px solid #23A103;
  border-radius: 7px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.sp-green h3 {
  color: #23A103;
  font-size: 28px;
  font-weight: bold;
}

.sp-green svg {
  fill: #23A103;
  margin-right: 10px;
}

.sp-box {
  display: flex;
}

.sp-table-header p {
  margin-bottom: 0;
  padding-top: 10px;
}


.dashboxes .col-md-12 {
  margin-bottom: 20px;
  padding-right: 0;
}

.activity-wraper li h3 {
  font-size: 20px;
  color: #1921bd;
}

.activity-wraper li {
  line-height: 24px;
}

.activity-wraper p span {
  font-size: 14px;
  color: rgb(167, 167, 167);
}

.activity-wraper p {
  font-size: 14px;
  color: rgb(167, 167, 167);
  margin-bottom: 5px;
}

.collapse-icon {
  float: right;
  font-weight: bold;
  font-size: 24px;
}

.sticky-header th {
  /* position: sticky; */
  top: 0;
  z-index: 1;
  background-color: #fff;
}


.Collapsible__trigger {
  background-color: #3e429d;
  position: relative;
  display: block;
  padding: 10px 20px !important;
  color: #fff;
  font-size: 18px;
  padding: 10px;
}

.Collapsible p {
  padding: 15px;
  border: 1px solid #3e429d;
}

.is-open::before {
  content: "-";
  position: absolute;
  right: 20px;
  color: #fff;
  top: 7px;
  font-weight: bold;
  font-size: 24px;
}

.is-closed::before {
  content: "+";
  top: 6px;
  position: absolute;
  right: 20px;
  color: #fff;
  font-weight: bold;
  font-size: 24px;
}

.Collapsible {
  margin: 5px 0;
}

.table-view {
  overflow-y: scroll;
}

/* Footer.css */
.copyrights {
  background-color: #DFDFDF;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: -3%;
}

.copyrights h5 {
  color: #7D7D7D;
  font-size: 12px;
  font-weight: normal;
  margin: 0;
}


.copyrights a {
  color: #474DDC;
}

.c-company h4 {
  margin-bottom: 20px;
}

.tax-billing svg {
  margin-right: 7px;
}

.c-company {
  min-height: 260px;
  overflow-y: scroll;
}

.managing-tax select {
  margin: 5px 0;
}

.managing-tax {
  border: 1px solid #cbcbcb;
  padding: 20px;
  border-radius: 7px;
  margin-top: 10px;
  height: 540px;
  overflow-y: scroll;
}

.managing-tax .managing-tax {
  height: auto;
  overflow: inherit;
}

.tax-list {
  height: 150px;
  overflow-y: scroll;
  padding-right: 10px;
}

.tax-list svg {
  margin-right: 0;
}

.update-btn {
  display: block;
  border: 0;
}

.checkpermissions {
  margin-top: 0;
}

.roles-permissions h4 {
  margin-bottom: 20px;
}

.ad-customer {
  display: flex;
}

.ad-customer .btn {
  background-color: transparent !important;
  border: 0;
  font-size: 22px;
  color: #080D79;
  font-weight: bold;
}

.savebtn {
  color: #080D79;
  margin-top: 10px;
  cursor: pointer;
}

.theme-color {
  color: #1720cd !important;
}

.ad-customer .btn:hover {
  color: #000;
}

.recent-transiction-table {
  border: 1px solid #bebebe;
  margin-top: 0;
  padding: 5px;
  max-height: 700px;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
}

.recent-transiction-table table th {
  background-color: #a2a2a2 !important;
}

.billing-checks label {
  font-size: 14px;
}

.billing-tabs {
  display: flex;
}

.btab:last-child {
  width: 40px;
}

.addmore-section input {
  margin: 12px 0;
}

.btab {
  cursor: pointer;
  color: #fff;
  background-color: #002989;
  display: inline;
  width: 150px;
  text-align: center;
  padding: 10px 5px;
  border-radius: 7px 7px 0 0;
  margin-right: 7px;
}

.active-tab {
  background-color: #ffffff;
  color: #474DDC;
  display: inline;
  width: 150px;
  text-align: center;
  padding: 10px 5px;
  border-radius: 7px 7px 0 0;
  cursor: pointer;
  margin-right: 7px;
}

.blank-table {
  background-color: #E5E6FF;
  margin: 20px 0;
  color: #474DDC;
  width: 100%;
  border: 1px dashed #474DDC;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blank-table p {
  color: #474DDC;
}

.blank-table svg {
  fill: #474DDC;
}

.tab-content {
  display: none;
}

.active-content {
  display: block;
}

.sale-purchase-box {
  margin-bottom: 20px;
}

.add-items .col-md-6 {
  margin: 15px 0;
}

.discount-group {
  display: flex;
  margin-bottom: 15px;
}

.discount-group label {
  margin-right: 25px;
}

.c-suppliers-collapse .col-md-6 {
  margin: 5px 0;
}

.suppliers-table {
  height: 190px;
}

.add-items .col-md-11 {
  margin-bottom: 10px;
}

.mrp_field {
  font-size: 18px;
  border: 1px solid #b9b9b9;
  font-size: 14px;
  padding: 11px 15px;
  border-radius: 6px;
}

.imei_field {
  font-size: 18px;
  border: 1px solid #b9b9b9;
  font-size: 14px;
  padding: 11px 15px;
  border-radius: 6px;
}

.field-group {
  display: flex;
  align-items: center;
  gap: 8px;
}

.field-group .form-check {
  flex-shrink: 0;
  width: 300px;
}

.field-group input[type="text"] {
  flex-grow: 1;
  border: 1px solid #b9b9b9;
  border-radius: 6px;
  padding: 11px 15px;
  width: 0em;
}

.mrp_input {
  display: flex;
}

.imei_input {
  display: flex;
}

.container_input {
  display: flex;
  gap: 10em;
}

.tax-billing {
  margin-bottom: 2em;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.report-table {
  height: 41vh;
}

select option {
  word-wrap: normal;
  color: black !important;
}

select {
  color: grey !important;
}
