.tax-rate-plus-icon {
  margin-right: 5px;
}

.tax-group-value {
  margin-top: -3px;
  margin-right: 7px;
}

.tax-group-error {
  color: red;
  font-size: small;
  margin-left: 7.2rem;
}

.group-modal{
  margin-top: -20px;
  margin-left: -20px;
  width: 112%;
}

.custom-select {
  .css-1nmdiq5-menu {
    position: relative;
    z-index: 1000000000;
  }
}

/* .css-13cymwt-control{
  top: -25px;
  margin-left: 97px;
} */

.group-footer{
  margin-left: -20px;
  margin-bottom: -20px;
  width: 110%;
}

.tax-group-p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -0.5rem;
}

.tax-group-head {
  font-size: x-large;
  color: #3e429d;
  font-weight: bold;
  margin-top: 10px;
}

.button-group {
  display: flex;
  align-items: center;
}

.tax-group {
  display: flex;
  justify-content: space-between;
}

.related-tax-rates {
  display: flex;
  flex-wrap: wrap;
}

.related-tax-rates ul.horizontal-list {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.related-tax-rates ul.horizontal-list li {
  margin-right: 10px;
}

.group-contain-tax {
  margin-bottom: 15px;
  display: flex;
}

.group {
  margin-right: 10px;
  margin-left: 1px;
  margin-bottom: 15px;
}

.grp-tax-name {
  height: 2rem;
  width: 21rem;
}

.taxes-select {
  width: 21rem;
  top: -26px;
  margin-left: 96px;
}

.tax-group-scroll.mt-3 {
  overflow-y: auto; 
  overflow-x: hidden; 
  height: 60vh; 
}

.tax-group-scroll.mt-3::-webkit-scrollbar {
  width: 8px;
}

.edit-taxrate-submit-btn{
  margin-left: 28rem;
}

.tax-group-scroll.mt-3::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.tax-group-scroll.mt-3::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

