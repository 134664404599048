.restaurant-item-container {
  display: flex;
  gap: 10px;
}

.restourent-left-content {
  overflow-y: auto;
  border-radius: 15px;
  padding: 10px;
  width: 50%;
  background-color: white;
  height: 54rem;
}

.table-name,
.kot-name,
.pox-kot-name,
.date-time-kot,
.kot-caption {
  min-width: 100px;
  margin-right: 2px;
}

.table-name {
  margin-left: 1rem;
}

.kot-heading-modified {
  margin-bottom: 15px;
}

.restourent-right-content {
  border-radius: 15px;
  width: 50%;
  background-color: white;
  height: 54rem;
}

.kot-inner-box-1 {
  display: flex;
}

.kot-header {
  justify-content: space-between;
  margin-bottom: 15px;
  display: flex;
  margin-top: 1rem;
  line-height: 4rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: whitesmoke;
}

.inner-box-1-list {
  border-radius: 15px;
  width: 30%;
  margin-top: 1rem;
  margin-left: 10px;
  height: 52rem;
  background-color: white;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  overflow-x: hidden;
}

.inner-box-1-list-second {
  border-radius: 15px;
  width: 65%;
  margin-top: 1rem;
  margin-left: 10px;
  height: 52rem;
  background-color: white;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

.kot-item-category {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  text-align: center;
  background: #002989;
  color: white;
  margin-bottom: 2rem;
  margin-top: 2rem;
  padding: 6px;
}

.categories-heading {
  font-family: 'Poppins', sans-serif;
  font-size: 22px;
  color: white;
  font-weight: 600;
  line-height: 33px;
  cursor: pointer;
}

.group-category-list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

/* .card-content-kot {
  border: 1px solid #ccc;
  border-radius: 19px;
  padding: 16px;
  margin: 2px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 8rem;
  background-color: #575ac1;
  color: white;
  height: 5rem;
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  align-items: center;
  cursor: pointer;
} */

.card-content-kot:hover {
  background-color: #002989 !important;
}

.card-content-kot.active {
  background-color: #002989 !important;
}

.card-content-kot-1.active {
  background-color: #002989 !important;
}

.card-content-kot {
  border: 1px solid #ccc;
  border-radius: 1rem;
  padding: 1rem;
  margin: 0.2rem;
  box-shadow: 0 0.5rem 0.8rem rgba(0, 0, 0, 0.1);
  width: 10rem;
  background-color: #575ac1;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  align-items: center;
  cursor: pointer;
  line-height: 2rem;
}

.kot-icon-space {
  margin-right: 0.5rem;
  color: red;
}

.search-kot-items {
  margin-top: -26px;
  padding: 6px;
}

.cat-font-size {
  color: black;
  font-size: medium;
}

/* .kot-icon-space {
  margin-right: 10px;
} */

.card-list-kot {
  display: flex;
  flex-wrap: wrap;
}

.card-content-kot-1 {
  display: flex;
  flex-direction: column; 
  align-items: center; 
  justify-content: center; 
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px; 
  margin: 5px; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 120px;
  background-color: #575ac1;
  color: white;
  height: 80px; 
  font-family: 'Poppins', sans-serif;
  font-size: medium;
  cursor: pointer;
  transition: background-color 0.3s ease; 
}

.sub-cat-font-size{
  text-align: center; 
  font-weight: bold;
}

.seleted-group-sub-categories {
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  overflow-y: auto;
  max-height: 200px;
  margin-top: 0.5rem;
}

.seleted-group-sub-categories-items {
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-height: 460px;
  margin-top: 0.5rem;
  overflow-y: auto;
}

.kot-create-buttons {
  display: flex;
  justify-content: left;
  margin-left: -6px;
}

span.kot-caption-group-title {
  margin-right: 0.5rem;
  margin-left: 1rem;
}

span.selected-captain-name-show {
  margin-right: 5px;
}

.select-captain-kot-table-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.kot-create-card-details {
  margin-top: 0.5rem;
  height: 11rem;
  background-color: whitesmoke;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.kot-card-detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.kot-create-value-response {
  max-height: 80px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 80px;
}

.response-value-kot {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white; */
  margin-bottom: 10px;
}

.take-away-order-sub-details {
  overflow-y: auto;
  max-height: 170px;
  height: 170px;
  overflow-x: hidden;
}

.response-kot-total-value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 10px;
  line-height: 2rem;
}

.total-kot-create-title {
  margin-left: 2rem;
}

.total-kot-create-title-value {
  margin-right: 3rem;
}

.total-kot-create-total-value {
  margin-right: 2rem;
}

.kot-label {
  margin-left: 2rem;
}

.kot-label-second {
  margin-right: 2rem;
}

.sub-category {
  opacity: 0;
  transform: translateX(-100%);
  animation: slideInFromLeft 0.5s forwards;
}

.card-content-item-category-kot.active {
  background-color: #002989 !important;
}

.card-content-item-category-kot {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 5px;
  margin: 2px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 200px;
  background-color: #575ac1;
  color: white;
  height: auto;
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  cursor: pointer;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
}

svg.svg-inline--fa.fa-circle.item-kot-type {
  float: right;
  border: 1px solid white;
  color: black;
  background-color: white;
  margin-right: 2px;
  margin-top: 2px;
}

.item-kot-name {
  font-weight: bold;
  text-align: center;
  /* margin-top: 1.5rem; */
}

.kot-container {
  display: flex; 
  align-items: center; 
  justify-content: center; 
  margin-top: 0.5rem;
}

.item-kot-image img {
  border-radius: 5px; /* Rounds the corners of the image */
  max-width: 25px; /* Limits the maximum width */
  max-height: 50px; /* Limits the maximum height */
  object-fit: cover; /* Ensures the image maintains its aspect ratio */
}

.price-of-item-kot {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  flex: 1; /* Allows this div to grow if needed */
  position: relative; /* Removes the fixed positioning */
  /* color: white; */
  margin-left: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}


span.item-price {
  background: white;
  color: black;
  padding: 5px 6px;
  border-radius: 10px;
}

.kot-footer.left {
  flex: 1;
  text-align: left;
}

.kot-footer.right {
  flex: 1;
  text-align: right;
}

.btn-kot-page-save,
.kot-button-success,
.kot-guest-button,
.kot-guest-button
{
  margin-right: 10px;
  background-color: #002989 !important;
  color: #ffffff !important;
  font-weight: normal;
  border-color: #002989;
  border-radius: 10px;
  line-height: 2rem;
  padding: 5px 6px;
}

.dine-in-order-details {
  margin-top: 0.5rem;
  height: 11rem;
  background-color: whitesmoke;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: scroll;
}

.take-away-order-details {
  margin-top: 0.5rem;
  height: 16rem;
  background-color: whitesmoke;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: scroll;
}

svg.svg-inline--fa.fa-circle.item-kot-type.veg {
  color: green;
}

svg.svg-inline--fa.fa-circle.item-kot-type.non-veg {
  color: red;
}

span.kot-select-guest-title {
  margin-right: 1rem;
}

span.selected-guest-name-show {
  margin-right: 1rem;
}

span.item-card-heart-icon {
  margin-left: 0.2rem;
}

.item-heart-icon-red{
  color: red !important;
}

.heart-icon {
  color: white; 
  box-shadow: 0 0 5px rgba(41, 40, 40, 0.5); 
  border-radius: 50%;
  padding: 5px;
  background-color: rgba(103, 99, 99, 0.8); 
}

@keyframes slideInFromLeft {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}


@media (max-width: 1200px) {
  .card-content-kot {
    width: 6rem;
    height: 8rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .card-content-kot {
    width: 5rem;
    height: 3.5rem;
    font-size: 0.8rem;
  }

  .card-content-kot-1 {
    width:  5rem; 
    height: 70px; 
  }
}

@media (max-width: 480px) {
  .card-content-kot {
    width: 4rem;
    height: 3rem;
    font-size: 0.7rem;
  }

  .card-content-kot-1 {
    width:  4rem;
    height: 60px; 
  }
}

.kot-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  width: 100%;
  margin-bottom: 10px;
  height: auto; 
  border-radius: 8px;
  margin-top: 1rem;
  padding: 10px;
  flex-wrap: wrap;
}

.kot-footer-left, 
.kot-footer-right, 
.kot-footer-center {
  display: flex;
  align-items: center;
}

.kot-footer-left {
  flex: 1;
}

.kot-footer-right {
  flex: 1;
  justify-content: flex-end;
  display: flex;
}

.kot-footer-center {
  flex: 8; 
  justify-content: center;
  flex-wrap: wrap;
  text-align: center; 
}

.kot-btn-parcel, 
.kot-invoice-nc {
  margin-right: 10px;
  background-color: #002989 !important;
  color: #ffffff !important;
  font-weight: normal;
  border-color: #002989;
  margin-left: 10px;
  border-radius: 10px;
  padding: 5px 6px;
  line-height: 2rem;
}

.kot-footer-input-checkbox {
  margin-right: 5px;
}

.kot-dis-button {
  background-color: #002989 !important;
  color: #ffffff !important;
  font-weight: normal;
  border-color: #002989;
  border-radius: 10px;
  padding: 5px 10px;
  line-height: 2rem;
}

.restaurant-kot-group-container {
  overflow-y: auto;
  max-height: 90vh;
}

@media (max-width: 768px) {
  .kot-footer-center {
    flex: 1;
  }
  .kot-footer-left, 
  .kot-footer-right {
    flex: 1; 
  }
  .kot-footer-center-checkbox-1, 
  .kot-footer-center-checkbox-2 {
    margin-top: 5px; 
  }
}
