.company-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .company-initials {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0056b3;
    color: white; 
    font-size: 28px;
    border-radius: 50%;
    font-weight: bold;
  }

  .menu:hover{
    background-color: white;
    color: #0056b3;
  }
