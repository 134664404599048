.template-two-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    text-align: center;
  }
  
  .second-template-two-main {
    background-color: #fff;
    padding: 20px;
    width: 100%;
  }
  
  .second-template-two-footer {
    text-align: center;
    margin-bottom: 20px;
  }

  .second-template-two-sub{
    font-size: small;
  }
  
  .second-template-two-sub-header {
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .template-two-date,
  .template-two-time {
    margin-bottom: 10px;
  }
  
  hr, .hr-dotted {
    border-top: 3px dotted #888;
  }
  
  .table-header-dotted {
    border-top: 3px dotted #888;
    border-bottom: 3px dotted #888;
  }
  
  .template-two-date-time{
    display: flex;
    justify-content: space-between;
  }
  
  .template-two-expenses {
    display: flex;
    margin-left: 28rem;
  }
  
  .second-company-title{
    font-size: small;
    font-weight: bold;
  }
  
  .template-two-policy{
    font-size: larger;
  }

  .template-two-second-content{
    text-align: center;
    background: #38475a;
    color: white;
  }

  .barcode-photo{
    height: 6rem;
    margin-left: 9rem;
  }

  .second-barcode{
    height: 3rem;
    width: 6rem;
  }

  .template-two-second-company-address, 
  .template-two-second-com-mail, 
  .template-two-second-content{
    font-size: small;
  }

  .cash-memo-template-second{
    width: 100%;
  }
  
  @media print {
    @page {
      size: A4;
      margin: 0; /* Set margin to 0 for full utilization of space */
    }
  
    body {
      margin: 0; /* Remove default body margin */
    }
  
    .template-two-main {
      font-size: 10px; /* Set base font size */
      line-height: 1.2;
      max-width: 210mm; /* A4 width in mm */
      margin: 0 auto; /* This centers the main div horizontally */
      padding: 10px;
      box-sizing: border-box; /* Ensures padding is included in width */
    }
  
    .template-two-sub {
      margin: 20px 0 0 0; /* Only margin at the top to start from top */
    }
  
    .cash-memo-template-second {
      width: 100%; /* Ensure table uses full width */
      border-collapse: collapse; /* For proper alignment */
      margin: 10px 0; /* Spacing above and below the table */
    }
  
    /* Other table styles remain unchanged */
    .cash-memo-template-second th,
    .cash-memo-template-second td {
      border: 1px solid #ddd; /* Border for cells */
      padding: 3px; /* Cell padding */
      text-align: left; /* Left align text */
    }
  
    .template-two-footer {
      text-align: center; /* Center footer content */
      margin-top: 10px; /* Some spacing above */
      font-size: 9px; /* Font size for footer */
    }
  
    /* Ensure barcode image is correctly centered */
    .barcode-image {
      display: block;
      margin: 10px auto; /* Center the barcode image */
      max-width: 100%; /* Responsive width */
      height: auto; /* Maintain aspect ratio */
    }
  
    /* Other necessary print styles here */
  }
  
  .template-two-main {
    font-size: 10px;
    line-height: 1.2;
    max-width: 210mm;
    margin: 0 auto;
    padding: 10px;
  }
  
  .template-two-sub-header {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .template-two-date-time {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  
  .cash-memo-template-second {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 10px;
  }
  
  .cash-memo-template-second th,
  .cash-memo-template-second td {
    border: 1px solid #ddd;
    padding: 3px;
    text-align: left;
  }
  
  .cash-memo-template-second th {
    background-color: #f2f2f2;
  }
  
  .tax-font-size {
    font-size: 8px;
  }
  
  .template-two-summary-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 10px;
    margin-left: 30px;
  }
  
  .template-two-summary-table td {
    padding: 2px;
  }
  
  .template-two-summary-table td:first-child {
    text-align: right;
    font-weight: bold;
  }
  
  .barcode-image {
    display: block;
    margin: 10px auto;
    max-width: 100%;
    height: auto;
  }
  
  .template-two-footer {
    text-align: center;
    margin-top: 10px;
    font-size: 9px;
  }
  
  .company-title {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 3px;
  }
  
  .template-two-company-address,
  .template-two-com-mail {
    margin-bottom: 2px;
  }
  
  .template-two-content {
    text-align: center;
    background: #38475a;
    color: white;
    padding: 5px;
    font-size: 9px;
    margin-top: 10px;
  }
  
  @media screen {
    .template-two-main {
      border: 1px solid #ddd;
      padding: 20px;
      margin: 20px auto;
    }
  }