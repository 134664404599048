.dual-form-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  
  .form-section {
    flex: 1;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .notification-form {
    border-left: 4px solid #007bff;
  }
  
  .whatsapp-form {
    border-left: 4px solid #28a745;
  }
  
  h4 {
    margin-bottom: 20px;
    color: #333;
    font-weight: 600;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .btn-success {
    background-color: #28a745;
    border-color: #28a745;
  }