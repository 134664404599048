.dash-box {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
}

.card-smal {
  min-height: 100%;
}

.dash-box select {
  /* width: 200px; */
  float: right;
}

.large-box {
  min-height: 100%;
}

.sale-details {
  border-right: 2px dashed #ccc;
  margin-top: 20px;
  margin-top: 55px;
}

.sale-details h4 {
  margin-top: 40px;
  color: #0ba500;
}

.sale-details h4 svg {
  fill:#0ba500 ;
}

.large-box svg {
  fill: #474DDC;
  margin-right: 7px;
}

.large-box h3 {
  color: #233D5C;
  font-size: 24px;
}

.dash-box {
  position: relative;
}

.dash-report-txt {
  position:absolute;
  bottom: 10px;
  text-align: center;
}

.dashvalue span {
  font-size: 20px;
  color: #888888;
}

.dashbalue h3 {
  font-weight: bold;
}

.yourecieve svg {
  fill: #0ba500;
}
.youpay svg {
  fill: #d10000;
}

.card-smal {
  position: relative;
}

.card-smal p {
  position:absolute;
  right: 0;
  bottom: 0;
  text-align: center;
  font-size: 20px;
  color: #8D8D8D;
  width: 100%;
}

.card-smal h3 {
  font-size: 22px;
}

.card-smal h3 svg {
  margin-right: 7px;
}

.dashpurchase svg {
  fill: #1720cd;
}

.dash-report-txt h5 {
  font-size: 14px;
  text-align: center;
}

.dash-report-txt p {
  font-size: 12px;
  color: #7d7d7d;
}

.col-md-8 .large-box .dash-report-txt {
  text-align: left;
}

.dashvalue h3 {
  font-size: 36px;
}

.card-smal .dashvalue h3 {
  margin-top: 30px;
}

.sale-details h5, .sale-details p {
  font-size: 16px;
  font-weight: normal;
  color: #7d7d7d;
}

.dashvalue span {
  background-color: transparent;
}

select.form-select.form-dashborad-main-box-filter {
  width: 35%;
}
