.item-settings-container {
  display: flex;
  justify-content: space-around;
}

.item-settings-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; 
}

.left-panel {
  width: 100%; 
}

.option-list {
  gap: 7px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  display: grid;
}

.option-item {
  flex: 1 1 calc(25% - 10px); 
  margin-bottom: 15px; 
  padding: 10px 15px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow  0.3s ease;
}

@media (max-width: 768px) {
  .option-list {
    grid-template-columns: repeat(2, 1fr); 
  }
}

@media (max-width: 480px) {
  .option-list {
    grid-template-columns: 1fr;
  }
}

.heading_content {
  font-family: 'Poppins', sans-serif;
  font-size: 2rem;
  font-weight: 700;
  color: #002989;
  letter-spacing: 2px;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
  padding-bottom: 10px;
  border-bottom: 2px solid #002989;
  background-color: #f5f5f5;
  padding: 15px;
  margin-top: -17px;
  border-radius: 5px;
  width: 102%;
  margin-left: -17px;
}

.item-settings,
.additional-item-fields {
  width: 48%;
  margin-bottom: 20px;
  display: flex;
  padding: 20px; /* add padding to each section */
  border: 1px solid #ddd; /* add a border to each section (optional) */
  border-radius: 10px; /* add a border radius to each section (optional) */
}

.item-settings,
.additional-item-fields {
  width: 48%;
  margin-bottom: 20px;
  display: flex;
}

.item-setting-row,
.additional-field-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.item-setting-row input[type="checkbox"],
.additional-field-row input[type="checkbox"] {
  margin-right: 10px;
}

.item-setting-row label,
.additional-field-row label {
  flex: 1;
}

.additional-field-row .input-container {
  display: flex;
  align-items: center;
}

.additional-field-row .input-container input[type="text"],
.additional-field-row .input-container select {
  margin-left: 10px;
}

.quantity-container {
  display: flex;
  align-items: center;
}

.quantity-container input[type="number"] {
  margin-right: 10px;
}
.form-group input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.form-group label {
  /* display: block; */
  /* margin-bottom: 5px; */
  display: inline-flex !important;
}

.form-group-i {
  display: flex;
  gap: 10px;
  margin-bottom: 1em;
  justify-content: space-between;
  text-align: center;
}
.checkboxes {
  width: 18px;
}
.form-group-i input[type="checkbox"] {
  margin-right: 10px;
}

.form-group-i label {
  margin-right: 10px;
}
.form-input-i {
  width: 167px;
  height: 40px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group-j {
  display: flex;
  gap: 10px;
  margin-bottom: 1em;
}

.h5-price{
    margin-bottom: 2em;
}

.checkboxes-i{
width: 18px;
}

.btn-save{
  display: flex;
  gap: 23rem;
}

.heading_content{
  font-family: 'Poppins', sans-serif;
  font-size: 2rem;
  font-weight: 700;
  color: #002989;
  letter-spacing: 2px;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
  padding-bottom: 10px;
  border-bottom: 2px solid #002989;
  background-color: #f5f5f5;
  padding: 15px;
  margin-top: -17px;
  border-radius: 5px;
  width: 102%;
  margin-left: -17px;
}

.option-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 13px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.option-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.option-item input[type="checkbox"] {
  margin-right: 10px;
  cursor: pointer;
}

.option-item label {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  color: #333;
  cursor: pointer;
  margin-left: 10px;
}