.toggle-button {
    display: inline-block;
    vertical-align: middle;
  }

  .toggle-button__input {
    display: none;
  }

  .toggle-button__label {
    display: inline-block;
    position: relative;
    width: 60px;
    height: 34px;
    background-color: #ccc;
    border-radius: 34px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .toggle-button__switch {
    display: inline-block;
    position: absolute;
    top: 2px;
    left: 2px;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }

  .toggle-button__input:checked + .toggle-button__label {
    background-color: #002989;
  }

  .toggle-button__input:checked + .toggle-button__label .toggle-button__switch {
    transform: translateX(26px);
  }