.sales-order-main-container {
  display: flex;
  flex-direction: column;
  height: 82vh;
  background-color: white;
  padding: 1rem;
  border-radius: 1rem;
  margin-top: 1rem;
}

.sales-order-container-first-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sales-order-first-left {
  display: flex;
  align-items: center;
}

.sales-order-first-right {
  display: flex;
  justify-content: flex-end;
}

button.sales-order-first-right-botton {
  display: flex;
  align-items: center;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  background-color: #002989;
  gap: 1rem;
}

.sales-order-container-second-div {
  margin-top: 1rem;
}

.sales-order-second-div-right {
  display: flex;
  justify-content: space-between;
}

.sale-order-right-first {
  display: flex;
  gap: 1rem;
}

.sales-order-second-drop-first {
  background-color: #002989;
  color: white;
  padding: 0.2rem 0.5rem;
  display: flex;
  gap: 1rem;
  border-radius: 0.5rem;
  height: 2rem;
}

.sales-order-second-filter {
  background-color: #002989;
  color: white;
  padding: 0.2rem 0.5rem;
  border-radius: 0.4rem;
  height: 2rem;
}

span.sales-order-ellips-icon {
  color: #002989;
  font-size: x-large;
  font-weight: bold;
}

.quotation-second-filter {
  background-color: #002989;
  color: white;
  padding: 0.2rem 0.4rem;
  border-radius: 0.4rem;
}

span.all-sales-order-show {
  color: #002989;
  font-size: x-large;
  font-weight: bold;
}

/* sales order table css */
.sale-order-table-group {
  overflow-x: auto;
  border-bottom: 1px solid #ccc;
  margin-top: 1rem;
  overflow-y: auto;
  max-height: 350px;
}

.sale-order-table {
  width: 100%;
  border-collapse: collapse;
}

.sale-order-thead-row th {
  padding: 0.4rem;
  border-bottom: 1px solid #ccc;
  text-align: left;
  border: 1px solid #ccc;
  font-size: small;
  font-weight: bold;
}

.sale-order-table-body td {
  padding: 0.2rem;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  text-align: left;
  font-size: small;
}

.sale-order-tab-td {
  font-size: small;
}

.sale-order-table-column-popup {
  position: absolute;
  top: 13rem;
  right: 1rem;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 10;
  width: 25%;
}

.sale-order-table-column-popup {
  position: absolute;
  top: 13rem;
  right: 1rem;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 10;
  width: 15%;
}

.sale-order-table-column-popup label {
  display: flex;
  margin-bottom: 0.5rem;
  font-size: x-small;
}

input.sale-order-visible-column-input {
  width: 10%;
  margin-right: 4%;
}

button.sale-order-visible-box-btn {
  background-color: #002989;
  color: white;
  padding: 0.1rem 0.2rem;
  font-size: small;
  border: none;
  border-radius: 0.2rem;
}

.new-sale-order-popup-container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  z-index: 1000
}

/* sale order filter css */
.sale-order-filter-popup-container{
  position: absolute;
  top: 13rem;
  right: 1rem;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 10;
  width: 30%;
}

label.sale-order-first-att-div-label,
.sale-order-second-att-div-label,
.sale-order-third-part-1-label,
.sale-order-third-part-2-label{
  font-size: x-small;
}

.sale-order-first-att-div,
.sale-order-second-att-div,
.sale-order-third-part-1,
.sale-order-third-part-2{
  display: flex;
  flex-flow: column;
  margin-bottom: 0.5rem;
}

.sale-order-third-att-div {
  display: flex;
  justify-content: space-between;
}

input.sale-order-third-att-date {
  padding: 0.2rem;
  font-size: x-small;
  width: 100%;
}

button.sale-order-attr-pop-btn {
  border: none;
  border-radius: 0.2rem;
  font-size: small;
  padding: 0.2rem 0.4rem;
  background-color: #002989;
  color: white;
}

.sale-order-attr-btn-grp {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

/* sales order down icon action css */
.sale-order-down-icon-action{
  position: absolute;
  top: 19rem;
  right: 1rem;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
  width: 15%;
}

svg.svg-inline--fa.fa-caret-down.sale-order-table-body-td {
  margin-left: 0.5rem;
}

ul.sale-order-down-icon-action-ul {
  padding: 0px;
  margin-bottom: 0px;
}

li.sale-order-down-icon-action-li {
  padding: 0.2rem;
  font-size: small;
}

.sale-order-down-icon-action-li:hover{
  background-color: #ccc;
  cursor: pointer;
}
