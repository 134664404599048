.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content-create-floors {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    width: 30%;
    max-height: 100vh;
    overflow-y: auto;
  }
  
  .modal-content-create-floors h2 {
    margin-top: 0;
  }
  
  .floor-details {
    display: flex;
    padding: 10px;
    border-radius: 10px;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .remove-link {
    width: 4%;
    font-size: 30px;
    margin-left: 95%;
    cursor: pointer;
    color: red;
    text-decoration: none;
  }
  
  .remove-link:hover {
    text-decoration: underline;
  }
  
  .floor-input {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 95%;
    margin-left: 12px;
    margin-bottom: 10px;
  }
  
  .floor-input input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .floor-details .input-error {
    border-color: red;
  }
  
  .error-message {
    color: red;
    font-size: 0.8em;
    margin-top: 5px;
  }
  
  .limit-message {
    color: red;
    font-size: 0.9em;
    margin-top: 10px;
  }
  
  .modal-content-create-floors button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    background-color: #002989;
    color: #fff;
    cursor: pointer;
    margin-right: 10px;
    width: 100%;
    margin-right: 1px;
  }
  
  .modal-content-create-floors button:hover {
    background-color: #0056b3;
  }
  
  .modal-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
