.item-unit-main-container {
  display: flex;
  flex-direction: column;
  height: auto;
  background-color: #fff;
  padding: 17px;
  border-radius: 15px;
  overflow: hidden;
}

.item-add-unit-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px; 
}

.unit-list-name {
  font-size: large;
  color: #3e429d;
  font-weight: bold;
}

.item-unit-second-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

svg.svg-inline--fa.fa-plus.unit-plus-icon {
  margin-right: 0.5rem;
}

.item-unit-popup-container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.unit-table-container {
  overflow-y: auto;
  max-height: 450px;
  margin-top: 1rem;
}

.unit-pagination-dropdown{
  display: flex;
  align-items: center;
  height: 3rem;
}
