.sale-common-popup-main-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem;
  background-color: #fff;
  line-height: 1.5rem;
  display: flex;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, .1);
  transition: color .2s ease;
}

.sale-common-popup-right-part {
  display: flex;
  gap: 1rem;
}

button.sale-common-header-button {
  background-color: #002989;
  color: white;
  padding: 0.2rem 0.8rem;
  border-radius: 0.2rem;
  border: 1px solid #002989;
}

.sale-common-popup-left-part {
  display: flex;
  flex-flow: column;
}

.sale-common-location-group {
  display: flex;
  justify-content: center;
  gap: 0.2rem;
}