/* .logo-content {
  text-align: center;
  margin-bottom: 40px;
} */

.logo-content {
  text-align: center;
  margin-bottom: 4rem;
  margin-top: -3rem;
}

/* .logo-image {
  height: 60px;
  border-radius: 8px;
  margin-top: 70px;
  
} */

img.company-unique-logo-image {
  margin-top: 6rem;
  background-color: white;
  height: 4rem;
  width: 73%;
}

.login-body {
  background-color: #474DDC;
  height: 100vh;
  padding-top: 75px;
}

.login-box {
  width: 22%;
  margin: 0 auto;
  min-height: 50%;
  border-radius: 8px;
  background-color: #fff;
  padding: 1.5rem;
  text-align: center;
  box-sizing: border-box;
}

span.login-title-name {
  font-size: x-large;
  font-weight: bold;
}

button.btn.btn-primary.login-submit-button {
  margin-top: 1rem;
}

label.otp-enter-title {
  margin-left: 0.5rem;
  margin-top: 1rem;
}

input.form-control.enter-mobile-number-field {
  margin-top: 1rem;
}
.remeber-me{
  margin: 0px 5px 0px 5px;
  font-weight: lighter;
}