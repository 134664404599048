.transfer-table-modal {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.modal-header, .modal-footer {
  background-color: #002989; /* Dark background */
  color: white;
  padding: 10px;
}

.modal-body {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.table-list {
  max-height: 300px; /* Adjust the height as needed */
  overflow-y: auto;
  border: 1px solid #ddd;
  padding: 10px;
  background-color: #f8f9fa;
}

.table-option-transfer {
  width: 25%;
  background-color: orange;
  display: block;
  padding: 10px;
  border-radius: 3px;
  align-items: center;
  margin-bottom: 5px;
}

.table-option-transfer-with {
  width: 25%;
  background-color: green;
  display: block;
  border-radius: 3px;
  padding: 10px;
  align-items: center;
  margin-bottom: 5px;
}

.table-option-transfer input, .table-option-transfer-with input {
  margin-right: 10px;
}

.transfer-btn {
  border-radius: 50px;
  width: 10%;
  padding: 10px;
  color: #002989;
  font-weight: 700;
  border: none;
  cursor: pointer;
}

.transfer-btn:hover {
  background-color: #0056b3;
  color: #fff;
}

.close-button {
  background-color: #002989;
  border: none;
  color: rgb(163, 163, 163);
  font-size: 20px;
  cursor: pointer;
  top: 0px !important;
}

.close-button:hover {
  background-color: #002989;
  color: rgb(255, 255, 255) !important;
}
