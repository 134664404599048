.Kitchenceff-header {
  background-color: #002c8a;
  height: 50px;
  padding: 10px;
  color: white;
}

.Kitchenceff-intro {
  font-size: small;
  margin: 0;
}

.react-trello {
  display: flex;
  flex-direction: row;
  margin: 0;
}

.react-trello-lane {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  border-radius: 10px;
  background-color: #f0f0f0;
  padding: 10px;
  width: 30%;
}

.react-trello-lane-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #001f5b;
  border-radius: 10px 10px 0 0;
  padding: 5px 10px;
  font-weight: bold;
  color: white;
}

.react-trello-lane-header-title {
  font-size: 16px;
}

.react-trello-lane-header-button {
  background-color: transparent;
  border: none;
  font-size: 12px;
  cursor: pointer;
  color: white;
}

.react-trello-lane-header-button:hover {
  opacity: 0.7;
}

.alldetails {
  margin-left: 10px;
  color: white;
  font-size: 16px;
}

.react-trello-lane-content {
  padding: 10px;
}

.react-trello-card {
  border-radius: 5px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: black;
}

/* Card status colors */
.react-trello-card.placed {
  background-color: #fb1f1fb8;
}

.react-trello-card.priority-list {
  background-color: #ffa500;
}

.react-trello-card.preparation {
  background-color: #77770c91;
}

.react-trello-card.ready-to-serve {
  background-color: #14c014b3;
}

.react-trello-card-title {
  font-weight: bold;
  font-size: 18px;
  margin-left: 10px;
  color: white;
}

.react-trello-card-description {
  font-size: 12px;
  color: black;
}

.react-trello-card-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.react-trello-card-actions-button {
  background-color: transparent;
  border: none;
  font-size: 12px;
  cursor: pointer;
}

.react-trello-card-actions-button:hover {
  opacity: 0.7;
}

.react-trello-card-add-button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: #001f5b;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.react-trello-card-add-button:hover {
  opacity: 0.8;
}

.react-trello-card-add {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  margin-top: 10px;
}

.react-trello-card-add input {
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.react-trello-card-add button {
  background-color: #001f5b;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.react-trello-card-add button:hover {
  opacity: 0.8;
}

.description-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  margin-left: 10px;
  font-size: 17px;
}

span.description-cross-symbole {
  margin-left: 1rem;
  font-weight: bold;
  margin-right: 5px;
  color: gray;
}
