.container-fluid {
  background-color: white;
  border-radius: 5px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #002989;
  color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.merge-table-header {
  font-size: 24px;
  font-weight: bold;
}

.merge-close-button {
  background: none;
  border: none;
  color: #ccc;
  font-size: 34px;
  cursor: pointer;
}

.merge-close-button:hover{
  color: #fff;
}

.merge-table-main {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.merge-table-from, .merge-table-to {
  flex: 1;
  gap: 10px;
}

.table-from-header, .table-to-header {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
}

.form-table-sub, .form-table-to-sub {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
}

.floor-header {
  background-color: #6c757d;
  color: white;
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 3px;
}

.from-table {
  background-color: orange;
  width: 28%;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
  border-radius: 3px;
}

.from-table .form-check-input {
  margin-right: 10px;
}

.table-label {
  margin-left: 10px;
}

.modal-footer {
  display: flex;
  /* justify-content: center; */
  /* padding: 10px 20px; */
  background-color: #002989;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.merge-btn {
  background-color: white;
  color: #002989;
  font-weight: bold;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  cursor: pointer;
}

.merge-btn:hover {
  background-color: #0056b3;
  color: white;
}
