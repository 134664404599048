.input-select {
  width: 100%;
  padding: 6px 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.label-contents {
  font-weight: 600;
  color: #002989;
  font-size: 30px;
  margin-bottom: 1rem;
}

.search-item-cont {
  margin-right: 10px;
  color: #8b8989;
}

.save-btn {
  margin-top: 10px;
  padding: 8px 16px;
  font-size: 14px;
  background-color: #002989;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.total-section {
  font-size: 16px;
  text-align: right;
  border: 1px solid grey;
  position: relative;
}

.sub-btn {
  margin-top: 10px;
  font-weight: bold;
  font-size: 16px;
  text-align: right;
  background-color: #474ddc;
}

.form-control:disabled {
  background-color: white !important;
  opacity: 1;
}

/* Responsive styles */
@media (max-width: 600px) {
  .customer-section {
    flex-direction: column;
  }
}

.search_content {
  margin-right: 10px;
}

.table-td {
  padding: 6px;
  font-size: 18px;
  border: 1px solid;
}

.supplier-phone {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  width: 18%;
}

.supplier-form {
  display: flex;
  /* gap: 30px; */
  justify-content: space-between;
}

.table-th {
  background-color: whitesmoke !important;
  color: black !important;
  font-weight: normal;
  border: 1px solid;
  padding: 19px;
  z-index: auto;
}

.table-row {
  border: 1px solid;
  padding: 2px;
}

.amount {
  display: flex;
  align-items: center;
  background-color: white;
}

.add-btn {
  color: #474ddc;
  font-weight: bold;
  display: flex;
  padding: 3px 1px 4px 7px;
  cursor: pointer;
}

.cal-total-purchase {
  position: relative;
  /* top: -1rem; */
  margin-right: 2rem;
}

.select-input {
  padding-bottom: 13px;
  width: 16rem;
}

.table-tr {
  border-color: inherit;
  border-width: 0;
}

.notice {
  color: #474ddc;
}

.number-input[type="number"]::-webkit-inner-spin-button,
.number-input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}

.table-td {
  position: relative;
}

.item-suggestions-sale {
  position: absolute;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 0;
  margin: 0;
  list-style-type: none;
  max-height: 200px;
  overflow-y: auto;
  width: 214px;
}

.item_list:hover {
  background-color: #eeeded;
  cursor: pointer;
}

.item-suggestions li {
  padding: 8px 12px;
  cursor: pointer;
}

.item-suggestions li:hover {
  background-color: #f5f5f5;
}

.css-1u9des2-indicatorSeparator {
  display: none;
}

.css-1hb7zxy-IndicatorsContainer {
  color: grey;
}

.phone-number {
  margin-right: 67rem;
}

.in-with-tax {
  margin-left: -20px;
}

.in-without-tax {
  margin-right: -57px;
}

.disable-pointer {
  pointer-events: none;
  cursor: not-allowed;
}

.bold-text {
  font-weight: bold;
}

select.input-select.dropdown-input\,.with-color-text {
  background-color: white;
  border-left: thick solid whitesmoke;
  color: grey;
}

.slect-unit {
  border: none;
  background-color: white;
}

td .edit-trash {
  height: 15px;
  width: 42%;
  color: red;
  margin-right: 10px;
  margin-left: 12px;
}

select {
  word-wrap: normal;
  color: grey !important;
  font-size: 16px !important;
  background-color: white;
}

.table-td select {
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  font-size: 14px !important;
  color: #625e5e !important;
  margin-top: 0px;
}

/* .form-control{
    height: 39px;
} */

.select-purchase-input option:hover {
  background-color: blue;
  opacity: 0.8;
}

.select-wrapper:hover {
  background-color: blue !important;
  opacity: 0.7;
}

.select-wrapper--is-selected {
  background-color: blue !important;
}

.bold-select {
  font-weight: bold;
  color: black;
}

.element-content {
  margin-right: -4px;
  height: 39px;
  width: 106px;
  margin-left: 5px;
}

.element-content-unit {
  height: 39px;
  width: 106px;
}

.s_no {
  width: 4%;
}

.item_name {
  width: 12%;
}

.item_code {
  width: 6%;
}

.hsn_no {
  width: 6%;
}

.qty {
  width: 5%;
}

.price_per_unit {
  width: 17%;
}

.item_tax {
  width: 11%;
}

.item_unit {
  width: 7%
}

.item_amount {
  width: 6%;
}






.select.selector.element-content {
  width: 50%;
}

.bg_purchase_button {
  border: 2px solid #002989;
  background-color: #002989;
  color: #fff;
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.active_purchase_button {
  background-color: #e7e8e9;
  color: #002989;
}

.total-sub-section {
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
}

span.purchase-item-tax {
  margin-right: 10rem;
}

.purchase-toggle-div {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 2rem;
}

.toggle-container {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.toggle-container input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked+.toggle-slider {
  background-color: #002989;
}

input:checked+.toggle-slider:before {
  transform: translateX(26px);
}

.purchase-toggle-label {
  font-size: larger;
  color: #333;
}

.supplier-form-sub-fields {
  display: flex;
  gap: 1rem;
}

button.purchase-bill-file {
  padding: 5px 6px;
  border-radius: 5px;
  border: 1px solid #002989;
  background-color: #002989;
  color: white;
  line-height: 2rem;
}


.stock-purchase-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  /* Ensure the modal appears on top */
}

/*  */
.stock-purchase-modal-content {
  background: white;
  padding: 1rem;
  border-radius: 8px;
  width: auto;
  position: relative;
}

.stock-purchase-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.stock-purchase-modal-body {
  padding-top: 30px;
}

.stock-purchase-detail-modal-body {
  margin-top: 2rem;
  overflow-y: auto;
  max-height: 250px;
}

.stock-payment-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.purchase-amount-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.stock-payment-popup-content {
  background-color: white;
  /* padding: 20px; */
  border-radius: 8px;
  width: 400px;
  position: relative;
}

.stock-payment-popup-header {
  display: flex;
  justify-content: space-between;
  background-color: #002989;
  line-height: 3rem;
  color: white;
}

.stock-payment-popup-close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: white;
  font-size: xx-large;
  font-weight: bold;
  margin-right: 1rem;
}

.stock-payment-popup-body {
  padding: 20px;
}

.purchase-invoice-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.purchase-invoice-popup-content {
  background: white;
  padding: 3rem;
  border-radius: 5px;
  position: relative;
  width: auto;
  height: auto;
}

.purchase-invoice-close-sign {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 1.5rem;
}

.purchase-invoice-image {
  max-width: 100%;
  max-height: 50vh;
}

select.form-select.stock-form-select {
  height: 3rem;
  margin-bottom: 1rem;
}

input.form-control.stock-input-field {
  width: 22rem;
}

span.stock-pay-head {
  padding-left: 1.5rem;
  font-size: larger;
}

input.form-control.purchase-group-input-phone {
  height: 3rem;
}

.disabled-btn-paynow {
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 4px 2px;
  height: 39px;
  background: #999;
  width: 93px;
  border-radius: 5px;
}

.no-suppliers-message {
  display: flex;
  align-items: center;
}

.add-supplier-link {
  margin-left: 8px;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.add-supplier-link:hover {
  color: darkblue;
}