  .discount-invoice-main {
    width: 60%;
    height: auto;
    border-collapse: collapse;
    background-color: white !important;
  }

  .invoice-discount-title {
    width: 100%;
    background-color: #002989 !important;
    height: 3rem;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
  }

  .invoice-discount-title-heading {
    font-size: larger;
    font-weight: bold;
  }

  .invoice-discount-boxes {
    height: auto;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    gap: 4rem;
  }

  .invoice-discount-box-first {
    height: auto;
    width: 60%;
  }

  /* .invoice-discount-box-second {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 5px;
    width: 40%;
    height: 20rem;
    width: 40%;
    margin-top: 1.8rem;
    margin-right: 2rem;
  } */

  .keypad-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #808080;
    color: white;
    height: 50px;
    border: none;
    cursor: pointer;
  }

  .discount-calcu-button-clear {
    background-color: #002989;
    color: white;
    line-height: 2rem;
    border-radius: 10px;
  }

  .discount-calcu-button-enter {
    background-color: #002989;
    color: white;
    line-height: 2rem;
    border-radius: 10px;
  }

  .discount-calcu-button-cancel {
    background-color: #002989;
    color: white;
    line-height: 2rem;
    border-radius: 10px;
  }

  #amount {
    margin-left: -3rem;
  }

  .discount-reason-box {
    margin-top: 2rem;
  }

  input.form-control.discount-input-box {
    width: 94%;
    margin-left: 2rem;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  span.discount-food-title {
    margin-left: 1.8rem;
    font-size: larger;
    font-weight: bold;
  }

  span.select-discount-reason-title {
    margin-left: 2rem;
  }

  .add-new-reason {
    display: flex;
  }

  .add-new-reason-title {
    margin-left: 2rem;
  }

  .add-new-reason {
    margin-top: 2rem;
  }

  input.form-control.add-new-discount-reason {
    margin-left: 2rem;
    width: 93%;
  }

  .add-new-reason-title {
    margin-left: 2rem;
    margin-top: 1rem;
  }

  .select-discount-reason-group {
    overflow: auto;
    height: auto;
    margin-top: 2rem;
  }

  button.discount-save-and-select {
    margin-left: 2rem;
    background-color: #002989;
    color: white;
    margin-top: 1rem;
    line-height: 2rem;
    padding: 5px;
    border-radius: 10px;
  }

  .discount-input-and-label {
    display: flex;
    align-items: center;
    margin-left: 8%;
    gap: 0rem;
  }

  .discount-input-and-label label {
    margin-right: 10px;
  }

  .discount-input-and-label input[type="text"] {
    margin-left: 10px;
    padding: 5px;
  }

  .discount-label-and-input {
    display: flex;
    gap: 0.5rem;
  }

  .discount-input-and-label-face-2 {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 1rem;
  }

  .discount-invoice-second-content {
    display: flex;
    align-items: center;
    gap: 34px;
  }

  .coupon-title {
    margin-right: 5px;
  }

  /* .form-control {
    padding: 5px;
  } */

  .discount-apply-coupon-button{
    padding: 5px 10px;
    margin-top: 0rem;
    border-radius: 10px;
    background-color: #002989;
    color: white;
    width: 100%;
  }

  input.form-control.dis-coupon-input-field {
    width: 13rem;
    margin-left: -0.4rem;
  }

  .dis-coupon-title {
    margin-left: 2rem;
  }

  .discount-reason-select {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-left: 2rem;
    margin-top: 1rem;
  }

  .coupon-distance-margin {
    margin-bottom: 10px;
    border-radius: 1rem;
    margin-right: 10px;
    border: 1px solid #002989;
    color: #002989;
    background-color: white;
    padding: 5px 6px;
  }

  .coupon-distance-margin:hover {
    background-color: #cddcf8;
  }

  .discount-selected-reason-color {
    background-color: #cddcf8;
  }

  @media (min-width: 768px) {
    .invoice-discount-boxes {
      flex-direction: row;
      gap: 4rem;
    }

    .invoice-discount-box-first {
      width: 60%;
    }

    .invoice-discount-box-second {
      width: 40%;
      margin-top: 1.8rem;
      margin-right: 2rem;
    }
  }

  .restaurant-invoice-discount-error-message {
    color: red;
    font-size: 0.875em;
    margin-top: 5px;
    margin-left: 15px;
  }
  