.sale-temp-setting-main-container {
  display: flex;
  flex-direction: column;
  height: 82vh;
  background-color: #fff;
  padding: 2rem;
  border-radius: 15px;
  overflow: auto;
}

.sale-quote-temp-group-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;
}

.sale-quote-temp-left-part {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 25%;
}

.sale-quote-temp-left-part span {
  font-size: medium;
  font-weight: bold;
  margin-bottom: 10px;
  word-wrap: break-word;
  width: 80%;
}

.sale-quote-temp-left-part button {
  padding: 10px 20px;
  background-color: #002989;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.sale-quote-temp-right-part {
  flex: 1;
}

.templates-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.template-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 40vh;
}

.template-card img {
  width: 100%;
  max-width: 180px;
  height: 30vh;
  margin-bottom: 10px;
  border-radius: 4px;
}

.template-card button {
  padding: 8px 16px;
  background-color: #ccc;
  color: #333;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.template-card:hover{
  cursor: pointer;
}

.template-card.selected .sale-temp-check-icon {
  display: block; /* Display only when the card is selected */
}

.template-box-popup-container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  z-index: 1000;
}

.template-box-sub-popup-container {
  overflow-y: auto;
  height: 95vh;
}

span.template-name-title {
  font-style: italic;
  color: gray;
  display: flex;
  justify-content: center;
}

svg.svg-inline--fa.fa-circle-check.sale-temp-check-icon {
  transform: translate(-10%, -50%);
  margin-top: -8rem;
  color: #002989;
  font-size: xx-large;
}
