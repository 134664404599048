.numeric-keypad {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  margin-top: -3rem;
}

.keypad-button {
  font-size: 14px;
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: grey;
  cursor: pointer;
}

.enter-button {
  grid-column: span 3;
  background-color: grey;
  color: white;
}
