.kot-transaction-order-main {
  width: 50%;
  height: auto;
}

.transaction-kot-header {
  width: 100%;
  background-color: #002989 !important;
  height: 3rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}

.kot-trans-header-title {
  font-size: larger;
  font-weight: bold;
}

.trans-kot-sub-part {
  background-color: white;
  height: auto;
  padding: 20px;
}

.order-items-table {
  overflow-y: auto;
  max-height: 250px;
}

.kot-transaction-grand-total{
  border: 1px solid #474DDC;
  border-radius: 8px;
  padding: 15px;
  margin-top: 1rem;
}

span.trans-grand-total-value {
  float: right;
  margin-right: 1rem;
}

span.kot-trans-group-total-value {
  float: right;
  margin-right: 2rem;
}

.order-group {
  margin-top: 1rem;
}

.kot-trans-group-order-container {
  background-color: #002989;
  margin-bottom: 1rem;
  line-height: 2rem;
  color: white;
}

span.kot-trans-order-group-title {
  font-size: large;
  margin-left: 0.5rem;
}

.kot-trans-group-total-amount{
  border: 1px solid #474DDC;
  border-radius: 8px;
  padding: 15px;
  margin-top: 1rem;
}

.kot-trans-sub-second {
  overflow-y: auto;
  max-height: 450px;
}
