.order-container {
	padding: 20px;
}
  
.table-grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
}
  
.search-options {
	display: flex;
}
  
.search-options button {
	padding: 8px 16px;
	color: #090909;
	border: 1px solid #ccc;
	border-radius: 50px;
	cursor: pointer;
	margin-right: 10px;
}
  
.temp-tables {
	margin-left: auto;
	display: flex;
	justify-content: center;
	align-items: center;
}
  
.temp-tables span {
	font-weight: bold;
	margin-right: 10px;
}
  
.search-bar {
	display: flex;
	align-items: center;
	padding: 10px;
	background-color: #f0f0f0;
	border-radius: 4px;
}
  
.search-bar input {
	margin-right: 10px;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 4px;
}
  
.button-create {
	background-color: rgb(43, 139, 208);
	color: white;
	width: 67px;
	padding: 8px;
	margin-left: 6px;
	border: none;
}

.floor_container{
	flex: 1;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.floor_data{
	flex: 4;
	display: flex;
	flex-wrap: wrap;
	gap: 1%;
	align-items: center;
	align-self: center;
	padding-bottom: 1rem;
}

.tables_count{
	flex: 2;
	font-size: 20px;
	font-weight: 700;
	display: flex;
	justify-content: center;
	align-items: center;

}

.search_tables{
	flex: 1;
}

.create_table {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    background-color: #002989;
    color: #f0f0f0;
    margin-left: 10px;
    border: 10px;
    width: 150px;
    height: 38px;
}

.restaurant-table-group {
    overflow-y: auto;
    max-height: 650px;
}

.tables-container {
    margin-top: -1%;
}

button.btn.btn-light.restaurant-floor-buttons {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: white;
}
