.container-fluid {
  background-color: white;
  border-radius: 5px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #002989;
  color: white;
  /* border-top-left-radius: 5px;
  border-top-right-radius: 5px; */
}

.merge-close-button {
  background: none;
  border: none;
  color: #ccc;
  font-size: 34px;
  cursor: pointer;
}

.merge-close-button:hover{
  color: #fff;
}

.create-table-form {
  position: relative;
}

.close-button-btn {
  position: absolute;
  top: 0px;
  right: 17px;
  font-size: 34px;
  background: none;
  border: none;
  cursor: pointer;
}
  