.expanse-bill-content-main{
  display: flex;
  flex-direction: column;
  height: auto;
  background-color: #fff;
  padding: 17px;
  border-radius: 15px;
  overflow: hidden;
}

.expanse-table-content{
  background-color: white;
  border-radius: 15px;
  padding: 17px;
}

.fixed-add-expense {
  position: fixed;
  top: 20px;
  right: 34px;
  z-index: 1000;
}

.add-salary-expense {
  margin-right: 8px;
}

/* .fixed-add-expense .item-btn {
  background-color: #002989;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-top: 60%;
} */
