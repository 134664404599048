.item_label {
  color: #002989;
  font-weight: 600;
  font-size: 24px;
}

.bar-code-content {
  height: 50px;
  width: 150px;
}

.item-bar-code-value {
  justify-content: flex-start;
  display: flex;
  padding-left: 5rem;
}

.stock-add-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.add-stock-setting-close-sign {
  height: 3rem;
  margin-top: 0rem;
}


/* Add these styles to your item-details.css file */

.audit-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.audit-modal {
  background-color: white;
  border-radius: 8px;
  width: 80%;
  max-width: 1200px;
  max-height: 80vh;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.audit-modal-header {
  padding: 1rem;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.audit-modal-header h5 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
}

.close-button {
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;
  transition: color 0.2s;
  margin-top: 233px;
  margin-right: 317px;
}

.close-button:hover {
  color: #000;
}

.audit-modal-body {
  padding: 1rem;
  overflow-y: auto;
  max-height: calc(80vh - 4rem);
}

/* Style for the "Show Audit Details" link */
.text-primary {
  color: #007bff;
  text-decoration: none;
}

.cursor-pointer {
  cursor: pointer;
}

.hover\:underline:hover {
  text-decoration: underline;
}

span.audit-model-left {
  font-size: larger;
  font-weight: bold;
}

span.audit-model-right{
  font-size: larger;
  font-weight: bold;
}

.card-body-first-div {
  display: flex;
  justify-content: space-between;
}

/* --------------------- */

.stock-history-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.stock-history-modal {
  background: white;
  border-radius: 8px;
  width: 80%;
  max-width: 900px;
  max-height: 80vh;
  overflow-y: auto;
}

.stock-history-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #ddd;
}

.stock-history-modal-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.stock-history-modal-close {
  cursor: pointer;
  font-size: 1.5rem;
  padding: 0.5rem;
}

.stock-history-modal-body {
  padding: 1rem;
}

.history-icon {
  color: #6c757d;
  transition: color 0.2s;
}

.history-icon:hover {
  color: #495057;
}

.stock-variant-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.stock-variant-modal-content {
  background: white;
  padding: 1rem;
  border-radius: 8px;
  width: auto;
  position: relative;
}
.stock-variant-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.stock-variant-detail-modal-body {
  margin-top: 2rem;
  overflow-y: auto;
  max-height: 250px;
}

svg.variant-printer-icon {
  color: #0d6efd;
}

