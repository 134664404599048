.bill-amount-content {
  margin-left: 1rem;
}

.bill-amount-content,
h3 {
  margin-right: 10px;
}

.title-title-transations {
  margin-bottom: 10px;
  border-radius: 1rem;
  margin-right: 10px;
  border: 2px solid #002989;
  color: #002989;
  background-color: white;
  padding: 5px 6px;
  font-size: large;
  font-weight: bold;
}

.title-title-transations.active{
  background-color: #cddcf8;
}

.title-title-transations:hover{
  background-color: #cddcf8;
}

.status-edit-icon {
  margin-left: 10px;
  color: #474DDC;
}

/* .box-transations.active {
  background-color: #cddcf8;
} */

.trans-new-record {
  display: flex;
  gap: 0.5rem;
  box-sizing: border-box;
  flex-wrap: wrap;
}

/* .box-transations {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #f9f9f9;
  width: 28%;
  height: 4rem;
  box-sizing: border-box;
} */

h3.title {
  font-family: 'Poppins', sans-serif;
  color: #3e429d !important;
  font-weight: 600;
  line-height: 1.5rem;
  display: contents;
}

.transaction-value {
  float: left;
}

.bill-search-attribute {
  margin-bottom: 1rem;
}

@media (max-width: 1200px) {
  .box-transations {
    width: 45%;
  }
}

@media (max-width: 768px) {
  .box-transations {
    width: 100%;
  }
}

.search-bill-by-status {
  margin-bottom: 1rem;
}
.billing-marigin-bottom {
  margin-bottom: 1.5rem;
}

.billing-search-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.payment-history-popup {
  position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.payment-histroy-content {
  background-color: #fff;
	padding: 20px;
	border-radius: 8px;
	width: 600px;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
	position: relative;
}

.payment-history-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.payment-mode-select {
  position: relative;
  margin-bottom: 15px;
}

.payment-histroy-actions {
	display: flex;
	justify-content: flex-end;
	gap: 10px;
}

.payment-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.payment-history-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1em;
  max-height: 400px;
  overflow: auto;
}

.payment-history-table th, .payment-history-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}
.payment-history-table th {
  background-color: #f4f4f4;
}
