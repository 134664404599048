.billing-preview-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.billing-preview-content {
  background-color: #fff;
  width: 55%;
  max-height: 95%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  overflow-y: auto;
}

.billing-preview-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 10px;
}

.billing-preview-body {
  padding: 20px 0;
}

.template-selection {
  margin-bottom: 20px;
}

.template-preview {
  display: flex;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  padding: 20px;
  background-color: #f0f0f0;
  width: 100%;
  height: 700px;
  max-height: 700px;
}

.box {
  height: 200px;
}

.receipt-box-container {
  flex: 0 0 80%;
  background-color: #ccc;
  margin-right: 10px;
  height: 100%;
  width: 100%;
}

.receipt-box-sub {
  flex: 0 0 20%;
  background-color: #ddd;
  height: 100%;
}

.template-preview::-webkit-scrollbar {
  display: none;
}

.billing-preview-footer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #eaeaea;
  padding-top: 10px;
}

.billing-btn-cancel,
.billing-btn-confirm {
  margin-left: 10px;
}

.image-collection {
  max-height: 100%;
  overflow-y: auto;
  border: 1px solid #eaeaea;
  padding: 10px;
  background-color: #ccc;
  overflow-x: auto;
}

.receipt-temp-container {
  max-height: 100%;
  overflow-y: auto;
  border: 1px solid #eaeaea;
  padding: 10px;
  background-color: white;
  overflow-x: hidden;
}

/* .box-img-align {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
} */

.box-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  margin-left: 2.rem;
  /* margin-top: 1rem; */
}

.image-collection::-webkit-scrollbar {
  width: 10px;
}

.image-collection::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

.image-collection::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.receipt-temp-container::-webkit-scrollbar {
  width: 10px;
}

.receipt-temp-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

.receipt-temp-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.box-img-align {
  position: relative;
  display: inline-block;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.checkmark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #474DDC;
  font-size: 2rem;
  z-index: 1;
}
.upi-qr-code {
  text-align: center;
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.upi-qr-code h4 {
  margin-bottom: 15px;
}

.upi-qr-code p {
  margin-top: 15px;
  font-weight: bold;
}