.admore-field {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .admore-field input[type="text"] {
    margin-right: 35px; /* Adjust spacing between input fields */
  }
  
  /* .deletebtn {
    margin-left: 240px;
  } */
  
  .btn-primary.ad-customer {
    margin-right: 370px; /* Adjust margin for the "Add Branch" button */
    margin-top: -66px;
  }

  
  /* Style for Cancel button */
.btn-cancel {
    background-color: #dc3545; /* Red background color */
    border-color: #dc3545; /* Red border color */
  }
  
  .btn-cancel:hover {
    background-color: #c82333; /* Darker red on hover */
    border-color: #c82333; /* Darker red border on hover */
  }
  
  /* Style for Submit button */
  .btn-submit {
    background-color: #007bff; /* Blue background color */
    border-color: #007bff; /* Blue border color */
  }
  
  .btn-submit:hover {
    background-color: #0056b3; /* Darker blue on hover */
    border-color: #0056b3; /* Darker blue border on hover */
  }

  .button-space {
    margin-right: 10px; /* Adjust the margin as needed */
  }
  