.container-content {
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 10px;
}

.date-selector-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.date-selector-container > div {
  display: flex;
  align-items: center;
}

.date-selector-container label {
  margin-right: 10px;
}

.date-selector-container button {
  margin-left: 10px;
  border: 0px;
}

.summary-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #e0e0e0;
  padding: 10px;
  border-radius: 5px;
}

.summary-container > div {
  align-items: center;
  justify-content: center;
}

.summary-container p {
  margin-right: 5px;
  margin-bottom: 0px;
}

.summary-container h3 {
  margin-right: 10px;
  font-weight: bold;
}

.summary-container .summary-item:last-child {
  margin-right: 0;
}

h3.purchase-value-with-rs {
  float: left;
  margin-left: 1rem;
  margin-top: 2rem;
  font-size: xx-large;
}

h3.receive-value-with-rs {
  float: left;
  margin-left: 0.5rem;
  margin-top: 2rem;
  font-size: xx-large;
}

h3.pay-value-with-rs {
  float: left;
  margin-left: 0.5rem;
  margin-top: 2rem;
  font-size: xx-large;
}

.expanse-fetch-total{
  float: left;
  margin-left: 1rem;
  margin-top: 2rem;
  font-size: xx-large;
}
