.online-order-sys-store-container {
  padding: 20px;
  background-color: white;
  height: auto;
  width: auto;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.online-order-store-heade-container {
  line-height: 1.5rem;
  background-color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  text-align: right;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}

.online-order-sys-breadcrumb {
  color: #6c757d;
}

.online-order-sys-add-new-store-button {
  background-color: #002989;
  color: white;
  padding: 5px 6px;
  border: none;
  cursor: pointer;
}

.online-order-sys-add-new-store-form {
  background-color: white;
  /* padding: 20px; */
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 550px;
  margin-top: 2rem;
}

.online-order-sys-store-form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.online-order-sys-store-form-group {
  flex: 0 0 48%;
}

.online-order-sys-form-label {
  display: block;
  margin-bottom: 5px;
}

.online-order-sys-input-text,
.online-order-sys-select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.online-order-sys-info-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: #007bff;
  color: white;
  text-align: center;
  border-radius: 50%;
  font-style: normal;
  margin-left: 5px;
}

.online-order-sys-store-form-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.online-order-sys-store-form-actions {
  margin-left: 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  gap: 1rem;
}

button.online-order-new-store-button {
  color: white;
  border: 1px solid #002989;
  border-radius: 5px;
  padding: 5px 6px;
  background-color: #002989;
}

/* .online-order-sys-store-form-actions button:first-child {
  background-color: #4caf50;
  color: white;
}

.online-order-sys-store-form-actions button:last-child {
  background-color: #f44336;
  color: white;
} */

.online-order-sys-form-group-hide-name {
  display: flex;
  gap: 5rem;
  justify-content: space-between;
}

.online-order-sys-form-hide-name-input {
  width: 1rem;
  height: 1rem;
}

.online-store-details {
  margin-top: 2rem;
  overflow-y: auto;
  max-height: 800px;
}

span.plat-item-details-title {
  margin-left: 1rem;
  font-size: larger;
  font-weight: bold;
  color: #002989;
}

.online-order-sys-store-form-group.active-new-store-form-checkox {
  display: flex;
  justify-content: space-between;
}

.online-active-new-store-form-checkox {
  flex: 0 0 48%;
  display: flex;
  justify-content: space-between;
}

.online-order-sys-enable-order {
  display: flex;
  justify-content: space-between;
}

.online-order-sys-enable-order {
  display: flex;
  justify-content: space-between;
  flex: 0 0 48%;
}

.online-new-store-header-container {
  width: 100%;
  background-color: #002989 !important;
  height: 3rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

span.online-new-store-header-title {
  margin-left: 0.5rem;
}

span.online-store-reference-id {
  margin-left: 0.5rem;
  font-size: larger;
  font-weight: bold;
}

.online-store-reference-grp {
  margin-top: 1rem;
}

.online-store-new-form-grp {
  padding: 2rem;
}
