.unit-popup-main-container {
  width: auto;
  height: auto;
  background-color: white;
}

.unit-popup-title-container {
  width: 100%;
  background-color: #002989 !important;
  height: 3rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}

.unit-popup-header-title {
  font-size: larger;
  font-weight: bold;
}

.unit-popup-sub-container {
  padding: 10px;
}

.unit-popup-input-box {
  display: flex;
}

label.unit-label-title {
  width: 10rem;
  margin-top: 2%;
}

.unit-error-message {
  color: red;
  float: right;
  margin-right: 1rem;
}

.unit-popup-button-box {
  margin-top: 1rem;
  padding: 10px;
}

span.unit-popup-buttons {
  float: inline-end;
  margin-bottom: 1rem;
}

button.unit-popup-create-btn {
  border-radius: 5px;
  background-color: #002989;
  color: white;
  padding: 5px 6px;
  margin-right: 1rem;
}

button.unit-popup-close-btn {
  border-radius: 5px;
  background-color: #002989;
  color: white;
  padding: 5px 6px;
}
