.receipt-gen-main-container {
  width: 100vw;
  height: auto;
  max-width: 794px;
  margin: 0 auto;
  background-color: white;
  box-sizing: border-box;
  overflow: hidden;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

.receipt-gen-header-div {
  padding: 0.5rem;
  border: 1px solid;
  display: flex;
  justify-content: space-between;
  background-color: #002989;
  color: white;
}

span.receipt-gen-header-left-title {
  font-size: larger;
  font-weight: bold;
}

span.receipt-gen-header-right-title {
  font-size: larger;
  font-weight: bold;
}

.receipt-gen-temp-subcontainer-header{
  padding: 1rem;
}

.receipt-company-info-grp {
  text-align: center;
}

img.receipt-company-logo {
  height: 10%;
  width: 10%;
}

.receipt-some-other-info {
  text-align: center;
}

span.receipt-company-name {
  font-size: larger;
  font-weight: bold;
}

span.receipt-com-info-title {
  font-size: medium;
  font-weight: bold;
}

span.receipt-com-info-titles,
.receipt-other-info-titles {
  font-size: small;
  font-weight: bold;
}

.receipt-title-grp-container {
  text-align: center;
  font-weight: bold;
  font-size: medium;
}

.receipt-other-info {
  display: flex;
  flex-flow: column;
}

.receipt-gen-temp-horizontal-line {
  width: 100%;
  height: 1px;
  background-color: black;
  margin: 1rem 0;
}

.receipt-order-grp-container {
  display: flex;
  justify-content: space-between;
}

.receipt-order-grp {
  display: flex;
  flex-flow: column;
}

span.receipt-order-grp-titles {
  font-size: medium;
  font-weight: bold;
}

span.receipt-order-grp-values {
  font-size: small;
  font-weight: bold;
}

.receipt-order-grp-container {
  display: flex;
  justify-content: flex-start;
  gap: 5rem;
}

/* receipt general temp table*/
/* table.receipt-gen-temp-table {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
  border-bottom: 1px solid;
}

tr.receipt-gen-temp-tab-head-row,
.receipt-gen-temp-table-body-row {
  line-height: 0.4rem;
}

th.receipt-gen-temp-table-header-attribute {
  font-weight: bold;
  font-size: x-small;
}

.receipt-gen-temp-table th, .receipt-gen-temp-table td {
  border: 1px solid black;
  padding: 0.4rem;
  text-align: left;
  font-size: xx-small;
  font-weight: bold;
}

table.receipt-gen-temp-footer-table {
  border: 1px solid black;
  margin-top: -1rem;
}

.receipt-gen-temp-footer-data {
  display: flex;
  flex-flow: column;
  float: inline-end;
  width: 30%;
}

.receipt-gen-temp-footer-data-row {
  display: flex;
  justify-content: space-between;
  padding: 0rem 0;
}

span.receipt-gen-temp-footer-label {
  flex: 1 1;
  text-align: left;
  font-weight: bold;
  font-size: x-small;
}

span.receipt-gen-temp-footer-value {
  flex: 1 1;
  text-align: right;
  font-size: x-small;
  font-weight: bold;
}

.receipt-gen-temp-tax-details {
  font-size: 0.4rem;
  font-weight: bold;
  margin-bottom: 0.2rem;
}

span.receipt-gen-temp-extra-row-data {
  display: flex;
  justify-content: flex-end;
}

.receipt-gen-table-grp-container {
  margin-bottom: 1rem;
} */
/*  */

.receipt-cust-first-data {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

span.receipt-cust-first-left-title,
.receipt-cust-second-title,
.receipt-cust-third-title {
  font-size: small;
  width: 40%;
}

.receipt-cust-first-right-title {
  display: flex;
  flex-flow: column;
  width: 60%;
}

span.receipt-cust-com-name {
  font-size: small;
  font-weight: bold;
}

span.receipt-cust-com-other-info {
  font-size: small;
}

.receipt-cust-second-data,
.receipt-cust-third-data,
.receipt-cust-fourth-data {
  display: flex;
  gap: 1rem;
}

span.receipt-cust-second-value {
  width: 60%;
  font-size: small;
  font-weight: bold;
}

span.receipt-cust-third-value {
  width: 60%;
  font-size: small;
}

.receipt-gen-temp-signature-section {
  padding: 1rem;
  margin-top: 1rem;
  border: 1px solid black;
}

.receipt-gen-signature-first-div {
  display: flex;
  justify-content: space-between;
}

.receipt-gen-signature-second-div {
  display: flex;
  justify-content: space-between;
  margin-top: 5rem;
}

span.receipt-gen-temp-first-title {
  font-size: small;
  font-weight: bold;
}

.receipt-gen-signature-first-part {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
}

.receipt-gen-signature-second-part {
  display: flex;
  justify-content: flex-end;
}

.receipt-cust-msg {
  text-align: center;
  font-size: small;
  margin-top: 1rem;
}

.receipt-gen-header-right-title{
  cursor: pointer;
}

@media print {
  .receipt-gen-header-div,
  .sale-receipt-main-container{
    display: none !important;
  }

  .receipt-gen-main-container {
    width: 100%;
    height: auto;
    max-width: 794px;
    margin: 0 auto;
    background-color: white;
    overflow: visible;
  }

  .receipt-gen-sub-container {
    padding: 1rem;
  }

  table.receipt-gen-temp-table {
    width: 100%;
    border-collapse: collapse;
    margin: 1rem 0;
    border: 1px solid black;
  }

  .receipt-gen-temp-table th {
    border: 1px solid black;
    padding: 0.4rem;
    text-align: left;
    font-size: xx-small;
    font-weight: bold;
    color: black !important;
    background-color: white !important;
  }

  tr.receipt-gen-temp-tab-head-row {
    border: 2px solid black;
  } 

  .receipt-gen-temp-signature-section,
  .receipt-gen-temp-footer-table,
  .receipt-gen-head-third-title-grp {
    page-break-inside: avoid;
  }

  .receipt-gen-temp-horizontal-line {
    border-top: 1px solid black;
    margin: 1rem 0;
  }
}
