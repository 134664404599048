.delivery-letter-pad-invoice-container {
  width: 100vw;
  height: calc(100vw * 1.414);
  max-width: 794px;
  max-height: 1123px;
  margin: 0 auto;
  padding: 2rem;
  background-color: white;
  box-sizing: border-box;
  overflow: hidden;
}

.delivery-letter-pad-invoice-bill-div {
  display: flex;
  flex-flow: column;
}

.delivery-letter-pad-invoice-billing-address {
  display: flex;
  justify-content: space-between;
}

.delivery-letter-pad-invoice-table {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
}

.delivery-letter-pad-invoice-table th,
.delivery-letter-pad-invoice-table td {
  border: 1px solid black;
  padding: 0.4rem;
  text-align: left;
  font-size: xx-small;
  font-weight: bold;
}

.delivery-letter-pad-invoice-table th {
  font-weight: bold;
  font-size: x-small;
}

tr.delivery-letter-pad-table-body-row {
  line-height: 0.4rem;
  border-bottom: 1px solid;
}

.footer-data-row {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;
}

.footer-label {
  flex: 1;
  text-align: left;
  font-weight: bold;
}

.footer-value {
  flex: 1;
  text-align: right;
}

span.delivery-letter-pad-extra-row-data {
  display: flex;
  justify-content: flex-end;
}

.delivery-letter-pad-invoice-footer-section {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}

.delivery-letter-pad-signature-section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 1rem;
  gap: 5rem;
  margin-top: 1rem;
  border: 1px solid black;
}

.delivery-challan-pad-button-group {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

.delivery-letter-pad-title-grp {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.delivery-letter-pad-title {
  font-size: medium;
  font-weight: bold;
}

.delivery-horizontal-line {
  width: 100%;
  height: 1px;
  background-color: black;
  margin: 0.4rem 0;
}

.delivery-order-no,
.delivery-order-date {
  display: flex;
  flex-flow: column;
}

.delivery-order-grp {
  display: flex;
  gap: 10rem;
}

span.delivery-order-no-value,
.delivery-order-date-value {
  font-weight: bold;
  font-size: small;
}

.delivery-letter-pad-tax-grp {
  display: flex;
  flex-flow: column;
  gap: 0.2rem;
}

span.delivery-letter-pad-tax-label {
  font-size: 0.4rem;
  font-weight: bold;
}

span.delivery-letter-pad-footer-title {
  font-size: small;
  font-weight: bold;
}

span.delivery-order-label {
  font-size: small;
}

span.delivery-letter-pad-bill-issued-data {
  font-size: small;
  font-weight: bold;
}

span.delivery-letter-pad-issue-title {
  font-weight: bold;
  font-size: medium;
}

img.delivery-letter-company-logo {
  height: 5rem;
  width: 100%;
  margin-top: 0.5rem;
}

.delivery-letter-com-grp {
  display: flex;
  justify-content: center;
  word-wrap: break-word;
}

.delivery-letter-branch-grp {
  display: flex;
  justify-content: center;
  word-wrap: break-word;
}

.delivery-letter-pad-com-info-container {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
}

.delivery-letter-img-grp {
  width: 18%;
  border: 2px solid black;
  border-radius: 0.5rem;
  height: 8rem;
  padding: 0.5rem;
}

.delivery-letter-com-info-grp {
  width: 82%;
  border: 2px solid black;
  border-radius: 0.5rem;
  padding: 1rem;
  height: 8rem;
}

span.delivery-letter-com-label {
  font-size: small;
  font-weight: bold;
}

span.delivery-letter-com-name-label {
  font-size: x-large;
  font-weight: bold;
}

span.delivery-letter-branch-label {
  font-size: medium;
  font-weight: bold;
}

.delivery-letter-header-com-data-grp {
  display: flex;
  justify-content: space-between;
}

span.delivery-letter-com-data-label {
  font-size: small;
  font-weight: bold;
}

/* footer css */
table.delivery-letter-pad-footer-table {
  border: 1px solid black;
  margin-top: -1rem;
}

.delivery-letter-pad-footer-data {
  display: flex;
  flex-flow: column;
  float: inline-end;
  width: 40%;
}

.delivery-letter-pad-footer-data-row {
  display: flex;
  justify-content: space-between;
  padding: 0rem 0;
}

span.delivery-letter-pad-footer-label {
  flex: 1 1;
  text-align: left;
  font-weight: bold;
  font-size: x-small;
}

span.delivery-letter-pad-footer-value {
  flex: 1 1;
  text-align: right;
  font-size: x-small;
  font-weight: bold;
}

button.delivery-button-icon {
  width: 2rem;
  font-size: larger;
  color: #474ddc;
  background-color: white;
  border: 2px solid;
}

@media print {
  @page {
    margin: 1mm; 
  }

  .delivery-challan-pad-button-group {
    display: none !important;
  }

  .delivery-letter-pad-invoice-container {
    width: 100%;
    height: auto;
    max-width: 150mm;
    max-height: 200mm;
    margin: 0 auto;
    padding: 1rem;
    box-sizing: border-box;
    overflow: hidden;
    background-color: white;
    page-break-inside: avoid; 
  }

  .delivery-letter-pad-invoice-header,
  .delivery-letter-pad-invoice-footer,
  .delivery-letter-pad-invoice-table {
    page-break-after: auto;
  }

  .delivery-letter-pad-invoice-table {
    width: 100%;
    border-collapse: collapse;
  }

  .delivery-letter-pad-invoice-table th,
  .delivery-letter-pad-invoice-table td {
    padding: 8px;
    border: 1px solid #000;
    text-align: left;
  }

  .delivery-letter-pad-footer-title {
    display: inline-block;
    margin-right: 1rem;
    font-weight: bold;
  }

  .footer-data-row {
    margin: 0.3rem 0;
  }
}
