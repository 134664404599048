.select-content {
    margin-bottom: 20px;
    position: relative;
    width: 25%;
  }
  
  .select-content select {
    width: 100%;
    height: auto;
    padding: 10px;
    padding-right: 40px; /* Space for the icon */
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: #fff;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  
  .staff-dropdown-icon {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: #333;
  }
  