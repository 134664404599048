.company-show-main-content {
  background-color: #fff;
  padding: 17px;
  border-radius: 15px;
  max-width: 100%;
  height: auto;
}

.new-content {
  max-height: 900px;
  overflow-y: scroll;
}

.qr-code-generation {
  margin-top: -6px;
}

.detail-item {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.detail-item strong {
  width: 200px;
}

.download-link {
  display: block;
  margin-top: 5px;
}

.bill-total {
  padding-right: 28px;
}

.btn {
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  display: block;
  margin-top: 20px;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
  border: none;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.branches-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.branch-card {
  flex: 1 1 calc(33.333% - 16px);
  box-sizing: border-box;
  transition: background-color 0.3s ease;
}

.branch-card:hover {
  background-color: #f0f0f0;
}

.branches-title {
  text-align: center;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.create-branch{
  margin-top: -21px;
  width: 110%;
  margin-left: -21px;
  overflow: clip;
}

.content {
  max-width: 100%;
}

.show-com-logo-size {
  max-width: 100%;
}

.btn-container {
  display: flex;
  gap: 10px;
}

.branches-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.branch-card {
  flex: 1 1 calc(33% - 20px);
  box-sizing: border-box;
}

.company-details {
  border-bottom: 1px dotted #000;
  padding-bottom: 20px;
}

.fixed-button {
  position: fixed;
  bottom: 20px;
  left: 70px;
  z-index: 1000;
}

.branch-edit {
  position: absolute;
  top: 10px;
  right: 20px;
  display: flex;
  gap: 10px;
}

.input-error {
  border-color: red !important;
}

.delete-icon {
  margin-top: -5px;
}

.form-group {
  margin-bottom: 20px;
}

input.form-control {
  padding: 8px;
  margin-bottom: 8px;
  margin-right: 11px;
}

img.logo-size {
  width: 100px;
  height: 100px;
  border-radius: 10rem;
}

.word-wrap {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 25ch;
}

.close_branch_model {
  background: none;
  border: none;
  font-size: 2rem;
  position: absolute;
  color: #fff;
  right: 0.5rem;
  top: 0.01;
}

.qr-code-size {
  max-width: 200px;
  max-height: 200px;
}

.com-sub-model {
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  color: #002989 !important;
  font-weight: 600;
  line-height: 33px;
  margin-right: 80px;
}

.select-language-drop-icon {
  margin-left: -12%;
}

.language-selection-field {
  margin-bottom: 1rem;
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  font-size: 1.2em;
}

.icon-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.custom-tooltip {
  font-size: 14px;
  padding: 5px 10px;
}

.com-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  line-height: 3rem;
  background-color: white;
  box-shadow: 0 0 10px rgb(27 25 25 / 10%);
}

.com-actions {
  display: flex;
  align-items: center;
}

.com-edit {
  margin-right: 2rem;
}

.com-edit a {
  cursor: pointer;
  color: #3e429d;
  font-size: x-large;
}

span#company_heading {
  font-size: x-large;
  font-weight: bold;
  color: #3e429d;
}

.company-show-sub-container {
  overflow-y: auto;
  max-height: 650px;
  margin-top: 1rem;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgb(27 25 25 / 10%);
  padding: 20px;
}

.show-company-details-section {
  margin-top: 1rem;
  background: white;
  box-shadow: 0 0 10px rgb(27 25 25 / 10%);
  margin-bottom: 1rem;
  padding: 20px;
  height: auto;
}

.company-branch-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  box-shadow: 0 0 10px rgb(27 25 25 / 10%);
  margin-bottom: 1rem;
  line-height: 4rem;
}

span.company-branch-title {
  margin-left: 1rem;
  font-size: x-large;
  font-weight: bold;
  color: #3e429d;
}

.company-branch-container-grp {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgb(27 25 25 / 10%);
  padding: 10px;
  overflow-y: auto;
  max-height: 500px;
}

.company-profile-time-group {
  display: flex;
  gap: 1rem;
}
