.kot-transaction-main {
  display: flex;
  flex-direction: column;
  height: 90vh;
  background-color: #fff;
  padding: 16px;
  border-radius: 15px;
  overflow: hidden;
}

.kot-transaction-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.kot-transaction-title {
  flex: 1;
  font-size: larger;
  font-weight: bold;
  color: #002989;
}

.transaction-kot-table-main {
  margin-top: 1rem;
  overflow-y: auto;
  max-height: 450px;
}

.kot-transaction-popup-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.order-transaction-show-icon{
  color: #0d6efd;
  font-size: larger;
  font-weight: bold;
}

.kot-transaction-header-container {
  line-height: 3rem;
  background-color: white;
  padding: 0.2rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center; 
}

span.kot-transaction-title-name {
  font-size: larger;
  font-weight: bold;
  margin-right: 1rem;
  color: #002989;
}

.kot-trans-first-row {
  display: flex;
  justify-content: space-around;
  margin-bottom: 0.5rem;
}

button.kot-void-way-btn {
  width: 16%;
  border: none;
  background-color: #002989;
  color: white;
  border-radius: 0.5rem;
  padding: 0.5rem 0.6rem;
}
