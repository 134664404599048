.invoice-template-second {
    position: relative;
    font-family: Arial, sans-serif;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 50%;
    margin: 0 auto;
    border-bottom: 12px solid #333333;
    border-top: 12px solid #9f181c;
}

.invoice-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.company-logo {
    width: 200px;
    border-radius: 50%;
    margin-right: 20px;
    height: 200px;
}

.company-info h2 {
    margin-bottom: 5px;
}

.invoice-details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.invoice-second-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.invoice-second-tableth,
.invoice-second-table td {
    border: 1px solid #ccc;
    padding: 8px;
}

.invoice-second-table th {
    background-color: #f2f2f2;
}

.invoice-total {
    text-align: right;
    margin-top: 20px;
    font-size: 18px;
}

.invoice-number{
    font-size: xx-large ;
}
