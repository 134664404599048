.common-supplier-main-container {
  height: auto;
  width: auto;
  background-color: white;
}

.common-supplier-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem;
  background-color: #fff;
  line-height: 1.5rem;
  display: flex;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, .1);
  transition: color .2s ease;
}

span.common-supplier-close {
  margin-right: 1rem;
  margin-top: 0.2rem;
}

.common-supplier-form-field-grp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.common-supplier-label {
  width: 30%;
}

input.form-control.common-supplier-label-field {
  width: 65%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.2rem;
}

textarea.form-control.common-supplier-label-textarea {
  width: 65%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.2rem;
}

.common-supplier-btn-grp {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-right: 1rem;
}

button.common-supplier-btn {
  border: none;
  border-radius: 0.2rem;
  padding: 0.2rem 0.4rem;
  color: white;
  background-color: #002989;
}

.common-supplier-sub-container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 1rem;
}

.common-supplier-form-grp {
  width: 50%;
  padding: 1rem;
  overflow-y: auto;
  max-height: 536px;
}

.common-supplierr-second-sub-container {
  width: 50%;
  padding: 1rem;
  position: relative;
}

.common-supplierr-second-sub-container::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: grey;
}

.common-supplier-tab-navigation {
  display: flex;
  justify-content: space-around;
  margin: 1rem 0;
  border-bottom: 1px solid #ccc;
}

.common-supplier-tab-navigation button {
  padding: 0.2rem 0.4rem;
  background: none;
  border: none;
  border-bottom: 1px solid transparent;
  cursor: pointer;
}

.common-supplier-tab-navigation button.active {
  border-bottom: 2px solid #002989;
  font-weight: bold;
  color: #002989;
}

/* address form css */
.supplier-address-form {
  border: 1px solid black;
  padding: 1rem;
}

.supplier-address-form-subcontainer {
  margin-top: 1rem;
}

.supplier-add-input-first-grp {
  display: flex;
  flex-flow: column;
  gap: 1rem;
}

.supplier-address-input-row-second {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.supplier-address-input-row-third {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
}

span.supplier-billing-title {
  font-weight: bold;
}

select.form-select.supplier-country-select {
  width: 50%;
}

/* shipping address form css */
.supplier-shipping-form {
  padding: 1rem;
  border: 1px solid black;
}

.supplier-shipping-form {
  padding: 1rem;
}

.supplier-shipping-input-row-second {
  display: flex;
  gap: 1rem;
}

.supplier-shipping-input-row-third {
  display: flex;
  gap: 1rem;
}

.supplier-shipping-form-subcontainer {
  margin-top: 1rem;
}

span.supplier-shipping-title {
  font-weight: bold;
}

/* bank detail form css */
.bank-details-form {
  border: 1px solid black;
  overflow-y: auto;
  max-height: 400px;
  padding-top: 1rem;
}

.supplier-bank-detail-form {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.supplier-bank-div-grp {
  display: flex;
  width: 100%;
  align-items: center;
}

.supplier-bank-div-grp span {
  width: 30%;
  margin-right: 1rem;
  text-align: right;
}

.supplier-input-div-field {
  width: 70%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
}

textarea.form-control.supplier-account-textarea {
  width: 70%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  height: 10rem;
}

input.form-control.supplier-input-div-field {
  height: 1%;
}

/* default form  css */
.supplier-default-div-grp {
  display: flex;
  flex-flow: column;
  gap: 1rem;
}

.supplier-default-second-div-grp {
  display: flex;
  flex-flow: column;
  margin-top: 1rem;
  gap: 1rem;
}

.supplier-default-form {
  border: 1px solid black;
  padding: 0.5rem;
}

textarea.form-control.supplier-default-account-textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  height: 6rem;
}
