.entries-control-label {
  margin-left: 0.2em;
}

.entry-per-page {
  display: flex;
  align-items: center;
}

.label-per-page {
  text-align: center;
  justify-content: center;
  margin-left: 5px;
}
