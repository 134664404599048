/* input.form-control.date-form-control {
  width: 51.5rem;
} */

svg.svg-inline--fa.fa-calendar-days.date-picker-icon {
  margin-right: 1rem;
}

.coupon-table-view {
  overflow-y: auto;
  max-height: 575px;
}

.added-hight-coupon {
  height: auto;
  max-height: 850px;
  overflow-y: scroll;
}

.coupon-dropdown-icon {
  position: absolute;
  top: 52%;
  right: 22px;
  transform: translateY(-50%);
  pointer-events: none;
  color: #555;
}

.valid-coupon-width {
  width: 52rem;
}

.coupon-valid-until > .react-datepicker-wrapper,  .coupon-valid-from > .react-datepicker-wrapper{
  width: 100%;
}
