.purchase-item-popup-main {
	background-color: #fff;
	padding: 20px;
	border-radius: 8px;
	position: relative;
	height: auto;
	width: 30rem;
}

.item-purchase-lp{
	display: flex;
	gap: 10px;
}

input.form-control.purchase-item-field {
	width: 60%;
}

.purchase-item-label {
  font-size: 16px;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-right: 15px;
	gap: 10px;
}

.purchase-item-radio-group {
  display: flex;
  align-items: center;
}

.item-purchase-radio-grp{
	display: flex;
}

.item-purchase-text-type{
	display: flex;
	gap: 2rem;
}

select.form-control.purchase-item-tax-select {
	width: 17rem;
	margin-right: 1rem;
	border: 1px solid #b9b9b9;
}

button.btn.btn-primary.cancel-button-purchase-item {
	margin-right: 10px;
}

.purchase-tax-button{
	text-align: right;
}

.pur-item-icon {
	position: absolute;
	margin-top: -2rem;
	margin-left: 15rem;
	color: #b9b9b9;
}

.custom-label {
  display: flex;
  flex-direction: column; 
}

