.invoice-template-third {
    position: relative;
    font-family: Arial, sans-serif;
    background-color: rgb(163, 163, 237); 
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 50%;
    margin: 0 auto;
    border-bottom: 12px solid #333333;
    border-top: 12px solid #9f181c;
}

.invoice-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.company-logo {
    width: 200px;
    border-radius: 50%;
    margin-right: 20px;
    height: 200px;
}

.third-template-company-info h2 {
    margin-bottom: 5px;
}

.third-template-invoice-details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.invoice-third-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.invoice-third-table th,
.invoice-third-table td {
    border: 1px solid #ccc;
    padding: 8px;
}

.third-template-invoice-total {
    text-align: right;
    margin-top: 20px;
    font-size: 18px;
}

.third-template-invoice-number {
    font-size: xx-large;
}

.template-third-footer-container {
    margin-top: 30px;
}

/* .watermark {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3em;
    opacity: 0.3;
    z-index: -1;
    justify-content: center;
} */

