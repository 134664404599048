.vertical-line {
  border-left: 2px dashed grey;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.tax-list-btn {
  background-color: #e3e3e3;
  color: #474DDC;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.arrow-icon {
  margin-left: 1px;
}

.circle-icon {
  display: inline-block;
  border-radius: 100%;
  border: 3px solid #3e429d;
  padding: 5px;

}

/* ==========Tax Rate Modal=========== */

.tax-group-div {
  display: flex;
  margin-bottom: 15spx;
  margin-top: 10px;
  margin-left: -28px;
  justify-content: space-around;
  font-size: 12px;
}

.taxes {
  margin-right: 65px;
}

/* =========ManagingTax========== */

.fill-crown {
  color: blue;
  font-size: 24px;
}

.gst-filing {
  color: #474DDC;
  margin-right: 5px;
  margin-right: 5px;
}

.react-select__menu {
  z-index: 9999 !important;
}

.info-circle {
  color: gray;
  margin-left: -2rem;
}

input#enable-gst {
  width: 22px;
  height: 1rem;
}

/* ============================== */

.taxrate-btn-container {
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 2px;
  padding: 2px 2px;
  background-color: #3e429d;
  border-radius: 0.6rem;
  text-decoration: none;
  color: white;
}

.tax-rate-header-add-button {
  display: flex;
}

.btn {
  display: flex;
  align-items: center;
}

/* =============================common css for rate and group ======== */

.tax-rates-row {
  margin-top: 10px;
  margin-right: 10px;
}

/* .tax-rate-scrollbar {
  height: 93vh;
  overflow-y: auto;
  overflow-x: hidden;
} */

.edit-trash-managing-tax {
  height: 15px;
  width: 15px;
  color: #474DDC;
  margin-left: 10px;
  cursor: pointer;
}

.edit-trash-delete-managing-tax {
  height: 15px;
  width: 15px;
  color: red;
  margin-left: 10px;
  cursor: pointer;
}

.modal {
  z-index: 10000 !important;
}


.tax-rate-group-font {
  width: 7rem;
  font-size: 17px;
}

/* ***********==========*************/

.input-b-color {
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-b-color:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.input-b-color::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.input-b-color::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.input-b-color:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.input-b-color::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.input-b-color::placeholder {
  color: #6c757d;
  opacity: 1;
}

.input-b-color:disabled,
.input-b-color[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.input-b-color {
  color: grey;
}


/* ----------------other css-------------- */

.gst-settings-container {
  background-color: #fff;
  padding: 17px;
  border-radius: 15px;
  height: 82vh;
  margin-left: 1%;
}

.gst-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  height: 100%;
  gap: 20px;
}

.box {
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 5px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 75vh;
}

.taxrate-sticky1-header {
  margin-bottom: 10px;
}

hr.hr-short {
  margin-top: 1rem;
}

.gst-setting {
  display: flex;
  align-items: center;
}

.gst-tax-header {
  font-size: x-large;
  color: #3e429d;
  font-weight: bold;
  margin-top: 10px;
}
