.quote-general-main-container {
  width: 100vw;
  height: auto;
  max-width: 794px;
  margin: 0 auto;
  background-color: white;
  box-sizing: border-box;
  overflow: hidden;
  /* box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2); */
}

.quote-gen-sub-container {
  padding: 1rem;
}

.quote-header-div {
  padding: 0.5rem;
  border: 1px solid;
  display: flex;
  justify-content: space-between;
  background-color: #002989;
  color: white;
}

span.quote-header-left-title {
  font-size: larger;
  font-weight: bold;
}

span.quote-header-right-title {
  font-size: larger;
  font-weight: bold;
}

.quote-gen-subcontainer-header {
  display: flex;
  width: 100%;
}

img.quote-template-company-logo {
  height: 50%;
  width: 80%;
}

.quote-gen-head-second-div {
  flex: 0 0 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  word-wrap: break-word;
}

span.quote-gen-head-com-title {
  font-size: x-large;
  font-weight: bold;
  font-family: "Times New Roman", Times, serif;
}

span.quote-gen-head-branch-title {
  font-size: medium;
  font-weight: bold;
}

span.quote-gen-head-other-titles {
  font-size: small;
  font-family: "Times New Roman", Times, serif;
}

span.quote-gen-cust-sub-titles {
  width: 100%;
  word-wrap: break-word;
}

span.quote-gen-head-third-sub-title,
span.quote-gen-cust-sub-titles,
span.quote-gen-order-value {
  font-size: small;
  font-weight: bold;
  font-family: "Times New Roman", Times, serif;
}

span.quote-gen-order-no-title,
span.quote-gen-order-date-title {
  font-size: medium;
  font-family: "Times New Roman", Times, serif;
}

span.quote-gen-cust-title {
  font-size: medium;
  font-weight: bold;
  font-family: "Times New Roman", Times, serif;
}

.quote-gen-head-third-div {
  flex: 0 0 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.quote-gen-head-third-title {
  font-family: "Times New Roman", Times, serif;
}

.quote-gen-head-third-sub-title-grp {
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
  margin-top: 1rem;
}

.quote-gen-head-third-title-grp {
  width: 100%;
  background-color: #002989;
  color: white;
  padding: 0.5rem;
  text-align: center;
}

.quote-general-cust-info-grp {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.quote-gen-cust-info-div {
  display: flex;
  flex-flow: column;
}

.quote-gen-order-info-grp {
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
}

.quote-gen-order-info {
  display: flex;
  flex-flow: column;
}

.quote-gen-temp-horizontal-line {
  width: 100%;
  height: 0.5px;
  background-color: black;
  margin: 1rem 0;
}

/* quote temp table */
table.quote-gen-temp-table {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
  border-bottom: 1px solid;
}

tr.quote-gen-temp-tab-head-row,
.quote-gen-temp-table-body-row {
  line-height: 0.4rem;
}

th.quote-gen-temp-table-header-attribute {
  font-weight: bold;
  font-size: x-small;
  font-family: 'Times New Roman', Times, serif;
}

.quote-gen-temp-table th,
.quote-gen-temp-table td {
  border: 1px solid black;
  padding: 0.4rem;
  text-align: left;
  font-size: xx-small;
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
}

table.quote-gen-temp-footer-table {
  border: 1px solid black;
  margin-top: -1rem;
}

.quote-gen-temp-footer-data {
  display: flex;
  flex-flow: column;
  float: inline-end;
  width: 30%;
}

.quote-gen-temp-footer-data-row {
  display: flex;
  justify-content: space-between;
  padding: 0rem 0;
}

span.quote-gen-temp-footer-label {
  flex: 1 1;
  text-align: left;
  font-weight: bold;
  font-size: x-small;
  font-family: 'Times New Roman', Times, serif;
}

span.quote-gen-temp-footer-value {
  flex: 1 1;
  text-align: right;
  font-size: x-small;
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
}

.quote-gen-temp-table-body-data {
  font-family: 'Times New Roman', Times, serif;
}

.quote-gen-temp-tax-details {
  font-size: 0.4rem;
  font-weight: bold;
  margin-bottom: 0.2rem;
  font-family: 'Times New Roman', Times, serif;
}

span.quote-gen-temp-extra-row-data {
  display: flex;
  justify-content: flex-end;
  font-family: 'Times New Roman', Times, serif;
}

/* footer signature grp */
.quote-gen-temp-signature-section {
  padding: 1rem;
  margin-top: 1rem;
  border: 1px solid black;
}

.quote-gen-signature-first-div {
  display: flex;
  justify-content: space-between;
}

.quote-gen-signature-second-div {
  display: flex;
  justify-content: space-between;
  margin-top: 5rem;
}

span.quote-gen-temp-first-title {
  font-size: small;
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
}

.quote-gen-signature-first-part {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
}

.quote-gen-signature-second-part {
  display: flex;
  justify-content: flex-end;
}

.quote-header-right-title {
  cursor: pointer;
}

@media print {
  .quote-header-div,
  .sidebar {
    display: none !important;
  }

  .quote-general-main-container {
    font-family: 'Poppins', sans-serif;
    width: 100%;
    height: auto;
    max-width: 794px;
    margin: 0 auto;
    background-color: white;
    overflow: visible;
  }

  .quote-gen-sub-container {
    padding: 1rem;
    margin-top: 2.5rem;
    margin-left: 2.5rem;
  }

  .quote-gen-head-first-div {
    margin-bottom: 1rem;
  }

  table.quote-gen-temp-table {
    width: 100%;
    border-collapse: collapse;
    margin: 1rem 0;
    border: 1px solid black;
  }

  .quote-gen-temp-table th {
    border: 1px solid black;
    padding: 0.4rem;
    text-align: left;
    font-size: xx-small;
    font-weight: bold;
    color: black !important;
    background-color: white !important;
  }

  tr.quote-gen-temp-tab-head-row {
    border: 2px solid black;
  } 

  .quote-gen-head-third-title-grp{
    font-weight: bold;
    color: black;
    font-size: medium;
  }

  .quote-gen-temp-signature-section,
  .quote-gen-temp-footer-table,
  .quote-gen-head-third-title-grp {
    page-break-inside: avoid;
  }

  .quatation-table-group,
  .copyrights, .quotation-main-container {
    display: none;
  }

  .quote-gen-temp-horizontal-line {
    border: 0.5px solid black;
  }
}
