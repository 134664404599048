.template-one-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.template-one-first-main {
  background-color: #fff;
  padding: 20px;
  width: 100%;
}

.template-one-header {
  text-align: center;
  margin-bottom: 20px;
}

.template-one-sub {
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
  font-size: small;
}

.template-one-date,
.template-one-time {
  margin-bottom: 10px;
}

hr,
.hr-dotted {
  border-top: 3px dotted #888;
}

.table-header-dotted {
  border-top: 3px dotted #888;
  border-bottom: 3px dotted #888;
}

.template-one-date-time {
  display: flex;
  justify-content: space-between;
}

.template-one-right {
  margin-right: -300px;
}

span.company-title {
  text-align: center;
  margin-top: 10px;
}

.template-one-container {
  display: flex;
  align-items: flex-start;
}

.first-template-one-policy {
  font-size: larger;
  text-decoration: underline;
  margin-right: 19rem;
}

.template-one-first-table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 2rem;
}

.template-one-first-table th,
.template-one-first-table td {
  text-align: left;
  padding: 8px;
}

.template-one-first-table th:first-child,
.template-one-first-table td:first-child {
  padding-left: 16px;
}

.template-one-first-table th:last-child,
.template-one-first-table td:last-child {
  padding-right: 16px;
}

span.first-template-one-policy-content {
  margin-right: 7rem;
}

img.receipt-first-logo-1 {
  width: 8rem;
  height: 3rem;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-bottom: 30px;
}

span.first-receipt-policy-content {
  float: left;
}

@media print {
  .hide-on-print {
    display: none;
  }

  .template-one-main {
    background-color: #fff;
    padding: 20px;
    width: 100%;
  }
}
@media print {
  @page {
    size: A4 portrait; /* Ensure A4 size is set and in portrait mode */
    margin: 0; /* Set the margin to 0 to use full space */
  }

  body {
    margin: 0; /* Ensure no margin in body */
  }

  .template-one-wrapper {
    display: flex; /* Use flexbox to center content horizontally */
    justify-content: center; /* Center content horizontally */
    width: 100%; /* Full width */
  }

  .template-one-first-main {
    font-size: 10px; /* Font size */
    line-height: 1.2; 
    max-width: 210mm; /* A4 Width */
    margin: 20px 0; /* Add margin for spacing from the top */
    padding: 0 10mm; /* Use padding instead of margin for horizontal space */
    box-sizing: border-box; /* This makes sure padding is included in total width */
  }

  img.receipt-first-logo-1 {
    width: 7rem; /* Adjust logo size to fit better on print */
    height: auto;
    margin: 0 auto; /* Center the logo */
  }

  .template-one-header,
  .template-one-date-time,
  .template-one-first-table,
  .template-one-summary-table,
  .template-one-footer {
    width: 100%; /* Make sure all elements are at full width */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    margin: 0; /* Remove margin that could affect centering */
    padding: 0; /* Remove padding that could affect centering */
  }

  .template-one-first-table th,
  .template-one-first-table td,
  .template-one-summary-table td {
    padding: 6px; /* Adjusted padding to fit */
    font-size: 9px; /* Smaller font size for print */
  }

  .tax-font-size {
    font-size: 8px; /* Tax details smaller */
  }

  .template-one-footer {
    margin-top: 10px; /* Add minor spacing above the footer */
    font-size: 9px; /* Footer font size */
  }

  /* Remove extra space at the top */
}
.template-one-first-main {
  font-size: 10px;
  line-height: 1.2;
  max-width: 210mm;
  margin: 0 auto;
}

.template-one-header {
  margin-bottom: 10px;
}

.receipt-first-logo-1 {
  width: 60px;
  height: auto;
  margin-bottom: 5px;
}

.company-title {
  font-size: 14px;
  font-weight: bold;
}

.template-one-company-address,
.template-one-com-mail {
  font-size: 9px;
  margin-bottom: 2px;
}

.template-one-sub-header {
  font-size: 12px;
  margin: 5px 0;
}

.template-one-date-time {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.template-one-first-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
}

.template-one-first-table th,
.template-one-first-table td {
  border: 1px solid #ddd;
  padding: 3px;
  text-align: left;
}

.template-one-first-table th {
  background-color: #f2f2f2;
}

.tax-font-size {
  font-size: 8px;
}

.template-one-summary-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
}

.template-one-summary-table td {
  padding: 2px;
}

.template-one-summary-table td:first-child {
  text-align: right;
  font-weight: bold;
}

.template-one-footer {
  margin-top: 10px;
  font-size: 9px;
}

.template-one-policy {
  font-weight: bold;
  margin-bottom: 3px;
}

@media screen {
  .template-one-first-main {
    border: 1px solid #ddd;
    padding: 20px;
    margin: 20px auto;
  }
}