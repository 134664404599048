.new-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.new-modal-content {
  background: white;
  padding: 5rem;
  border-radius: 5px;
  overflow-y: auto;
  position: relative;
  width: auto;
  height: auto;
  margin-bottom: 1rem;
}

.new-close-button {
  position: absolute;
  top: 13px;
  right: 9px;
  cursor: pointer;
}

.new-modal-body {
  max-height: 60vh;
  margin-top: -7%;
}

.new-close-modal {
  display: none;
}

.new-kot-section {
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.kot_table_header,
.kot_table_data {
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd;
}

.new-button-group {
  display: flex;
  gap: 1rem;
}

button.new-void-button {
  line-height: 2rem;
  padding: 5px 6px;
  border-radius: 5px;
  background-color: #002989;
  color: white;
  border: 1px solid #002989;
}

button.new-void-button:hover{
  opacity: 0.8;
}

button.new-transfer-button {
  line-height: 2rem;
  padding: 5px 6px;
  border-radius: 5px;
  background-color: #002989;
  color: white;
  border: 1px solid #002989;
}

button.new-transfer-button:hover{
  opacity: 0.8;
}

.new-items-section,
.new-tables-section {
  flex: 1;
  margin-right: 20px;
}

.new-items-section {
  max-width: 50%;
}

.new-tables-section {
  display: flex;
  flex-direction: column;
}

.new-table-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.new-table-list button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
}

.new-table-list button.selected {
  background-color: #218838;
}

.new-transfer-button {
  background-color: #ffc107;
  color: black;
  margin-right: 10px;
}

.new-transfer-container-heading-container {
  width: 100%;
  background-color: #002989 !important;
  height: 3rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}

.new-transfer-kot-title-heading {
  font-size: larger;
  font-weight: bold;
}

.new-transfer-kot-cross-icon {
  font-size: larger;
  font-weight: bold;
  cursor: pointer;
}

.new-item-tables-section-transfer-kot {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

.kot-detail-table-header {
  color: white;
  margin-bottom: 10px;
  background-color: #002989;
}

span.kot-detail-title-header {
  font-size: xx-large;
  margin-left: 10px;
}

span.order-kot-date-and-time {
  margin-left: 10px;
  font-size: larger;
}

span.comman-kot-table-close {
  float: right;
  margin-right: 10px;
  margin-top: 8px;
}

.contant-kot-trans {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  max-width: 885px;
  width: 100%;
}

.kot-transfer-item {
  background-color: #002989 !important;
  line-height: 3rem;
  color: white;
  border: 1px solid;
}

span.transfer-kot-title-header {
  margin-left: 10px;
  font-size: xx-large;
  font-weight: bold;
  margin-bottom: 10px;
  gap: 10px;
}

.close-button {
  position: absolute;
  top: 13px;
  right: 9px;
  cursor: pointer;
}

span.transfer-kot-close-button {
  float: right;
  margin-right: 10px;
}

.kot-transfer-table-details-group {
  display: flex;
  gap: 10px;
}

.item-tables-section-transfer-kot {
  width: 50%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  border-radius: 10px;
  background-color: whitesmoke;
}

.tranfer-table-kot-first-face {
  width: 50%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  border-radius: 10px;
  background-color: whitesmoke;
}

.transfer-item-table-list {
  overflow-y: auto;
  max-height: 800px;
}

.items-section {
  overflow-y: auto;
  max-height: 450px;
}

.kot-info-sub {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  background-color: #002989;
  color: white;
  line-height: 2rem;
  width: auto;
  padding: 2px 6px;
}

.button-group {
  display: flex;
  gap: 10px;
}

.large-model-content {
  /* border: 1px solid; */
  height: auto;
  margin-bottom: 1rem;
  background-color: whitesmoke;
  width: 70%;
}

.last-line {
  margin-top: -16px;
}

span.floor-name-header-transfer-items {
  font-size: larger;
  color: white;
  margin-left: 10px;
}


.transfer-item-large-model-content {
  border: 1px solid;
  height: 15rem;
  margin-bottom: 1rem;
  background-color: white;
  overflow: hidden;
}

.scrollable-tables-list {
  height: calc(100% - 40px);
  overflow-y: auto;
}

.scrollable-transfer-item-tables-list {
  overflow-y: auto;
  max-height: 200px;
}

button#tranfer-kot-button-style {
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: orange;
  line-height: 2rem;
  border-radius: 10px;
  padding: 5px 6px;
  border: 2px solid orange;
  color: white;
}

.void-transfer-button-kot-details{
  display: flex;
}

.no-kot-details {
  margin-left: 4%;
}

.kot-section {
  width: 100%;
  margin-bottom: 3rem;
}

button.item-void-button {
  border-radius: 10px;
  padding: 0px 5px;
  border: 1px solid white;
  margin-right: 1rem;
}

button.button-kot-transfer{
  border-radius: 10px;
  padding: 0px 5px;
  border: 1px solid white;
}

.kot-details-table-main {
  overflow-y: auto;
  max-height: 350px;
}

span.tranfer-table-item-total {
  float: right;
  margin-right: 2rem;
  margin-bottom: 1rem;
}

.transfer-select-table-title {
  padding: 0.5rem;
  font-size: larger;
  font-weight: bold;
}

.tables-section {
  background-color: whitesmoke;
}

.tranfer-table-floor {
  border: 1px solid;
  background-color: #002989;
  margin-left: 0.5rem;
}

.show-transfer-table-kot-items {
  margin-top: 0.5rem;
}

.transfer-select-table-header {
  border: 1px solid;
  line-height: 2.5rem;
  margin-bottom: 0.5rem;
  background-color: #002989;
  color: white;
}
