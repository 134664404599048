.purchase-receipt-main-container {
  display: flex;
  flex-direction: column;
  height: 80vh;
  background-color: white;
  padding: 0.8rem;
  border-radius: 1rem;
  margin-top: 1rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 480px;
  height: 500px;
}

.purchase-receipt-container-first-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.purchase-receipt-first-left {
  display: flex;
  align-items: center;
}

.purchase-receipt-first-right {
  display: flex;
  justify-content: flex-end;
}

button.purchase-receipt-first-right-botton {
  display: flex;
  align-items: center;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  background-color: #002989;
  gap: 1rem;
}

span.all-purchase-receipt-show {
  color: #002989;
  font-size: x-large;
  font-weight: bold;
}

.new-purchase-receipt-popup-container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  z-index: 1000
}

/* Table group */
.purchase-receipt-table-group {
  border-bottom: 1px solid #ccc;
  margin-top: 1rem;
}

.purchase-receipt-table {
  width: 100%;
  border-collapse: collapse;
}

.purchase-receipt-thead-row th {
  padding: 0.4rem;
  border-bottom: 1px solid #ccc;
  text-align: left;
  border: 1px solid #ccc;
  font-size: small;
  font-weight: bold;
}

.purchase-receipt-table-body td {
  padding: 0.2rem;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  text-align: left;
  font-size: small;
}

.purchase-receipt-tab-td {
  padding: 0.2rem;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  text-align: left;
  font-size: small;
}

.purchase-receipt-down-icon-action {
  position: absolute;
  top: 19rem;
  right: 1rem;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
  width: 15%;
}

ul.purchase-receipt-down-icon-action-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li.purchase-receipt-down-icon-action-li {
  padding-left: 0.5rem;
  font-size: small;
}

li.purchase-receipt-down-icon-action-li:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}
