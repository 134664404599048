.tax-rate-popup-contentainer{
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  /* max-width: 30%; */
  width: 100%;
}

.tax-rate-s {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -0.5rem;
}

.tax-modal{
  margin-top: -20px;
  margin-left: -20px;
  width: 112%;
}

.tax-footer{
  margin-left: -20px;
  margin-bottom: -20px;
  width: 110%;
}

.tax-head {
  font-size: x-large;
  color: #3e429d;
  font-weight: bold;
  margin-top: 1rem;
}

.button-rate {
  display: flex;
  align-items: center;
}

.tax-rate-plus-icon {
  margin-right: 0.5rem;
}

.tax-rate-div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
}

.tax-rate-value {
  margin-top: -3px;
  margin-right: 7px;
}

.tax-error {
  color: red;
  font-size: small;
  margin-left: 7.2rem;
}

.tax-rate-scroll.mt-3 {
  overflow-y: auto; 
  overflow-x: hidden; 
  height: 60vh; 
}

.tax-rate-scroll.mt-3::-webkit-scrollbar {
  width: 8px;
}

.tax-rate-scroll.mt-3::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.tax-rate-scroll.mt-3::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.tax-field {
  margin-bottom: 15px;
  display: flex;
  align-items: center; 
}

.select-container {
  position: relative;
  flex: 1; 
}

.tax-select-field {
  width: 100%;
  padding-right: 30px; 
  appearance: none; 
}

.tax-rate-drop-icon {
  position: absolute;
  right: 10px;
  top: 74%;
  transform: translateY(-50%);
  pointer-events: none; 
  margin-right: 1rem;
  color: grey;
}

.input-b-color {
  background-color: #fff; 
}

.tax-rate-close-button{
  width: 10%;
  margin-top: 3px;
  font-size: 26px;
}
