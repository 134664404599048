.transfer-letter-pad-invoice-container {
    width: 100%;
    max-width: 71rem;
    max-height: 1123px;
    margin: 0 auto;
    padding: 2rem;
    background-color: white;
    height: 47rem;
}

.transfer-letter-pad-invoice-bill-div {
    display: flex;
    flex-flow: column;
}

.transfer-letter-pad-invoice-billing-address {
    display: flex;
    justify-content: space-between;
}

.transfer-letter-pad-invoice-table {
    width: 100%;
    border-collapse: collapse;
    margin: 1rem 0;
}

.transfer-letter-pad-invoice-table th,
.transfer-letter-pad-invoice-table td {
    border: 1px solid black;
    padding: 0.4rem;
    text-align: left;
    font-size: xx-small;
    font-weight: bold;
}

.transfer-letter-pad-invoice-table th {
    font-weight: bold;
    font-size: x-small;
}

tr.transfer-letter-pad-table-body-row {
    line-height: 0.4rem;
    border-bottom: 1px solid;
}

.footer-data-row {
    display: flex;
    justify-content: space-between;
    margin: 0.5rem 0;
}

.footer-label {
    flex: 1;
    text-align: left;
    font-weight: bold;
}

.footer-value {
    flex: 1;
    text-align: right;
}

span.transfer-letter-pad-extra-row-data {
    display: flex;
    justify-content: flex-end;
}

.transfer-letter-pad-invoice-footer-section {
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
}

.transfer-letter-pad-signature-section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 1rem;
    gap: 5rem;
    margin-top: 1rem;
    border: 1px solid black;
}

.transfer-challan-pad-button-group {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
}

.transfer-letter-pad-title-grp {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.transfer-letter-pad-title {
    font-size: medium;
    font-weight: bold;
}

.transfer-horizontal-line {
    width: 100%;
    height: 1px;
    background-color: black;
    margin: 0.4rem 0;
}

.transfer-order-no,
.transfer-order-date {
    display: flex;
    flex-flow: column;
}

.transfer-order-grp {
    display: flex;
    gap: 10rem;
}

span.transfer-order-no-value,
.transfer-order-date-value {
    font-weight: bold;
    font-size: small;
}

.transfer-letter-pad-tax-grp {
    display: flex;
    flex-flow: column;
    gap: 0.2rem;
}

span.transfer-letter-pad-tax-label {
    font-size: 0.4rem;
    font-weight: bold;
}

span.transfer-letter-pad-footer-title {
    font-size: small;
    font-weight: bold;
}

span.transfer-order-label {
    font-size: small;
}

span.transfer-letter-pad-bill-issued-data {
    font-size: small;
    font-weight: bold;
}

span.transfer-letter-pad-issue-title {
    font-weight: bold;
    font-size: medium;
}

img.transfer-letter-company-logo {
    height: 5rem;
    width: 100%;
    margin-top: 0.5rem;
}

.transfer-letter-com-grp {
    display: flex;
    justify-content: center;
    word-wrap: break-word;
}

.transfer-letter-branch-grp {
    display: flex;
    justify-content: center;
    word-wrap: break-word;
}

.transfer-letter-pad-com-info-container {
    display: flex;
    gap: 0.5rem;
    margin-top: 1rem;
}

.transfer-letter-img-grp {
    width: 18%;
    border: 2px solid black;
    border-radius: 0.5rem;
    height: 8rem;
    padding: 0.5rem;
}

.transfer-letter-com-info-grp {
    width: 82%;
    border: 2px solid black;
    border-radius: 0.5rem;
    padding: 1rem;
    height: 8rem;
}

span.transfer-letter-com-label {
    font-size: small;
    font-weight: bold;
}

span.transfer-letter-com-name-label {
    font-size: x-large;
    font-weight: bold;
}

span.transfer-letter-branch-label {
    font-size: medium;
    font-weight: bold }
  
.transfer-letter-header-com-data-grp {
    display: flex;
    justify-content: space-between;
}

span.transfer-letter-com-data-label {
    font-size: small;
    font-weight: bold;
}

/* footer css */
table.transfer-letter-pad-footer-table {
    border: 1px solid black;
    margin-top: -1rem;
}

.transfer-letter-pad-footer-data {
    display: flex;
    flex-flow: column;
    float: inline-end;
    width: 40%;
}

.transfer-letter-pad-footer-data-row {
    display: flex;
    justify-content: space-between;
    padding: 0rem 0;
}

span.transfer-letter-pad-footer-label {
    flex: 1 1;
    text-align: left;
    font-weight: bold;
    font-size: x-small;
}

span.transfer-letter-pad-footer-value {
    flex: 1 1;
    text-align: right;
    font-size: x-small;
    font-weight: bold;
}

button.transfer-button-icon {
    width: 2rem;
    font-size: larger;
    color: #474ddc;
    background-color: white;
    border: 2px solid;
}

@media print {
    @page {
        margin: 1mm; 
    }

    .transfer-challan-pad-button-group {
        display: none !important;
    }

    .transfer-letter-pad-invoice-container {
        width: 100%;
        height: auto;
        max-width: 150mm;
        max-height: 200mm;
        margin: 0 auto;
        padding: 1rem;
        box-sizing: border-box;
        overflow: hidden;
        background-color: white;
        page-break-inside: avoid; 
    }

    .transfer-letter-pad-invoice-header,
    .transfer-letter-pad-invoice-footer,
    .transfer-letter-pad-invoice-table {
        page-break-after: auto;
    }

    .transfer-letter-pad-invoice-table {
        width: 100%;
        border-collapse: collapse;
    }

    .transfer-letter-pad-invoice-table th,
    .transfer-letter-pad-invoice-table td {
        padding: 8px;
        border: 1px solid #000;
        text-align: left;
    }

    .transfer-letter-pad-footer-title {
        display: inline-block;
        margin-right: 1rem;
        font-weight: bold;
    }

    .footer-data-row {
        margin: 0.3rem 0;
    }
}