.invoice-template-first {
    position: relative;
    font-family: Arial, sans-serif;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 50%;
    margin: 0 auto;
}

.first-template-invoice-number{
    font-size: xx-large ;
}

.first-template-invoice-details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.first-template-invoice-total {
    text-align: right;
    margin-top: 20px;
    font-size: 18px;
}
