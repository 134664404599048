/* Updated Loader.css */
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Higher than pagination but not affecting */
  pointer-events: none; /* Ensures the loader doesn't block clicks */
}

.loader-spinner {
  font-size: 24px;
}
