    
  .barcode-edit-qty-modal-content {
    position: relative;
  }
  
  .barcode-close-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #666;
  }
  
  .barcode-qty-edit-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
  }
  
  .template-preview-section {
    border: 1px solid #eee;
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 20px;
  }
  
  .default-template-preview {
    min-height: 150px;
    border: 1px solid #ddd;
    padding: 10px;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .see-more-templates-btn {
    width: 100%;
    padding: 8px;
    background: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    margin: 10px 0;
    transition: background-color 0.3s;
  }
  
  .templates-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 10px;
    margin-top: 15px;
    max-height: 300px;
    overflow-y: auto;
    animation: slideDown 0.3s ease-out;
  }
  
  .template-item {
    border: 1px solid #ddd;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .template-item.selected {
    border-color: #007bff;
    transform: scale(1.02);
  }
  
  .barcode-edit-item-qty-form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .barcode-edit-item-qty-form-group label {
    font-weight: 500;
  }
  
  .form-control {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .barcode-flexbox {
    display: flex;
    justify-content: flex-end;
  }
  
  /* .barcode-update-btn {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .barcode-update-btn:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  } */
  
  .loading-spinner {
    text-align: center;
    padding: 20px;
    color: #666;
  }
  
  @keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .template-preview-content {
    max-width: 100%;
    overflow: hidden;
  }
  
  /* Add responsive styles */
  @media (max-width: 768px) {
    .barocde-qty-edit-container {
      width: 95%;
      padding: 15px;
    }
  
    .templates-grid {
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    }
  }
  .template-preview-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .template-selected-indicator {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .check-icon {
    color: #28a745;
    font-size: 24px;
    filter: drop-shadow(0 0 2px white);
  }
  
  .template-item.selected,
  .default-template-preview.selected {
    border: 2px solid #28a745;
    box-shadow: 0 0 5px rgba(40, 167, 69, 0.3);
  }
  
  .template-item:hover,
  .default-template-preview:hover {
    border-color: #007bff;
    cursor: pointer;
  }
  .new-barcode-print-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 850px;
    width: 90%;
    max-height: 90vh;
    overflow-y: auto;
  }
  
  .new-barcode-print-modal-content {
    position: relative;
  }
  
  .new-barcode-print-layout {
    display: grid;
    grid-template-columns: 300px 1fr;
    gap: 20px;
    margin-top: 20px;
  }
  
  .new-quantity-section {
    background: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    height: fit-content;
    position: sticky;
    top: 20px;
  }
  
  .template-section {
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #e9ecef;
  }
  
  .new-barcode-qty-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .new-barcode-qty-form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .new-barcode-print-btn {
    background: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.2s;
  }
  
  .new-barcode-print-btn:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
  
  .template-selection-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .templates-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .barcode-template-preview {
    border: 2px solid #e9ecef;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s;
    max-width: min-content;
    display: flex;
    justify-items: center;
    margin: 0 auto;
  }
  
  .barcode-template-preview.selected {
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.2);
  }
  
  .template-name {
    margin-top: 8px;
    text-align: center;
    font-size: 14px;
  }
  
  .new-barcode-close-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #666;
  }
  .new-barcode-qty-form-group select {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .new-barcode-qty-form-group select:focus {
    outline: none;
    border-color: #007bff;
  }