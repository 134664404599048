.invoice-wrapper {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.invoice-header-row {
  display: flex;
  justify-content: space-between;
  width: auto;
}

.invoice-company-logo {
  width: 80px;
  height: 80px;
}

.invoice-company-details {
  flex-grow: 1;
  padding-left: 20px;
}

.invoice-actions {
  display: flex;
  gap: 10px;
  float: right;
  margin-top: -4rem;
}

.com-whole-info {
  display: flex;
}

.invoice-button-download,
.invoice-button-print {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  height: 45px;
}

.invoice-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
}

.invoice-box {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  background-color: #fff;
}

.invoice-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.invoice-company-info {
  text-align: right;
}

.invoice-details {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

h3 {
  margin-bottom: 10px;
  font-size: 18px;
  padding-bottom: 5px;
}

.invoice-table-container {
  margin-top: 20px;
}

.invoice-table {
  width: 100%;
  border-collapse: collapse;
}

.invoice-table th,
.invoice-table td {
  border: 2px solid grey;
  padding: 8px;
  text-align: left;
}

.invoice-table th {
  background-color: #f2f2f2;
}

.invoice-summary {
  display: flex;
  justify-content: space-between;
  margin-top: 230px;
}

.invoice-summary-left,
.invoice-summary-right {
  width: 25%;
}

.invoice-payment-info {
  margin-bottom: 20px;
}

.invoice-button-pay {
  background-color: #ff9800;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

.invoice-totals {
  padding-top: 10px;
}

.invoice-total-line {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.invoice-label {
  font-weight: bold;
}

.invoice-value {
  text-align: right;
}

/* .invoice-divider {
  margin-top: 20px;
} */

.invoice-company-logo img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10%;
}

@media print {
  @page {
    size: A4;
    margin-top: 100px;
    margin-bottom: 50px;
    border-top: 1px solid grey;
    page-break-inside: avoid;
    page-break-after: auto
  }

  @page: first {
    margin-top: 0px;
    margin-bottom: 1rem;
    page-break-inside: avoid;
    page-break-after: auto;
    border: 1px solid black;
  }

  .invoice-box {
    border: none;
  }

  .invoice-table th,
  .invoice-table td {
    border: 2px solid grey;
    padding: 8px;
    text-align: left;
  }

  .invoice-table tr.page-break {
    page-break-after: always;
  }

  .invoice-summary {
    display: flex;
    justify-content: space-between;
    margin-top: 4rem;
  }

  .invoice-actions {
    display: none;
  }
}

.company-info-invoice {
  display: table-header-group;
}
.watermark {
  display: none;
}

@media print {
  .watermark {
    display: block;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    font-size: 100px;
    color: rgba(0, 0, 0, 0.1);
    z-index: 1000;
    pointer-events: none;
  }

  .invoice-box {
    position: relative;
    z-index: 1;
  }
}

.tax-font-size-inv {
  font-size: 10px;
}

