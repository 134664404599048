.check-box-permission {
  margin-bottom: 20px;
}

.permission-group {
  display: flex;
  flex-wrap: wrap;
}

.row-content {
  display: flex;
  flex-wrap: wrap;
}

.col-content {
  flex: 0 0 0 25%;
  padding: 25px;
}

.form-checkbox {
  display: flex;
  align-items: center;
}

.form-input {
  margin-right: 5px;
  /* font-size: 32px; */
  height: 20px;
  width: 20px;
}

.form-checkbox-label {
  font-size: 22px;
  white-space: nowrap;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
}

.error-content {
  margin-left: 1rem;
}