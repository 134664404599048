.subscription-popup-content-per {
  width: 400px; /* Adjust the width as per your requirement */
  padding: 2rem;
  background-color: white;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  font-family: 'Arial', sans-serif;
  text-align: center;
  position: relative;
}

.subcription-popup-subcontent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.subcription-popup-subcontent h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  color: #333;
}

.btn.btn-primary.unauthorize-close-button {
  background-color: #0056b3;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  margin-top: 7px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn.btn-primary.unauthorize-close-button:hover {
  background-color: #003f8a;
}

.subscription-details {
  margin-top: 1rem;
}

.modal-details p {
  color: #666;
  font-size: 1rem;
  margin: 0;
}

