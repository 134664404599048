.pharma-purchase-item-add-setting {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.pharma-purchase-settings-container {
	background-color: #fff;
	padding: 20px;
	border-radius: 8px;
	position: relative;
	width: 33%;
	margin-left: 33%;
	height: 24rem;
}

.pharma-purchase-checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: inline-block;
  width: 18px;
  height: 18px;
  margin: 0;
  padding: 0;
  background-color: #fff;
  border: 2px solid #474DDC;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
}

.pharma-purchase-checkbox:checked::before {
  content: '✓';
  color: white;
  display: block;
  text-align: center;
  line-height: 18px;
  font-size: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  background-color: #474DDC;
}

.pharma-purchase-label {
  font-size: large;
}

.pharma-purchase-input {
  display: block;
  width: 33%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.pharma-purchase-input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.pharma-purchase-input::placeholder {
  color: #6c757d;
  opacity: 1;
}

.pharma-purchase-input:disabled {
  background-color: #e9ecef;
  opacity: 1;
}

.pharma-purchase-header {
  padding-top: 3rem;
}

.pharma-purchase-data-label {
  display: flex;
  gap: 10px;
}

.pharma-purchase-btn-primary {
  background-color:  #002989; 
  color: white;
}

.pharma-purchase-btn-disabled {
  background-color: grey;
  color: white;
  cursor: not-allowed;
}
