  .res-item-modifier {
    width: 50%;
    height: auto;
  }

  .res-modify-title {
    width: 100%;
    background-color: #002989 !important;
    height: 3rem;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
  }

  .modify-title-heading {
    font-size: larger;
    font-weight: bold;
  }

  .modify-cross-icon {
    font-size: larger;
    font-weight: bold;
    cursor: pointer;
  }

  input.form-control.modify-res {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }

  .modifier-buttons-res {
    gap: 10px;
  }

  .button.item-modify-close {
    margin-left: 10px;
  }

  .res-modify-sub {
    background-color: white;
    height: auto;
  }

  .res-modify-checkbox-list {
    height: auto;
    background-color: white;
    margin-left: 10px;
  }

  button.btn.btn-info.selected-modify-button.mb-3 {
    margin-left: 10px;
  }

  .modifier-buttons-res.mt-4 {
    margin-left: 10px;
  }

  .collection-res-modify-button {
    display: flex;
    margin-left: 10px;
    gap: 10px;
  }

  button.btn.btn-info.item-modify-close {
    margin-bottom: 10px;
  }

  .res-modifier-container {
    display: flex;
    align-items: center;
  }

  label.modifier-label {
    width: 20rem;
    margin-bottom: 10px;
  }

  .item-modifier-invoice-input {
    margin-bottom: 10px;
    border-radius: 1rem;
    margin-right: 10px;
    border: 1px solid #002989;
    color: #002989;
    background-color: white;
    padding: 10px 20px;
  }

  .item-modifier-invoice-input:hover {
    background-color: #cddcf8;
  }

  /* button.modifier-input:active {
    background-color: #1c4bb9;
  } */

  .modify-sub-padding {
    padding-top: 1rem;
  }

  span.modifier-buttons-res-save-select {
    margin-bottom: 10px;
  }

  .description-error-message {
    color: red;
    margin-top: 10px;
    margin-left: 10px;
  }

  .item-modifier-button-style {
    display: contents;
  }

  .modify-button-main-style {
    overflow-y: auto;
    max-height: 200px;
  }

  .item-res-modify-sub {
    margin-top: 2rem;
    margin-left: 1rem;
    font-size: large;
    font-weight: bold;
    color: #002989;
  }

  button.btn.btn-success.item-modify-save-select {
    background-color: #002989;
    border: none;
  }

  button.btn.btn-danger.item-modify-close {
    background-color: #002989;
    border: none;
  }

  button.item-modifier-invoice-input.selected-modifier {
    background-color: #cddcf8;
  }
