.delivery-person-main-container {
  width: 30%;
  height: auto;
}

.delivery-person-title {
  width: 100%;
  background-color: #002989 !important;
  height: 3rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}

.delivery-person-title-heading {
  font-size: larger;
  font-weight: bold;
}

.delivery-person-sub-container {
  background-color: white;
  height: auto;
}

.delivery-person-group-title {
  padding-top: 1rem;
  margin-left: 1rem;
  font-size: larger;
  font-weight: bold;
  margin-bottom: 1rem;
}

.biker-person-button-style {
  display: contents;
}

.collection-biker-list {
  overflow-y: auto;
  max-height: 200px;
  margin-left: 1rem;
}

.biker-person-input {
  margin-bottom: 10px;
  border-radius: 1rem;
  margin-right: 10px;
  border: 1px solid #002989;
  color: #002989;
  background-color: white;
  padding: 10px 20px;
}

.biker-person-input:hover {
  background-color: #cddcf8;
}
