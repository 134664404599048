.edit-tax-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    height: 70%;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.edit-tax-group-modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    position: relative;
    height: auto;
    width: 34rem;
}

.close {
    position: absolute;
    top: 17px;
    right: 24px;
    cursor: pointer;
    font-size: 4vh;
}

.edit-tax-container h2 {
    font-size: x-large;
    margin-bottom: 3vh;
}

.edit-tax-rate-form-group {
    display: flex;
    align-items: center;
    /* Align items vertically */
    margin-bottom: 1.5vh;
    font-size: large;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.form-group input {
    width: calc(100% - 16px);
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.taxedit-submit-button {
    background-color: #474DDC;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 1.5vh;
    float: right;
}

.submit-button:hover {
    background-color: #0056b3;
}

.taxrate-fom-input {
    padding: 2px 4px 3px 5px;
}

.taxrate-fom-input-title{
    margin-left: 14px;
}

.taxrate-fom-input-amount{
    margin-left: 27px;
}

.taxrate-edit-form{
    margin-left: 0px;
}

.raxrate-form-taxvalue{
    margin-left: 20px;
    width: 75%;
}

.edit-tax-rate-form-group label {
    font-size: 17px;
    margin-right: 10px;
}

.tax-group-multiselect{
    margin-left: 82px;
    width: 24rem;
    margin-right: 2px;
}

.taxgroup-edit-name{
    width: 10rem;
}

/* ------------------------ */

.input-b-color {
	display: block;
	/* width: 100%; */
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	appearance: none;
	border-radius: 0.375rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .input-b-color:focus {
	color: #495057;
	background-color: #fff;
	border-color: #80bdff;
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .input-b-color::-webkit-input-placeholder {
	color: #6c757d;
	opacity: 1;
  }
  
  .input-b-color::-moz-placeholder {
	color: #6c757d;
	opacity: 1;
  }
  
  .input-b-color:-ms-input-placeholder {
	color: #6c757d;
	opacity: 1;
  }
  
  .input-b-color::-ms-input-placeholder {
	color: #6c757d;
	opacity: 1;
  }
  
  .input-b-color::placeholder {
	color: #6c757d;
	opacity: 1;
  }
  
  .input-b-color:disabled, .input-b-color[readonly] {
	background-color: #e9ecef;
	opacity: 1;
  }
  