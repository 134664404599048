.order-thermal-main-container {
  width: 100vw;
  height: auto;
  margin: 0 auto;
  max-width: 794px;
  background-color: white;
  box-sizing: border-box;
  overflow: hidden;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

.order-thermal-sub-container {
  padding: 1rem;
}

.order-thermal-header-div {
  padding: 0.5rem;
  border: 1px solid;
  display: flex;
  justify-content: space-between;
  background-color: #002989;
  color: white;
}

span.order-thermal-header-left-title {
  font-size: larger;
  font-weight: bold;
}

span.order-thermal-header-right-title {
  font-size: larger;
  font-weight: bold;
}

.order-thermal-company-info-grp,
.order-thermal-company-info {
  text-align: center;
}

img.order-thermal-company-logo {
  width: 10%;
  height: 10%;
}

.order-thermal-horizontal-line {
  width: 100%;
  height: 1px;
  background-color: black;
  margin: 1rem 0;
}

.order-thermal-header-title-grp {
  text-align: center;
}

span.orderr-thermal-header-title {
  font-size: medium;
  font-weight: bold;
}

.order-thermal-cust-info-grp,
.order-thermal-cust-order-no-grp,
.order-thermal-cust-order-date-grp {
  display: flex;
  flex-flow: column;
}

span.order-thermal-cust-info-titles {
  font-size: small;
  font-weight: bold;
}

.order-thermal-cust-order-container {
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
}

span.order-thermal-cust-order-title {
  font-size: medium;
}

span.order-thermal-order-value {
  font-size: small;
  font-weight: bold;
}

.order-thermal-dotted-line {
  border: none;
  border-top: 1px dotted black;
  color: #fff;
  background-color: #fff;
  height: 1px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

span.order-thermal-com-info-title {
  font-size: small;
  font-weight: bold;
}

/* order thermal table */
table.order-thermal-temp-table {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
  border-bottom: 1px dotted black;
}

tr.order-thermal-temp-tab-head-row,
.order-thermal-temp-table-body-row {
  line-height: 0.4rem;
}

th.order-thermal-temp-table-header-attribute {
  font-weight: bold;
  font-size: x-small;
}

.order-thermal-temp-table th, .order-thermal-temp-table td {
  border: 1px dotted black;
  padding: 0.4rem;
  text-align: left;
  font-size: xx-small;
  font-weight: bold;
}

table.order-thermal-temp-footer-table {
  border: 1px dotted black;
  margin-top: -1rem;
}

.order-thermal-temp-footer-data {
  display: flex;
  flex-flow: column;
  float: inline-end;
  width: 30%;
}

.order-thermal-temp-footer-data-row {
  display: flex;
  justify-content: space-between;
  padding: 0rem 0;
}

span.order-thermal-temp-footer-label {
  flex: 1 1;
  text-align: left;
  font-weight: bold;
  font-size: x-small;
}

span.order-thermal-temp-footer-value {
  flex: 1 1;
  text-align: right;
  font-size: x-small;
  font-weight: bold;
}

.order-thermal-temp-tax-details {
  font-size: 0.4rem;
  font-weight: bold;
  margin-bottom: 0.2rem;
}

span.order-thermal-temp-extra-row-data {
  display: flex;
  justify-content: flex-end;
}

.order-thermal-cust-info-grp {
  display: flex;
  justify-content: space-between;
}

.order-thermal-cust-info-div {
  display: flex;
  flex-flow: column;
  word-wrap: break-word;
}

.order-thermal-order-info-grp {
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
}

.order-thermal-order-info {
  display: flex;
  flex-flow: column;
}

.order-thermal-header-grp {
  text-align: center;
}

/* footer thermal signature grp */
.order-thermal-temp-signature-section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 1rem;
  gap: 5rem;
  margin-top: 1rem;
  border:  1px dotted black;
}

span.order-thermal-temp-first-title {
  font-size: small;
  font-weight: bold;
}

.order-thermal-header-right-title{
  cursor: pointer;
}

@media print {
  .order-thermal-main-container {
    font-family: 'Arial', sans-serif;
    font-size: 12px;
    line-height: 1.2;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: white;
    width: 80mm;
  }

  .order-thermal-header-div {
    display: none !important;
  }

  img.order-thermal-company-logo {
    max-width: 50px;
    max-height: 50px;
    display: block;
    margin: 0 auto 5mm auto;
  }

  .order-thermal-header-title-grp, .order-thermal-company-info-grp {
    text-align: center;
    margin-bottom: 3mm;
  }

  .order-thermal-cust-info-grp {
    display: flex;
    flex-flow: column;
    font-size: 10px;
  }

  .order-thermal-cust-order-title{
    font-size: 5px;
    font-weight: bold;
  }

  .order-thermal-order-value{
    font-size: 5px;
    font-weight: bold;
  }

  .order-thermal-order-info {
    margin: 3mm 0;
  }

  .order-thermal-dotted-line, .order-thermal-horizontal-line {
    border: none;
    border-top: 1px dotted black;
    margin: 1mm 0;
  }

  table.order-thermal-temp-table {
    width: 100%;
    border-collapse: collapse;
    margin: 2mm 0;
    font-size: 10px;
  }

  .order-thermal-temp-table th {
    border: 1px dotted black;
    padding: 1mm;
    text-align: left;
    font-size: xx-small;
    font-weight: bold;
    color: black !important;
    background-color: white !important;
  }

  tr.order-thermal-temp-tab-head-row {
    border: 2px dotted black;
  } 

  .order-thermal-temp-footer-data {
    width: 100%;
    margin: 2mm 0;
  }

  table.order-thermal-temp-footer-table {
    margin-top: 0mm;
  }

  .order-thermal-temp-footer-data-row {
    display: flex;
    justify-content: space-between;
    font-size: 10px;
  }

  .order-thermal-temp-signature-section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 1mm;
    border: 1px dotted black;
    font-size: 10px;
  }
}
