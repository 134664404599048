.online-order-dropdown-container {
  position: relative;
}

.online-order-dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 14rem;
}

.online-order-dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.online-order-dropdown-menu li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.online-order-dropdown-menu li:hover {
  background-color: #3734cc;
  transform: scale(1.02);
  color: white;
}
