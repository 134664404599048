.popup-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
  }
  
  .popup-content {
	background-color: #fff;
	padding: 20px;
	border-radius: 8px;
	width: 400px;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
	position: relative;
  }
  
  .popup-content h4 {
	margin-bottom: 15px;
	font-size: 18px;
	color: #333;
  }
  
  .select-container {
	position: relative;
	margin-bottom: 15px;
  }
  
  .form-control {
	width: 100%;
	padding: 10px;
	font-size: 16px;
	border: 1px solid #ccc;
	border-radius: 4px;
  }
  
  .sts-edit-drop {
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
	pointer-events: none;
	color: #888;
  }
  
  .popup-actions {
	display: flex;
	justify-content: flex-end;
	gap: 10px;
  }
  
  .btn {
	padding: 10px 20px;
	border-radius: 4px;
	font-size: 16px;
	cursor: pointer;
  }
  
  .btn-primary {
	background-color: #007bff;
	border: none;
	color: #fff;
  }
  
  .btn-primary:hover {
	background-color: #0056b3;
  }
  
  .btn-secondary {
	background-color: #6c757d;
	border: none;
	color: #fff;
  }
  
  .btn-secondary:hover {
	background-color: #5a6268;
  }
  