.captain-select-main {
  width: auto;
  height: auto;
  border-collapse: collapse;
  background-color: white !important;
}
  
.captain-select-title {
  width: 100%;
  background-color: #002989 !important;
  height: 3rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}
  
.selected-captain-title-heading {
  font-size: larger;
  font-weight: bold;
}
  
.captain-selected-sub {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
  
button.btn.btn-success.select-captain-save {
  margin-left: 10px;
  background-color: #002989;

}

.captain-select-save-button {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.captain-first-label-and-input {
  display: flex;
  position: relative; 
  gap: 1rem;
}

label.captain-select-label-field {
  margin-left: 1rem;
  font-size: larger;
  font-weight: bold;
}

.form-select-caption-select{
  width: 15rem;
  margin-right: 10px;
  padding-right: 2rem; 
}

.select-captain-drop-icon {
  position: absolute;
  top: 50%; 
  right: 1rem; 
  transform: translateY(-50%);
  pointer-events: none; 
  color: grey;
}
