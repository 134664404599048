.save-btn-1 {
  margin-left: 3px;
}

.customer-create-tab {
  height: auto;
  max-height: 850px;
  overflow-y: scroll;
}

.show-staff {
  height: auto;
  max-height: 800px;
  overflow-y: scroll;
}

.cust-common-table {
  height: auto;
  max-height: 600px;
  overflow-y: auto;
}

.trans-common-table {
  height: auto;
  max-height: 550px;
  overflow-y: auto;
}

.sticky-header {
  position: sticky;
  top: 0;
}

.icon-link,
.show-icon,
.delete-icon {
  display: inline-block;
  padding: 5px;
  vertical-align: middle;
}

.margin-btm {
  margin-bottom: 15px;
}

.margin-left {
  margin-left: 20px;
  margin-top: 1rem;
}


.supplier-detail {
  display: flex;
  flex-direction: column;
}

.detail-row {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.detail-label {
  width: 200px;
  font-weight: bold;
  flex-shrink: 0;
}

.detail-value {
  flex-grow: 1;
  margin-left: 300px;
}

@media (max-width: 768px) {
  .detail-label {
    width: 100%;
    margin-bottom: 5px;
  }

  .detail-value {
    margin-left: 0;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* .modal-content {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  max-width: 40%;
  max-height: 40vh;
  overflow: auto;
} */

.modal-image {
  max-width: 100%;
  height: auto;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
  color: #aaa;
  z-index: 1;
}

.modal-close:hover {
  color: #000;
}

.confirm-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content-delete {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 400px;
}

.modal-header-delete {
  margin-bottom: 10px;
}

.modal-header-delete h3 {
  font-size: 1.5rem;
  color: #333;
}

.modal-body-delete {
  margin-bottom: 20px;
}

.modal-body-delete p {
  font-size: 1.2rem;
  color: #666;
}

.modal-footer-delete {
  display: flex;
  justify-content: flex-end;
}

.save-btn-delete {
  margin-right: 5px;
}

.select-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.select-input {
  flex: 1;
}

.staff-dropdown-icon {
  position: absolute;
  top: 60%;
  right: 30px;
  transform: translateY(-50%);
  pointer-events: none;
  color: #555;
}

.select-input {
  width: 100%;
  padding-right: 30px;
  color: black;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  margin-top: 1%;
}

.select-input option {
  color: black !important;
}

.position-relative {
  color: #bbbaba;
  position: relative;
}

.staff-dropdown-icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #999;
  margin-top: 0%;
}

.form-control {
  line-height: 2.5 !important;
  }
