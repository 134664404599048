.free-text-main-container {
  height: auto;
  background-color: white;
  width: auto;
}

.free-text-header-container {
  display: flex;
  justify-content: space-between;
  background-color: #002989;
  color: white;
  line-height: 3rem;
  padding: 0.5rem;
}

span.free-text-head-title,
.free-text-head-icon {
  font-size: larger;
  font-weight: bold;
}

.free-text-sub-container {
  padding: 0.5rem;
}

.free-text-field-grp {
  display: flex;
  gap: 2rem;
}

.free-text-btn-grp- {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

button.free-text-btn-perform {
  border: none;
  border-radius: 0.2rem;
  padding: 0.4rem 0.8rem;
  color: white;
  background-color: #002989;
}

lable.free-text-label {
  font-size: large;
  font-weight: bold;
}

.free-text-head-icon, 
.free-text-btn-perform:hover{
  cursor: pointer;
}
