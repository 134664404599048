.quotation-main-container {
  display: flex;
  flex-direction: column;
  height: 82vh;
  background-color: white;
  padding: 0.8rem;
  border-radius: 1rem;
  margin-top: 1rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.quotation-container-first-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.quotation-first-left {
  display: flex;
  align-items: center;
}

.quotation-first-right {
  display: flex;
  justify-content: flex-end;
}

button.quotation-first-right-botton {
  display: flex;
  align-items: center;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  background-color: #002989;
  gap: 1rem;
}

.quotation-container-second-div {
  margin-top: 0.5rem;
}

.quotation-second-div-right {
  display: flex;
  justify-content: space-between;
}

.quotation-second-part-div {
  display: flex;
  gap: 1rem;
}

.quotation-second-drop-first {
  background-color: #002989;
  color: white;
  padding: 0.2rem 0.5rem;
  display: flex;
  gap: 1rem;
  border-radius: 0.5rem;
  height: 2rem;
}

.quote-second-filter {
  background-color: #002989;
  color: white;
  padding: 0.2rem 0.5rem;
  border-radius: 0.4rem;
  cursor: pointer;
}

span.quote-ellips-icon {
  color: #002989;
  font-size: x-large;
  font-weight: bold;
}

.status {
  display: inline-block;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 0.85rem;
}

span.all-quotation-show {
  color: #002989;
  font-size: x-large;
  font-weight: bold;
}

span.all-quotation-show {
  color: #002989;
  font-size: x-large;
  font-weight: bold;
}

.new-quotation-popup-container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  z-index: 1000
}

/* Table group */
.quatation-table-group {
  border-bottom: 1px solid #ccc;
  margin-top: 1rem;
  overflow-y: auto;
  max-height: 350px;
}

.quatation-table {
  width: 100%;
  border-collapse: collapse;
}

.quatation-thead-row th {
  padding: 0.4rem;
  border-bottom: 1px solid #ccc;
  text-align: left;
  border: 1px solid #ccc;
  font-size: small;
  font-weight: bold;
}

.quatation-table-body td {
  padding: 0.2rem;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  text-align: left;
  font-size: small;
}

.quatation-tab-td {
  padding: 0.2rem;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  text-align: left;
  font-size: small;
}

.column-filter-popup {
  position: absolute;
  top: 13rem;
  right: 1rem;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 10;
  width: 15%;
}

.column-filter-popup label {
  display: flex;
  margin-bottom: 0.5rem;
  font-size: x-small;
}

input.quotation-visible-column-input {
  width: 10%;
  margin-right: 4%;
}

button.visible-box-btn {
  background-color: #002989;
  color: white;
  padding: 0.1rem 0.2rem;
  font-size: small;
  border: none;
  border-radius: 0.2rem;
}

/* attribute filter popup css*/
.quotation-attribute-filter-popup-container {
  position: absolute;
  top: 13rem;
  right: 1rem;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 10;
  width: 25%;
}

label.quote-first-att-div-label,
.quote-second-att-div-label,
.quote-third-part-1-label,
.quote-third-part-2-label{
  font-size: x-small;
}

.quote-first-att-div,
.quote-second-att-div,
.quote-third-part-1,
.quote-third-part-2{
  display: flex;
  flex-flow: column;
  margin-bottom: 0.5rem;
}

.quote-third-att-div {
  display: flex;
  justify-content: space-between;
}

input.quote-third-att-date {
  padding: 0.2rem;
  font-size: x-small;
  width: 100%;
}

.quote-attr-btn-grp {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

button.quote-attr-pop-btn {
  border: none;
  border-radius: 0.2rem;
  font-size: small;
  padding: 0.2rem 0.4rem;
  background-color: #002989;
  color: white;
}

.quotation-second-three-dot-grop {
  cursor: pointer;
}

span.quotatin-icon-between {
  border-left: 2px solid white;
  height: 2rem;
  position: absolute;
  left: 56.5rem;
  top: 25%;
}

.quotation-turn-icon-grp,
.quotation-down-icon-grp {
  cursor: pointer;
}

/* quotation down icon css */
.quotation-down-icon-popup{
  position: absolute;
  top: 13rem;
  right: 1rem;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: 12%;
  height: 4rem;
}

ul.quote-down-icon-list-grp {
  display: flex;
  flex-flow: column;
  padding-left: 0rem;
}

li.quote-down-options {
  font-size: small;
}

.quote-down-options:hover{
  background-color: whitesmoke;
  cursor: pointer;
}

/* quotationn turn icon css */
.quoation-turn-icon-popup{
  position: absolute;
  top: 13rem;
  right: 1rem;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 10;
  width: 25%;
}

.quote-turn-popup-div {
  display: flex;
  flex-flow: column;
}

.quote-turn-popup-div-2{
  display: flex;
  flex-flow: column;
  margin-top: 0.5rem;
}

label.quote-turn-pop-label {
  font-size: small;
}

.quote-turn-btn-grp {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}

button.quote-turn-btn {
  border: none;
  padding: 0.2rem 0.2rem;
  font-size: small;
  background-color: #002989;
  color: white;
  border-radius: 0.2rem;
}

/* quotation down icon action css */
.quotation-down-icon-action{
  right: 1rem;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
  width: 15%;
}

svg.svg-inline--fa.fa-caret-down.quote-table-body-td {
  margin-left: 0.5rem;
}

ul.quote-down-icon-action-ul {
  padding: 0px;
  margin-bottom: 0px;
}

li.quote-down-icon-action-li {
  padding: 0.2rem;
  font-size: small;
}

.quote-down-icon-action-li:hover{
  background-color: #ccc;
  cursor: pointer;
}

.quotation-header {
  display: flex;
  gap: 2rem;
}

select.form-control.trans-common-status {
  height: 2.5rem;
  width: 40%;
  padding-left: 0.5rem;
  padding-bottom: 0rem;
  padding-top: 0rem;
}

input.form-control.trans-common-datepicker {
  height: 2.5rem;
  width: 85%;
  padding-left: 0.5rem;
  padding-bottom: 0rem;
  padding-top: 0rem;
}
