.update-restaurant-setting-menu-btn-main {
    width: 30%;
    height: auto;
    border: 1px solid black;
  }
  
  .setting-menu-title {
    width: 100%;
    background-color: #002989 !important;
    height: 3rem;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
  }
  
  .update-menu-title-heading {
    font-size: larger;
    font-weight: bold;
  }


  .res-updateconfig-sub {
    background-color: white;
    height: auto;
  }
  
  .update-config-title-sub {
    margin-left: 1rem;
    font-size: large;
    font-weight: bold;
    color: #002989;
  }


  .collection-update-res-config-setting-button {
    display: flex;
    margin-left: 10px;
    gap: 10px;
  }
  
  span.update-config-res-save-select {
    margin-bottom: 10px;
  }
  
  button.btn.btn-success.update-res-config-save-select {
    background-color: #002989;
    border: none;
  }
  
  button.btn.btn-danger.update-config-close {
    background-color: #002989;
    border: none;
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
  
  .config-update-div-first {
    display: flex;
    padding-top: 10px;
  }
  
  .update-config-option-container {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    gap: 1rem;
  }
  
  .custom-checkbox {
    width: 16px;  
    height: 16px; 
    margin-right: 8px;
  }

  label.update-config-option-name {
    flex: none;
  }
