.delivery-order-main-container {
  display: flex;
  flex-direction: column;
  height: 92vh;
  background-color: #fff;
  padding: 17px;
  border-radius: 15px;
  overflow-y: auto;
}

.order-delivery-header-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

button.delivery-right-hand-button {
  margin-right: 1rem;
  line-height: 2rem;
  padding: 5px 8px;
  background-color: #002989;
  border-radius: 7px;
  color: white;
  border: 1px solid #002989;
}

span.order-delivery-title {
  font-size: larger;
  font-weight: bold;
}

hr.delivery-order-hr {
  position: relative;
  top: 0;
  border: none;
  height: 2px;
  background: black;
  margin-bottom: 1rem;
}

span.delivery-order-not-found {
  margin-left: 1rem;
}

.order-delivery-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  margin: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 30%;
  height: 10rem;
  /* background-color: orange; */
}

.order-delivery-card-alignment-main {
  text-align: left;
  display: flex;
  gap: 7rem;
}

span.delivery-card-title-name {
  font-size: x-large;
  font-weight: bold;
  color: white;
}

.delivery-item-order-amount {
  color: white;
  justify-content: center;
  font-size: larger;
}

.order-delivery-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

span.delivery-card-captain-name {
  font-size: larger;
  color: white;
}

.card-footer-icons {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  padding-top: 3rem;
  color: white;
}

.delivery-icon-pointer {
  cursor: pointer;
  margin: 0 10px;
  font-size: 1.5rem; 
}

.order-status-draft {
  background-color: orange; 
}

.order-status-pending {
  background-color: red;
}

/* External CSS file: order-delivery-header.css */

.order-delivery-header-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-delivery-header-right {
  display: flex;
  flex: 1;
}

.back-button {
  display: block;
  width: 38px;
  height: 36px;
  border-radius: 50%;
  background-color: #002989;
  border: none;
  margin: 0;
  position: relative;
}

.delivery-right-hand-button {
  background-color: #002989;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin-left: 10px;
  cursor: pointer;
}

.delivery-right-hand-button:hover {
  background-color: #001f70;
}

.order-delivery-header-right .back-button {
  flex: -1;
}

.order-delivery-header-right .delivery-buttons {
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
}
