.fouth-receipt-main-4 {
  background-color: #fff;
  padding: 20px;
  font-size: small;
  font-weight: bold;
  max-width: 800px;
}

.company-title-4 {
  font-size: 24px;
  text-align: center;
}

.invoice-info-4 {
  text-align: center;
  margin-bottom: 20px;
}

.invoice-info-4 h3 {
  margin-bottom: 10px;
}

.receipt-fourth-table-4 {
  /* border-collapse: collapse; */
  width: 100%;
  /* margin-bottom: 2rem; */
}

.fourth-receipt-table-heading-4 th {
  color: black;
  font-weight: bold;
  position: sticky;
  top: 0;
  background-color: white;
  border-bottom: 1px solid black;
}

.fourth-receipt-table-heading-4 th:first-child {
  left: 0;
  z-index: 2;
}

.receipt-fourth-table-4 th,
.receipt-fourth-table-4 td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid black;
}

.receipt-fourth-table-4 th:first-child,
.receipt-fourth-table td:first-child {
  padding-left: 16px;
}

.receipt-fourth-table-4 th:last-child,
.receipt-fourth-table td:last-child {
  padding-right: 16px;
}

.receipt-fourth-table-4 tfoot td {
  font-weight: bold;
}

.footer-4 {
  text-align: center;
  margin-top: 20px;
}

.footer-4 p {
  margin: 5px 0;
}

.receipt-four-logo-4 {
  height: 3rem;
  width: 3rem;
}

.fourth-logo-header-4 {
  text-align: center;
}

.receip-four-wel-msg-4 {
  text-align: center;
}

.company-info,
.order-details-4 {
  display: flex;
  flex-direction: column;
}

.company-info-4 {
  flex-basis: 60%;
  align-items: flex-start;
}

.order-details-4 {
  flex-basis: 40%;
}

.company-address-4,
.order-details-4 div {
  margin-bottom: 10px;
}

.receipt-company-address-4,
.receipt-com-mail-4,
.receipt-date-4,
.receipt-time-4 {
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  .fouth-receipt-main-4 {
    padding: 10px;
    font-size: 12px;
  }

  .company-title-4 {
    font-size: 18px;
  }

  .receipt-fourth-table-4 th,
  .receipt-fourth-table-4 td {
    padding: 4px;
  }

  .receipt-fourth-table-4 th:first-child,
  .receipt-fourth-table-4 td:first-child {
    padding-left: 8px;
  }

  .receipt-fourth-table-4 th:last-child,
  .receipt-fourth-table-4 td:last-child {
    padding-right: 8px;
  }
}

.tax-font-size {
  font-size: x-small;
  margin-top: 4px;

}

@media print {
  .fouth-receipt-main-4 {
    background-color: #fff;
    margin: 0 auto; /* center the main container */
    padding: 30px; /* add some padding to avoid content sticking to the edges */
    width: 100%;
    font-size: small;
    font-weight: bold;
    box-sizing: border-box; /* include padding in the width calculation */
  }
  .company-title-4 {
    font-size: 18px;
    text-align: center;
  }

  .invoice-info-4 {
    text-align: center;
    margin-bottom: 0;
  }

  .receipt-sub-header-4 {
    text-align: center;
    font-weight: bold;
    margin-bottom: 0;
  }

  .receipt-fourth-table-4 {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 0;
    table-layout: fixed; /* fix table layout issues */
  }

  .fourth-receipt-table-heading-4 th {
    color: black;
    font-weight: bold;
    position: sticky;
    top: 0;
    background-color: white;
    border-bottom: 1px solid black;
  }

  .fourth-receipt-table-heading-4 th:first-child {
    left: 0;
    z-index: 2;
  }

  .receipt-fourth-table-4 th,
  .receipt-fourth-table-4 td {
    text-align: left;
    padding: 4px; /* reduced padding */
    border-bottom: 1px solid black;
  }

  /* Center elements using flexbox */
  .company-info,
  .order-details-4 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .company-info-4 {
    flex-basis: 60%;
    align-items: flex-start;
  }

  .order-details-4 {
    flex-basis: 40%;
  }
}

.invoice_details-4 {
  display: flex;
  gap: 355px;
}

.fourth-receipt-policy-4 {
  margin-top: 1rem;
  display: flex;
  text-decoration: underline;
}

.receipt-fourth-policy-content-4,
.receipt-fourth-offer-content-4 {
  display: flex;
  margin-bottom: 1rem;
}
