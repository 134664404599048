.show-supplier-main-container {
  background-color: #fff;
  padding: 17px;
  border-radius: 15px;
  max-width: 100%;
  height: auto;
}

.show-supplier-header-container {
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  line-height: 4rem;
}

.supplier-show-details-title {
  color: #3e429d;
  margin-left: 1rem;
  font-weight: bold;
  font-size: x-large;
}

.show-supplier-second-container {
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
  height: 40rem;
  margin-top: 1rem;
  overflow-y: auto;
}

.supplier-details-row {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.show-supplier-details-label {
  width: 200px;
  font-weight: bold;
  flex-shrink: 0;
  margin-left: 2rem;
}

.supplier-details-value {
  flex-grow: 1;
  margin-left: 300px;
}

span.supplier-details-value {
  flex-grow: 1;
  margin-left: 300px;
}
