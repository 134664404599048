.card-content {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  margin: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 19rem;
  height: 10rem;
  background-color: rgba(93, 196, 34, 0.644);
}

.icon-set {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  padding: 10px;
  color: skyblue;
}

h3.item-order-amount {
  color: white;
  justify-content: center;
}

.icon-pointer {
  cursor: pointer;
}

.card-contain-alignment-main {
  text-align: left;
}

span.card-captain-name {
  margin-left: 3rem;
  font-weight: bold;
  color: white;
}

span.card-title-name {
  font-size: x-large;
  font-weight: bold;
  color: white;
}
