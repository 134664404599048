.sale-receipt-main-container {
  display: flex;
  flex-direction: column;
  height: 82vh;
  background-color: white;
  padding: 0.8rem;
  border-radius: 1rem;
  margin-top: 1rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.sale-receipt-container-first-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sale-receipt-first-left {
  display: flex;
  align-items: center;
}

.sale-receipt-first-right {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}

button.sale-receipt-first-right-botton {
  display: flex;
  align-items: center;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  background-color: #002989;
  gap: 1rem;
}

.sale-receipt-container-second-div {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.sale-receipt-second-div-right {
  display: flex;
  float: right;
  gap: 1.5rem;
}

.sale-receipt-second-drop-first {
  background-color: #002989;
  color: white;
  padding: 0.2rem 0.5rem;
  display: flex;
  gap: 1rem;
  border-radius: 0.5rem;
  height: 2rem;
}

.sale-receipt-second-filter {
  background-color: #002989;
  color: white;
  padding: 0.2rem 0.5rem;
  border-radius: 0.4rem;
  cursor: pointer;
  height: 2rem;
}

span.sale-receipt-ellips-icon {
  color: #002989;
  font-size: x-large;
  font-weight: bold;
}

.sale-receipt-ellips-icon:hover{
  cursor: pointer;
}

span.all-sale-receipt-show {
  font-size: x-large;
  font-weight: bold;
  color: #002989;
}

.new-receipt-invoice-popup{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  z-index: 1000
}

.new-receipt-account-popup{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  z-index: 1000
}

/* Table group */
.sale-receipt-table-group {
  overflow-x: auto;
  border-bottom: 1px solid #ccc;
  margin-top: 1rem;
  max-height: 350px;
  overflow-y: auto;
}

.sale-receipt-table {
  width: 100%;
  border-collapse: collapse;
}

.sale-receipt-thead-row th {
  padding: 0.4rem;
  border-bottom: 1px solid #ccc;
  text-align: left;
  border: 1px solid #ccc;
  font-size: small;
  font-weight: bold;
}

.sale-receipt-table-body td {
  padding: 0.2rem;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  text-align: left;
  font-size: small;
}

.sale-receipt-tab-td {
  font-size: small;
}


.sale-receipt-column-filter-popup {
  position: absolute;
  top: 13rem;
  right: 1rem;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 10;
  width: 15%;
}

.sale-receipt-column-filter-popup label {
  display: flex;
  margin-bottom: 0.5rem;
  font-size: x-small;
}

input.sale-receipt-visible-column-input {
  width: 10%;
  margin-right: 4%;
}

button.sale-receipt-visible-box-btn {
  background-color: #002989;
  color: white;
  padding: 0.1rem 0.2rem;
  font-size: small;
  border: none;
  border-radius: 0.2rem;
}

/* quotation down icon action css */
.sale-receipt-icon-action{
  position: absolute;
  top: 19rem;
  right: 1rem;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
  width: 15%;
}

svg.svg-inline--fa.fa-caret-down.sale-receipt-table-body-td {
  margin-left: 0.5rem;
}

ul.sale-receipt-down-icon-action-ul {
  padding: 0px;
  margin-bottom: 0px;
}

li.sale-receipt-down-icon-action-li {
  padding: 0.2rem;
  font-size: small;
}

.sale-receipt-down-icon-action-li:hover{
  background-color: #ccc;
  cursor: pointer;
}

svg.svg-inline--fa.fa-caret-down {
  cursor: pointer;
}

/* attribute filter popup css*/
.sale-receipt-attribute-filter-popup-container {
  position: absolute;
  top: 13rem;
  right: 1rem;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 10;
  width: 25%;
}

label.sale-receipt-first-att-div-label,
.sale-receipt-second-att-div-label,
.sale-receipt-third-part-1-label,
.sale-receipt-third-part-2-label{
  font-size: x-small;
}

.sale-receipt-first-att-div,
.sale-receipt-second-att-div,
.sale-receipt-third-part-1,
.sale-receipt-third-part-2{
  display: flex;
  flex-flow: column;
  margin-bottom: 0.5rem;
}

.sale-receipt-third-att-div {
  display: flex;
  justify-content: space-between;
}

input.sale-receipt-third-att-date {
  padding: 0.2rem;
  font-size: x-small;
  width: 100%;
}

.sale-receipt-attr-btn-grp {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

button.sale-receipt-attr-pop-btn {
  border: none;
  border-radius: 0.2rem;
  font-size: small;
  padding: 0.2rem 0.4rem;
  background-color: #002989;
  color: white;
}

/* receipt turn icon css */
.sale-receipt-turn-icon-popup{
  position: absolute;
  top: 13rem;
  right: 1rem;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 10;
  width: 25%;
}

.sale-receipt-turn-popup-div {
  display: flex;
  flex-flow: column;
}

.sale-receipt-turn-popup-div-2{
  display: flex;
  flex-flow: column;
  margin-top: 0.5rem;
}

label.sale-receipt-turn-pop-label {
  font-size: small;
}

.sale-receipt-turn-btn-grp {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}

button.sale-receipt-turn-btn {
  border: none;
  padding: 0.2rem 0.2rem;
  font-size: small;
  background-color: #002989;
  color: white;
  border-radius: 0.2rem;
}

.sale-receipt-second-div-part {
  display: flex;
  gap: 1rem;
}
