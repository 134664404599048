  .third-receipt-main {
    background-color: #fff;
    padding: 20px;
    width: 100%;
  }

  .third-receipt-header {
    text-align: center;
    margin-bottom: 20px;
  }

  .third-receipt-sub {
    text-align: center;
    margin-bottom: 20px;
    font-size: small;
  }

  .third-receipt-logo {
    height: 3rem;
    width: 5rem;
  }

  .third-receipt-date-time {
    display: flex;
    justify-content: space-between;
  }

  .third-receipt-date,
  .third-receipt {
    margin-bottom: 10px;
  }


  .third-receipt-policy {
    display: flex;
    text-decoration: underline;
  }

  .receipt-third-policy-content,
  .receipt-third-offer-content {
    display: flex;
    margin-bottom: 1rem;
  }

  .third-receipt-offer {
    display: flex;
    text-decoration: underline;
  }

  .receipt-third-content {
    text-align: center;
    background: #38475a;
    color: white;
    margin-bottom: 2rem;
    margin-top: 3rem;
  }

  .receipt-third-table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 2rem;
  }

  .receipt-third-table th,
  .receipt-third-table td {
    text-align: left;
    padding: 8px;
  }

  .third-receipt-table-heading th {
    background: #38475a;
    color: white;
    position: sticky;
    top: 0;
  }

  .third-receipt-table-heading th:first-child {
    left: 0;
    z-index: 2;
  }

  .receipt-third-table th:first-child,
  .receipt-third-table td:first-child {
    padding-left: 16px;
  }

  .receipt-third-table th:last-child,
  .receipt-third-table td:last-child {
    padding-right: 16px;
  }

  .third-receipt-company{
    font-size: larger;
    font-weight: bold;
  }

  .third-receipt-table-grp{
    overflow-y: auto;
    max-height: 420px;
  }

  @media print{
    .third-receipt-main {
      background-color: #fff;
      padding: 20px;
      width: 100%;
    }

    .third-receipt-header {
      text-align: center;
      margin-bottom: 20px;
    }

    .third-receipt-sub {
      text-align: center;
      margin-bottom: 20px;
    }

    .third-receipt-logo {
      height: 4rem;
      width: 10rem;
    }

    .third-receipt-date-time {
      display: flex;
      justify-content: space-between;
    }

    .third-receipt-date,
    .third-receipt {
      margin-bottom: 10px;
    }


    .third-receipt-policy {
      display: flex;
      text-decoration: underline;
    }

    .receipt-third-policy-content,
    .receipt-third-offer-content {
      display: flex;
      margin-bottom: 1rem;
    }

    .third-receipt-offer {
      display: flex;
      text-decoration: underline;
    }

    .receipt-third-content {
      text-align: center;
      background: #38475a;
      color: white;
      margin-bottom: 2rem;
      margin-top: 3rem;
    }

    .receipt-third-table {
      border-collapse: collapse;
      width: 100%;
      margin-bottom: 2rem;
    }

    .receipt-third-table th,
    .receipt-third-table td {
      text-align: left;
      padding: 8px;
    }

    .third-receipt-table-heading th {
      background: #38475a;
      color: white;
      position: sticky;
      top: 0;
    }

    .third-receipt-table-heading th:first-child {
      left: 0;
      z-index: 2;
    }

    .receipt-third-table th:first-child,
    .receipt-third-table td:first-child {
      padding-left: 16px;
    }

    .receipt-third-table th:last-child,
    .receipt-third-table td:last-child {
      padding-right: 16px;
    }

    .third-receipt-company{
      font-size: larger;
      font-weight: bold;
    }
  }
