.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #fff;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.transaction-details-wrapper {
  display: flex;
  flex-direction: column;
}

.company-details {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.company-details img {
  max-height: 50px;
  margin-right: 10px;
}

.payment-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.payment-actions .btn {
  margin-left: 10px;
}

.payment-section {
  border-top: 1px solid #ddd;
  padding-top: 20px;
  margin-top: 20px;
}
