.quantity-container-1 {
  /* display: flex; */
  align-items: center;
  /* font-size: 24px; */
  font-weight: 'bold'
}

.table-container {
  margin-top: 20px;
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

/* .centered {
  text-align: center;
} */

.quantity-control {
  display: flex;
  align-items: center;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
}

.quantity-button-1 {
  background-color: transparent;
  font-size: 1.2em;
  border: none;
  cursor: pointer;
  /* padding: 4px;
  font-weight: bold */
}

.quantity-number {
  margin: 0 10px;
  /* font-size: 16px; */
}

.tax-label {
  font-size: 0.9em;
  color: grey;
  margin-left: 5px;
}

.selected-items-container {
  display: flex;
  flex-direction: column;
}

.selected-items {
  display: flex;
  flex-wrap: wrap;
}

.selected-item {
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 15px;
  margin: 5px;
  display: flex;
  align-items: center;
}

.remove-button {
  background: none;
  border: none;
  margin-left: 5px;
  cursor: pointer;
  color: red;
}

.dropdown-wrapper {
  margin-top: 10px;
  position: relative;
}

.toggle-button.open {
  color: red;
}

.dropdown {
  position: absolute;
  background-color: white;
  border: 0px solid #ccc;
  z-index: 1000;
  margin-top: 5px;
  width: 100%;
  max-height: 200px;
}

.dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown li {
  padding: 8px 12px;
  cursor: pointer;
}

.dropdown li:hover {
  background-color: #f0f0f0;
}
.sales-rep-icon-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.sales-rep-count {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #007bff;
  color: white;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
.dropdown-bill-table-one {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  min-width: 200px;
  padding: 0.5rem 0;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 0.25rem;
  box-shadow: 0 2px 4px rgba(0,0,0,.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f8f9fa;
}

.dropdown-item.active {
  background-color: #e9ecef;
}

.toggle-button {
  background: none;
  border: none;
  cursor: pointer;
  color: blue;
}

.sticky-footer {
  position: sticky;
  bottom: 0;
  inset-block-end: 0;
}

.billing-table-container {
  max-height: 500px;
   overflow-y: auto
}

.sticky-header {
  position: sticky;
  top: 0;
}

.column-width-1 {
  width: 50px;
}

.column-width-2 {
  width: 100px;
}

.column-width-3 {
  width: 200px;
}

.column-width-4 {
  width: 150px;
}

.column-width-5 {
  width: 100px;
}

.column-width-6 {
  width: 50px;
}
.wholesale-price-input {
  width: 80px;
  padding: 5px;tr.billing-item-table-tr {
    line-height: 1rem;
    border-bottom: 1px solid #b9b9b9;
}
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  text-align: right;
}

.wholesale-price-input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}
.identifier-span { 
  word-break: break-word;
}

.tax-font-size {
  font-size: 7px;
}

.price-display-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.price-display-current {
  font-size: 1rem;
  font-weight: 600;
}

.price-display-info-icon {
  position: relative;
  display: inline-block;
}

.info-icon {
  color: #002989;
  cursor: pointer;
  font-size: 1rem;
  transition: color 0.2s ease;
}

.info-icon:hover {
  color: #0056b3;
}

.tooltip-content {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(-0.5rem);
  background-color: #007bff;
  color: white;
  padding: 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  white-space: nowrap;
  z-index: 1000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: opacity 0.2s ease, visibility 0.2s ease;
}

.flex.flex-col.items-center {
  display: flex;
}

tr.billing-item-table-tr {
  /* line-height: 1rem; */
  border-bottom: 1px solid #b9b9b9;
}
.bill-dropdown-menu {
  position: fixed; /* Change from absolute to fixed */
  background-color: #fff;
  min-width: 200px;
  border: 1px solid rgba(0,0,0,0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0,0,0,0.175);
  z-index: 9999; /* Higher z-index to appear above everything */
  padding: 8px 0;
  max-height: 300px;
  overflow-y: auto;
}
.bill-dropdown-item {
  display: block;
  padding: 8px 16px;
  clear: both;
  font-weight: normal;
  color: #333;
  white-space: nowrap;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.2s ease;
}
.bill-dropdown-item:hover {
  background-color: #f5f5f5;
  text-decoration: none;
}

.bill-dropdown-item.active {
  background-color: #e9ecef;
  color: #16181b;
}