.platform-items-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.platform-items-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  background-color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  line-height: 3rem;
  padding: 0.5rem;
}

.platform-items-search {
  display: flex;
  gap: 10px;
  align-items: center;
}

.platform-items-dropdown {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f0f0f0;
}

.platform-items-search-input {
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  justify-content: center;
}

.platform-items-search-button {
  padding: 8px 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.platform-items-actions {
  margin-bottom: 20px;
  color: #FF0000;
}

.platform-items-action-link {
  cursor: pointer;
  margin: 0 5px;
}

.platform-items-table {
  width: 100%;
  border-collapse: collapse;
}

.platform-items-table th, .platform-items-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.platform-items-table th {
  background-color: #f2f2f2;
}

.platform-items-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.platform-items-table tbody tr:hover {
  background-color: #e0f7fa;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.platform-items-table tbody tr:hover td {
  animation: highlight 0.5s ease;
}

@keyframes highlight {
  0% { background-color: #e0f7fa; }
  50% { background-color: #b2ebf2; }
  100% { background-color: #e0f7fa; }
}

.platform-items-update-item-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto;
  padding: 20px;
}

.platform-item-table-grp {
  overflow-y: auto;
  max-height: 500px;
}
