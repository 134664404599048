.item-invoice-main {
  height: auto;
  width: auto;
  display: flex;
  gap: 10px;
  background-color: whitesmoke;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.invoice-box-first {
  height: 90%;
  width: 50%;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: whitesmoke;
}

.invoice-box-second {
  height: 90%;
  width: 50%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.calculation-and-calculator-format {
  display: flex;
  height: auto;
  gap: 10px;
  width: 199%;
}

.calculator-format {
  width: 50%;
  margin-top: 10px;
  background-color: white;
  margin-bottom: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  height: 22rem;
}

.item-invoice-calculation {
  width: 50%;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  height: 22rem;
}

.invoice-total-item-count {
  margin-bottom: 10px;
  height: 30px;
  margin-top: 10px;
  background-color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.total-item-count-title {
  float: right;
  margin-right: 10px;
}

.invoice-table-contain {
  background-color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 320px;
}

.first-table-invoice-item {
  overflow-y: scroll;
  width: 100%;
}

.invoice-page-header {
  margin-bottom: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding-left: 10px;
}

span.btn.btn-info.table-back-button {
  float: right;
  margin-right: 10px;
  background-color: #002989 !important;
  color: #ffffff !important;
  font-weight: normal;
  border-color: #002989;
}

.invoice-table-contain {
  max-height: 320px;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Calculator.css */
.calculator-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
}

.calculator-display {
  width: 260px;
  height: 40px;
  background-color: white;
  border: 1px solid #ccc;
  text-align: right;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 18px;
}

.calculator-buttons {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;
}

/* .calcu-item-invoice-button {
  width: 6rem;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  border: none;
  background-color: #888;
  color: white;
  cursor: default;
} */

.special-button {
  background-color: #444;
}

.item-invoice-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  width: 100%;
  margin-bottom: 10px;
  height: 4rem;
}

.item-invoice-footer .left {
  flex: 1;
  text-align: left;
}

.item-invoice-footer .center {
  flex: 1;
  text-align: center;
}

.item-invoice-footer .right {
  flex: 1;
  text-align: right;
}

/* 
button.btn.btn-success.invoice-button-success {
  margin-left: 10px;
} */

/* button.btn.btn-warning.invoice-guest-button {
  margin-left: 10px;
} */
/* 
button.btn.btn-danger.invoice-dis-button {
  margin-left: 10px;
} */

.invoice-footer-center {
  display: flex;
  align-items: center;
  gap: 20px;
}

.invoice-footer-center-checkbox-1,
.invoice-footer-center-checkbox-2 {
  display: flex;
  align-items: center;
  gap: 5px;
}

.invoice-footer-input-checkbox {
  width: 2rem;
}

/* .invoice-btn-parcel {
  margin-left: 10px;
  margin-right: 10px;
} */

.invoice-total-round-and-total {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: whitesmoke;
}

.invoice-total-1-round,
.invoice-net-total-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.invoice-round-up {
  margin-left: 6rem;
}

.invoice-val-round-up {
  margin-right: 10px;
}

.invoice-net-value-label {
  margin-left: 2rem;
}

.invoice-net-value {
  margin-right: 6.5rem;
}

button.apply-coupon-button {
  margin-right: 3rem;
  width: 30%;
  background-color: #0dcaf0;
  border: 1px solid #0dcaf0;
}

.invoice-footer-left {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.invoice-footer-right {
  margin-bottom: 1rem;
  margin-right: 10px;
  margin-top: 1rem;
}

.invoice-parcel-popup-show {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.sub-table-invoice-header {
  position: sticky;
  top: 0;
}

.second-table-invoice-item {
  width: auto;
}

.sub-table-invoice-header, .row {
  display: flex;
  justify-content: space-between;
}

.invoice-table-sub-data {
  flex: 2;
  padding: 8px;
  margin-left: 2rem;
}

.sub-table-invoice-header .table-invoice-cell {
  font-weight: bold;
}

.table-invoice-cell {
  margin-left: 2rem;
}

.table-invoice-cell-body {
  max-height: 304px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  height: 312px;
}

.invoice-table-sub-data-value {
  flex: 1;
  padding: 10px;
}

.table-invoice-cell-value {
  /* flex: 1 1; */
  /* padding: 10px; */
  font-weight: bold;
  /* margin-left: 8.5rem; */
  margin-right: 14rem;
}

.table-2-invoice-scroll {
  margin-top: 0rem;
}

.invoice-btn-parcel,
.btn-invoice-nc {
  margin-right: 10px;
  background-color: #002989 !important;
  color: #ffffff !important;
  font-weight: normal;
  border-color: #002989;
  margin-left: 10px;
  border-radius: 10px;
  padding: 5px 6px;
  line-height: 2rem;
}

.btn-invoice-page-save,
.invoice-button-success,
.invoice-guest-button,
.invoice-guest-button,
.invoice-dis-button {
  margin-right: 10px;
  background-color: #002989 !important;
  color: #ffffff !important;
  font-weight: normal;
  border-color: #002989;
  border-radius: 10px;
  line-height: 2rem;
  padding: 5px 6px;
}

input.form-control.invoice-keypad-input-field {
  margin-top: -1rem;
  margin-bottom: 2rem;
  width: 90%;
}

.invoice-keypad-buttons {
  width: 100%;
}

.no-charge-invoice-popup-show {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.invoice-no-charge-div {
  line-height: 4rem;
  background: #c4c8d3;
  margin-right: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

span.no-charges-title-name {
  margin-left: 1rem;
  font-weight: bold;
  font-size: x-large;
  color: #414d8d;
}
