.update-platform-item-container {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: auto;
  max-height: 80vh;
  height: auto;
  overflow-y: auto;
}

.update-platform-item-header {
  width: 100%;
  background-color: #002989 !important;
  height: 3rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}

span.update-platform-item-title {
  font-size: larger;
  font-weight: bold;
}

.update-platform-item-header h2 {
  margin-bottom: 5px;
}

.update-platform-item-form input[type="text"],
.update-platform-item-form input[type="number"],
.update-platform-item-form select,
.update-platform-item-form textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.update-platform-item-form .update-platform-item-checkbox {
  display: flex;
  align-items: center;
}

.update-platform-item-form .update-platform-item-checkbox input {
  margin-right: 10px;
}

.update-platform-item-form-actions {
  margin-top: 20px;
  text-align: right;
  margin-bottom: 1rem;
  margin-right: 0.5rem;
}

.update-platform-item-form-actions button {
  margin-left: 10px;
}

.update-plat-summary-grp {
  margin-top: 1rem;
}

span.update-plat-head-summary {
  padding: 0.5rem;
}

button.update-platform-footer-buttons {
  background-color: #002989;
  color: white;
  border: 1px solid #002989;
  border-radius: 5px;
  padding: 5px 6px;
}

.update-platform-item-form {
  padding: 1rem;
}

.update-platform-item-sub-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 1rem;
}

.update-platform-item-div-grp {
  display: flex;
  align-items: center; 
  gap: 5rem
}

.update-platform-item-label {
  flex: 1;
  margin-right: 10px;
}

.update-platform-item-input-field {
  flex: 2;
}

label.update-platform-item-check-label {
  margin-left: 0.5rem;
}


@media (max-width: 768px) {
  .update-platform-item-container {
    padding: 10px;
  }
}
