.item-add-setting {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.item-add-settings-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  height: 48rem;
  width: 30%;
  margin-left: 35%;
}

.checkboxes-i {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: inline-block;
  width: 18px;
  height: 18px;
  margin: 0;
  padding: 0;
  background-color: #fff;
  border: 2px solid #474DDC;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
}

.checkboxes-i:checked::before {
  content: '✓';
  color: white;
  display: block;
  text-align: center;
  line-height: 18px;
  font-size: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  background-color: #474DDC;
}

.item-add-setting-label {
  font-size: large;
}

.item-input-add-setting {
  display: block;
  width: 33%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.item-input-add-setting:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.item-input-add-setting::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.item-input-add-setting::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.input-b-color:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.item-input-add-setting::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.item-input-add-setting::placeholder {
  color: #6c757d;
  opacity: 1;
}

.item-input-add-setting:disabled,
.input-b-color[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.add-setting-header {
  padding-top: 3rem;
}

.btn-primary {
  background-color: #007bff; 
  color: white;
}

.btn-disabled {
  background-color: grey;
  color: white;
  cursor: not-allowed;
}

.item-add-setting-option-checkbox-group {
  display: flex;
  gap: 1rem;
}

.item-add-option-check-grp {
  display: flex;
  gap: 0.5rem;
}


.item-add-setting-div-first {
  display: flex;
  justify-content: space-between;
}

.setting-data-label {
  display: flex;
  gap: 1rem;
  width: 10rem;
}


.form-group-i-2 {
  display: flex;
  gap: 10px;
  margin-bottom: 1em;
  justify-content: space-between;
  text-align: center;
  flex-flow: column;
}

.item-add-setting-data-label {
  display: flex;
  gap: 0.5rem;
}
