.billing-customer-popup-main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(12 8 8 / 50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.billing-customer-main {
  width: 50rem;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: height 0.2s ease;
}

.billing-customer-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #002989;
  height: 50px;
  padding: 0 1rem;
  color: white;
}

.billing-customer-title-heading {
  font-size: larger;
  font-weight: bold;
}

.billing-customer-sub-container {
  overflow-y: auto;
  max-height: 450px;
  padding: 20px;
}

.billing-customer-header-box {
  width: 100%;
  background-color: #002989;
  margin-bottom: 1%;
  padding: 0.5rem;
}

.billing-customer-header-title {
  color: white;
  margin-left: 1rem;
}

.billing-customer-form-group {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.billing-customer-form-group label {
  flex: 1;
}

.billing-customer-form-group input {
  flex: 2;
}

.billing-customer-form-save-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.billing-customer-create-form {
  line-height: 2rem;
  padding: 5px 6px;
  border-radius: 10px;
  background-color: #002989;
  color: white;
  border: 1px solid #002989;
  margin-bottom: 1rem;
}

.billing-customer-error-msg {
  display: flex;
}

.billing-customer-phone-error {
  margin-left: auto;
  color: red;
  font-size: medium;
  float: right;
  margin-right: 15rem;
}

span.billing-get-customer {
  font-size: x-large;
  font-weight: bold;
  cursor: pointer;
}

.hover-message {
  color: #002989;
}

.no-results {
  padding: 10px;
  text-align: center;
}

.no-results .nav-link {
  color: #007bff;
  cursor: pointer;
}

.no-results .nav-link:hover {
  text-decoration: underline;
}