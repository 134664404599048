.online-ordering-sys-main-container{
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 17px;
  border-radius: 15px;
  position: relative;
}

.online-order-dash-main {
  display: flex;
  height: auto;
  gap: 10px;
  position: relative;
}

.online-order-dash-main button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f0f0f0;
  transition: background-color 0.1s;
}

.online-order-dash-main button:hover {
  background-color: #e0e0e0;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.online-order-sys-filter-row {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.online-order-sys-filter-row select,
.online-order-sys-filter-row input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.online-new-order-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ================= */

.online-order-sys-order-count {
  background-color: #00bcd4;
  color: white;
  padding: 2px 6px;
  border-radius: 10px;
  font-size: 0.8em;
  margin-left: 5px;
}

.online-order-sys-order-section {
  background-color: #fff;
  padding: 20px;
  border-radius: 15px;
  margin-top: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: slideDown 0.5s ease-out;
}

.online-order-sys-completed-orders {
  margin-top: 20px;
}

.online-order-sys-order-item {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  padding: 15px 0;
}

.onlie-order-sys-order-channel img {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.onlie-order-sys-order-details {
  flex-grow: 1;
  display: flex;
}

.onlie-order-sys-customer-info, .order-info {
  flex: 1;
}

.onlie-order-sys-customer-info p, .order-info p {
  margin: 5px 0;
  font-size: 0.9em;
}

.onlie-order-sys-order-actions {
  display: flex;
  align-items: center;
}

.online-order-sys-view-button, .online-order-sys-user-button {
  padding: 5px 10px;
  margin-left: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.online-order-sys-view-button {
  background-color: #ffa000;
  color: white;
}

.online-order-sys-user-button {
  background-color: #e0e0e0;
  font-size: 1.2em;
}

.online-platform-list-header {
  background-color: white;
  line-height: 8rem;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}

.online-platform-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  margin: 10px;
}

.online-platform-icon-img {
  width: 100px;
  height: 100px;
  object-fit: cover; 
  border-radius: 4px;
}
