.fouth-receipt-main {
  background-color: #fff;
  padding: 20px;
  width: 100%;
  font-size: small;
  font-weight: bold;
}

.receipt-header {
  text-align: center;
  margin-bottom: 20px;
}

.receipt-sub-header {
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
}


.receipt-fourth-table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 2rem;
}

.fourth-receipt-table-heading th {
  color: black;
  font-weight: bold;
  position: sticky;
  top: 0;
  background-color: white;
  border-bottom: 1px solid black;
}

.fourth-receipt-table-heading th:first-child {
  left: 0;
  z-index: 2;
}

.receipt-fourth-table th,
.receipt-fourth-table td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid black;
}

.receipt-fourth-table th:first-child,
.receipt-fourth-table td:first-child {
  padding-left: 16px;
}

.receipt-fourth-table th:last-child,
.receipt-fourth-table td:last-child {
  padding-right: 16px;
}

.receipt-fourth-table tfoot td {
  font-weight: bold;
}

.fouth-receiopt-table-grp{
  overflow-y: auto;
  max-height: 420px;
}

@media print {
  .fouth-receipt-main {
    background-color: #fff;
    padding: 20px;
    width: 100%;
    font-size: small;
    font-weight: bold;
  }

  .receipt-header {
    text-align: center;
    margin-bottom: 20px;
  }

  .receipt-sub-header {
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
  }


  .receipt-fourth-table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 2rem;
  }

  .fourth-receipt-table-heading th {
    color: black;
    font-weight: bold;
    position: sticky;
    top: 0;
    background-color: white;
    border-bottom: 1px solid black;
  }

  .fourth-receipt-table-heading th:first-child {
    left: 0;
    z-index: 2;
  }

  .receipt-fourth-table th,
  .receipt-fourth-table td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid black;
  }

  .receipt-fourth-table th:first-child,
  .receipt-fourth-table td:first-child {
    padding-left: 16px;
  }

  .receipt-fourth-table th:last-child,
  .receipt-fourth-table td:last-child {
    padding-right: 16px;
  }

  .receipt-fourth-table tfoot td {
    font-weight: bold;
  }


}

.fourth-logo-header {
  text-align: center;
}

img.receipt-four-logo {
  width: 4rem;
  height: 4rem;
  margin-bottom: 2.5rem;
}

.invoice_details {
  display: flex;
  gap: 5rem;
}

.receip-four-wel-msg {
  text-align: center;
}

p.customer-invoice-msg {
  text-align: center;
}

span.company-title {
  text-align: center;
}
