.date-selector-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10rem;
  height: 4rem;
  background-color: rgb(46, 179, 179);
}

.row {
  margin-bottom: 25px;
}

.select-report-content {
  width: 100%;
  margin-right: 10px;
}

.form-select-option {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  margin-bottom: 1rem;
}

.date-picker-container {
  display: flex;
  position: relative;
  width: 100%;
  z-index: 100;
}

.date-picker {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.date-picker-icon {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  color: #474ddc;
}

.sticky-header-report {
  position: sticky;
  top: 0;
}

.report-type {
  height: 48px;
}

.select-report-content select {
  color: #474ddc;
  /* Change to the color you want */
  background-color: white;
  /* Optional: specify background color */
}

.report-box {
  margin-bottom: 50px;
}

.nav-pagination-style {
  position: fixed;
  bottom: 24px;
  right: 15px;
}

.common-heigth-report-amount {
  padding: 12px;
}

.icon-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
  height: 3rem;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
  display: none;
}

.icon-content {
  display: flex;
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.action-button {
  padding: 15px 20px;
  font-size: 28px;
  border: none;
  background: none;
  cursor: pointer;
  color: green;
}

.action-label {
  margin-top: 1rem;
  margin-right: 5px;
  font-size: larger;
}

.print-icon {
  color: #474ddc;
}

.report-table-data {
  margin-top: -1rem;
  overflow-y: auto;
  max-height: 52vh;
}

.date-picker-cal {
  margin-bottom: 1rem;
}

.pagination-dropdown {
  display: flex;
  align-items: center;
  height: 3rem;
  justify-content: space-between;
  /* Align items vertically */
}

.pagination-controls {
  margin-right: 20px;
}

.reports-control-label,
.report-gen .title {
  font-size: 20px;
}

.sp-table-header {
  position: sticky;
  top: 0;
  z-index: 1;
}

@media print {
  .sale-purchase-box,
  .pagination-controls,
  .header-container,
  .nav-pagination-style {
    display: none !important;
  }

  .reports-search-box {
    display: none;
  }
}

@media print {
  @page {
    margin-top: 0;
    margin-bottom: 0;
  }

  body {
    padding-top: 1px;
    padding-bottom: 1px;
  }
}

/* ---------------- */

.report-pagination-container {
  /* display: flex; */
  justify-content: flex-end;
  margin-right: 2rem;
  margin-top: -1.6rem;
}

.report-pagination-container button {
  padding: 5px 10px;
  margin-right: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.report-pagination-container button:hover {
  background-color: #1279e7;
}

.report-pagination-info {
  margin-top: 1rem;
}

.report-pagination-container button.active {
  background-color: #007bff;
  color: white;
}

.item-purchase-unauthorize-modal-popup{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.css-b62m3t-container {
  line-height: 2rem;
  margin-top: 0.1rem;
}

.report-searchable-dropdown-container {
  position: relative;
  z-index: 1000;
}

.report-searchable-dropdown {
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
}
.bill-items-summary {
  font-size: 0.9em;
  color: #666;
}

.bill-card {
  border: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 4px;
}

.bill-items {
  margin: 10px 0;
}

.bill-item {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: 1px solid #eee;
}

.summary-section {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #ddd;
}
.sales-person-details {
  padding: 15px;
}

.bill-card {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}

.bill-items {
  margin: 10px 0;
}

.bill-item {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: 1px solid #eee;
}

.summary-section {
  margin-top: 20px;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 4px;
}
.sales-person-details {
  padding: 15px;
}

.bill-card {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 15px;
  background-color: #fff;
}

.bill-items {
  margin: 10px 0;
}

.bill-item {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 10px;
  padding: 8px;
  border-bottom: 1px solid #eee;
}

.bill-total {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #ddd;
  text-align: right;
  font-weight: bold;
}

.summary-section {
  margin-top: 20px;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.summary-section div {
  margin: 5px 0;
}

.bill-item span:last-child {
  text-align: right;
}

.bill-item span:first-child {
  font-weight: 500;
}
/* Sales Executive Styles */
.bill-items-summary {
  font-size: 0.9em;
}

.bill-item-preview {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

.more-items {
  color: #666;
  font-style: italic;
}

/* Modal Styles */
.sales-person-details {
  padding: 15px;
}

.section-title {
  color: #333;
  border-bottom: 2px solid #eee;
  padding-bottom: 8px;
  margin-bottom: 15px;
}

.summary-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  background: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
}

.summary-item {
  display: flex;
  flex-direction: column;
}

.summary-item .label {
  color: #666;
  font-size: 0.9em;
}

.summary-item .value {
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
}

.bill-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  background: white;
}

.bill-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.bill-date {
  color: #666;
  font-size: 0.9em;
}

.bill-items-table {
  margin: 10px 0;
}

.bill-footer {
  text-align: right;
  padding-top: 10px;
  border-top: 1px solid #eee;
}