.back-button {
  display: block;
  width: 38px;
  height: 36px;
  border-radius: 50%;
  background-color: #002989;
  position: relative;
  margin: 0;
  border: none;
}

.back-button::before {
  content: "";
  display: block;
  width: 12px;
  height: 11px;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  transform: rotate(-45deg);
  position: absolute;
  left: 47%;
  top: 48%;
  margin-left: -5px;
  margin-top: -5px
}

.back-button::after {
  content: "";
  display: block;
  width: 2px;
  height: 18px;
  background-color: #fff;
  transform: rotate(92deg) translate(5px, -1px);
  position: absolute;
  left: 56%;
  top: 11px;
  margin-top: -7px;
}
