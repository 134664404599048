.receipt-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.receipt-first-main {
  background-color: #fff;
  padding: 20px;
  width: 100%;
}

.receipt-header {
  text-align: center;
  margin-bottom: 20px;
}

.receipt-sub {
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
  font-size: small;
}

.receipt-date,
.receipt-time {
  margin-bottom: 10px;
}

hr,
.hr-dotted {
  border-top: 3px dotted #888;
}

.table-header-dotted {
  border-top: 3px dotted #888;
  border-bottom: 3px dotted #888;
}

.receipt-date-time {
  display: flex;
  justify-content: space-between;
}

.receipt-right {
  margin-right: -300px;
}

span.company-title {
  font-size: x-large;
  margin-top: -2rem;
}

.receipt-container {
  display: flex;
  align-items: flex-start; 
}

.first-receipt-policy {
  font-size: larger;
  text-decoration: underline;
  margin-right: 23rem;
}

.receipt-first-table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 2rem;
}

.receipt-first-table th,
.receipt-first-table td {
  text-align: left;
  padding: 8px;
}

.receipt-first-table th:first-child,
.receipt-first-table td:first-child {
  padding-left: 16px;
}

.receipt-first-table th:last-child,
.receipt-first-table td:last-child {
  padding-right: 16px;
}

.first-receipt-1-logo-header {
  text-align: left;
  margin-bottom: 20px;
  width: 15%;
}

img.first-receipt-1-logo {
  height: 4rem;
  width: 5rem;
  margin-left: 13em;
}

@media print {
  .hide-on-print {
    display: none;
  }

  .receipt-main {
    background-color: #fff;
    padding: 20px;
    width: 100%;
  }
}
.qr-code-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.qr-code-image {
  width: 100px;
  height: 100px;
}

.receipt-first-table-div {
  overflow-y: auto;
  max-height: 220px;
}
