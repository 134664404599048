.purchase-expenses-main-container {
  display: flex;
  flex-direction: column;
  height: 80vh;
  background-color: white;
  padding: 0.8rem;
  border-radius: 1rem;
  margin-top: 1rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 480px;
  height: 500px;
}

.purchase-expenses-container-first-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.purchase-expenses-first-left {
  display: flex;
  align-items: center;
}

.purchase-expenses-first-right {
  display: flex;
  justify-content: flex-end;
}

button.purchase-expenses-first-right-botton {
  display: flex;
  align-items: center;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  background-color: #002989;
  gap: 1rem;
}

span.all-purchase-expenses-show {
  color: #002989;
  font-size: x-large;
  font-weight: bold;
}

.new-purchase-expenses-popup-container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  z-index: 1000
}

/* Table group */
.purchase-expenses-table-group {
  border-bottom: 1px solid #ccc;
  margin-top: 1rem;
}

.purchase-expenses-table {
  width: 100%;
  border-collapse: collapse;
}

.purchase-expenses-thead-row th {
  padding: 0.4rem;
  border-bottom: 1px solid #ccc;
  text-align: left;
  border: 1px solid #ccc;
  font-size: small;
  font-weight: bold;
}

.purchase-expenses-table-body td {
  padding: 0.2rem;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  text-align: left;
  font-size: small;
}

.purchase-expenses-tab-td {
  padding: 0.2rem;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  text-align: left;
  font-size: small;
}
