.calendar-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 90%;
}

.calendar-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar-container {
  padding: 20px;
}

.calendar-header {
  margin-bottom: 20px;
}

.calendar-header h2 {
  font-size: 1.5rem;
  margin: 0;
}

.date-range-selector {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.date-picker {
  flex: 1;
  margin-right: 20px;
}

.date-picker label {
  margin-right: 10px;
}

.close-button {
  background-color: transparent;
  border: none;
  color: #333;
  cursor: pointer;
  font-size: 1rem;
  padding: 8px 12px;
  border-radius: 4px;
}

.close-button:hover {
  background-color: #f0f0f0;
}
